/**
 * Returns the pagination graphql expects as follows:
 * @param {Object} options object from here
 * https://vuetifyjs.com/en/components/data-tables#paginate-and-sort-server-side
 * Format:
 * {
 *  "page": int (default: 1),
 *  "itemsPerPage": int,
 *  "sortBy": [],
 *  "sortDesc": [],
 *  "groupBy": [],
 *  "groupDesc": [],
 *  "mustSort": bool,
 *  "multiSort": bool,
 *  "itemsPerPageOptions": [int]
 * }
 *
 * @returns {Object} with:
 * - limit: Int
 * Number of results to return per page.
 *
 * - offset: Int
 * The initial index from which to return
 * the results. Default: 0
 *
 * - ordering: String
 * A string or comma delimited string values
 * that indicate the default ordering when
 * obtaining lists of objects.
 * */

import { Ordering } from "@/generated/graphql";
import { DataOptions } from "vuetify/src/components/VData/VData";

export function parsePagination(options: DataOptions) {
  let { itemsPerPage } = options;
  const { page, sortDesc, sortBy } = options;

  itemsPerPage = itemsPerPage || 20;
  if (itemsPerPage < 1) {
    itemsPerPage = 9999;
  }

  // Convert
  // sortBy ([key1, key2, ...]) and
  // sortDesc ([true, false, ...])
  // to object {key1: DESC, key2: ASC}
  const orders = sortBy
    .map((key, i) => ({
      [key]: sortDesc[i] ? Ordering.Desc : Ordering.Asc
    }))
  

  return {
    pagination: {
      limit: itemsPerPage,
      offset: itemsPerPage * (page - 1),
    },
    order: Object.assign({}, ...orders)
  };
}
