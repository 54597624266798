











































import Vue from 'vue';
import { handleErrors, showSuccessNotification } from '@/utils/notifications';
import store from '@/store';
import client from '@/graphql/client';

export default Vue.extend({
  name: 'AddUser',

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      roles: [] as string[],
      email: '',
    };
  },

  computed: {
    makeUserAdmin(): boolean {
      return this.roles.includes('admin');
    },

    routes(): object[] {
      //@ts-ignore
      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
    },

    items(): string[] {
      return this.routes
        .flatMap(({meta}: any) => meta && meta.roles)
        // Filter empty & duplicates
        .filter((v, i, arr) =>  !!v && arr.indexOf(v) === i)
    },
  },

  watch: {
    value() {
      this.email = "";
      this.roles = [];
    }
  },

  methods: {
    async submit() {
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }

      const { data, errors } = await client.inviteProjectUser({data: {
        newUserEmail: this.email,
        projectId: store.state.project.id,
        makeUserAdmin: this.makeUserAdmin,
        roles: this.roles,
      }});

      if (data) {
        showSuccessNotification(this.$t('project_settings.user_invited_success'), 3000);
        this.$emit('input', false);
      } else {
        handleErrors(errors);
      }
    }
  }
});
