import Bottleneck from "bottleneck";
import { print } from 'graphql/language/printer';
import { captureException } from '@sentry/browser';
import axiosApiInstance, { createResponse, QueryResponse } from '@/utils/api';
import { ASTNode } from 'graphql/language/ast';

export function createErrorResponse(errorMessage: string): QueryResponse<undefined> {
  return {
    data: undefined,
    errors: [errorMessage],
  }
}

// Don't allow 1 client to do 6 concurrent requests.
// Wait client side before current requests are resolved instead of overloading server.
const limiter = new Bottleneck({
  maxConcurrent: 6,
});

const query =  <Data = any, Variables = undefined>(
  queryNode: ASTNode,
  variables: Variables,
  fetchOptions = {},
): Promise<QueryResponse<Data>> => {
  const data = JSON.stringify({
    query: `${print(queryNode)}`,
    variables,
  });

  return new Promise((resolve, reject) => {
    axiosApiInstance.post('/', data, fetchOptions)
      .then(response => resolve(createResponse(response)))
      .catch((error) => {
        // Failed fetch is always an unexpected error, since expected
        // errors will be in "response.errors". Send it to sentry.
        captureException(error);
        reject(createErrorResponse(error));
      });
  })
}

export default limiter.wrap(query) as typeof query
