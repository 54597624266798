


























import { PropType } from 'vue';
import { debounce } from 'lodash'
import client from '@/graphql/client'
import { UserType } from '@/generated/graphql'
import { handleErrors } from '@/utils/notifications'
import { ProjectRole } from './types'

export default {
  props: {
    user: { type: Object as PropType<UserType>, required: true },
    projectRole: { type: Object as PropType<ProjectRole>, required: true },
  },

  data() {
    return {
      roleOptions: ["admin", "manager", "developer", "translator"],
      roles: [],
      noRole: false
    }
  },

  watch: {
    projectRole: {
      handler(val) {
        this.roles = val.roles
      },
      deep: true,
      immediate: true,
    },
    roles: {
      async handler(val, oldVal) {
        if (this.projectRole.id === 'ALL' && val.length > 0) {
          this.noRole = false
        }
        if (this.projectRole.id !== 'ALL') {
          this.noRole = !val.length
          oldVal && await this.save()
        }
      },
      deep: true,
      immediate: true,
    },

    noRole(val) {
      if (val) {
        this.roles = []
      }
    }
  },

  created() {
    // The debounced function is created when the component is compiled,
    // and each instance of the component shares the same debounced function
    // We need a unique debounce function for each component
    this.save = debounce(async function() {
      const { errors } = await client.updateUserProjectRoles({
        data: {
          organizationId: this.user.organizationId,
          projectId: this.projectRole.project.id,
          userId: this.user.id,
          roles: this.roles,
        }
      })
      if (errors.length) {
        handleErrors(errors)
      }
    }, 1000)
  },
  
  methods: {
    changeRole(role) {
      if (this.projectRole.id === 'ALL') {
        this.$emit('changeAllUsersRole', { role, isAdding: this.roles.includes(role) })
        return
      }
      this.$emit('changeSingleUserRole', { id: this.projectRole.id, role, isAdding: this.roles.includes(role) })
      
    },

    changeNoRole(noRole) {
      if (noRole && this.projectRole.id === 'ALL') {
        this.$emit('changeAllUsersRole', { noRole: true })
        return
      }
      this.$emit('changeSingleUserRole', { id: this.projectRole.id, noRole: true })
    },

    save() {},
  },
}
