var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { "max-width": "400", persistent: "" },
      on: {
        input: function($event) {
          return _vm.close()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 mb-2 ml-auto",
                  attrs: { disabled: !_vm.formIsValid, color: "primary" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": _vm.showPassword ? "visibility" : "visibility_off",
              rules: _vm.$validation.password,
              type: _vm.showPassword ? "text" : "password",
              label: _vm.$t("authentication.create_password"),
              "validate-on-blur": "",
              counter: ""
            },
            on: {
              "click:append": function($event) {
                _vm.showPassword = !_vm.showPassword
              }
            },
            model: {
              value: _vm.newPassword,
              callback: function($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword"
            }
          }),
          _c("button", {
            staticStyle: { display: "none" },
            on: { submit: _vm.submitForm }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }