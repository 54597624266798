var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.displayAddSecret
        ? _c("create-api-key-dialog", {
            on: {
              input: function($event) {
                _vm.displayAddSecret = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-card-title",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("api_keys.title")) + " "),
          _c(
            "v-btn",
            {
              staticClass: "ml-1",
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  _vm.displayAddSecret = true
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("api_keys.add")) + " "),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-text"),
      _c("v-data-table", {
        attrs: {
          id: "api-key-table",
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.apiKeys,
          "hide-default-footer": "",
          options: { itemsPerPage: 500 },
          "mobile-breakpoint": 1,
          expanded: _vm.expanded,
          "show-expand": ""
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          },
          "click:row": _vm.rowClick
        },
        scopedSlots: _vm._u([
          {
            key: "item.created",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.timeAgoInWords(_vm.toTimeStamp(item.created))) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.lastOnline",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.lastOnline
                        ? _vm.timeAgoInWords(_vm.toTimeStamp(item.lastOnline))
                        : _vm.$t("api_keys.unused")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.expiryDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  { class: _vm.isExpired(item.expiryDate) ? "red--text" : "" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.expiryDate
                            ? _vm.format(
                                new Date(item.expiryDate),
                                "yyyy-MM-dd"
                              )
                            : _vm.$t("api_keys.no_expiry_date")
                        ) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.delete",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "px-2",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.deleteItem(item)
                      }
                    }
                  },
                  [_vm._v(" close ")]
                )
              ]
            }
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  { attrs: { colspan: _vm.headers.length + 1 } },
                  [
                    _c("g-chip-select", {
                      staticStyle: {
                        transform: "scale(0.9) translateX(-36px)"
                      },
                      attrs: {
                        label: _vm.$t("project_settings.users.roles"),
                        items: _vm.roleChoices.map(function(i) {
                          return { value: i, text: i }
                        })
                      },
                      on: {
                        input: function($event) {
                          return _vm.debounceUpdateApiKeyRoles(
                            item.id,
                            item.roles
                          )
                        }
                      },
                      model: {
                        value: item.roles,
                        callback: function($$v) {
                          _vm.$set(item, "roles", $$v)
                        },
                        expression: "item.roles"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("v-card-actions")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }