<template>
  <div />
</template>

<script>
import store from '@/store';
export default {
  created() {
    store.logout();
  }
}
</script>
