import gql from "graphql-tag";
import fragments from "@/graphql/fragments";

const queries = {
  currentUser: gql`
    query currentUser {
      currentUser {
        id
        username
        email
        firstName
        lastName
        fullName
        imageUrl
        isOnline
        lastOnline
        locale
        isPasswordSet
        myProjects {
          ...projectFields
        }
        myOrganizations {
          id
          created
          name
          hidden
          users {
            id
            email
          }
          projects {
            id
            name
          }
        }
        roles {
          project {
            ...publicProjectFields
          }
          id
          user
          roles
        }
      }
    }
    ${fragments.project}
    ${fragments.publicProject}
  `,
  
  getUserRole: gql`
    query getUserRole($userId: ID!, $projectId: ProjectID!) {
      getUserRole(userId: $userId, projectId: $projectId) {
        user
        project {
          ...publicProjectFields
        }
        roles
      }
    }
    ${fragments.publicProject}
  `,

  projectStats: gql`
    query projectStats($projectId: ProjectID!) {
      projectStats(projectId: $projectId) {
        users
        languages
        translated
        translations
      }
    }
  `,

  getProject: gql`
    query project($slug: String!) {
      project(slug: $slug) {
        ...projectDetailFields
      }
    }
    ${fragments.projectDetail}
  `,

  getLanguages: gql`
    query languages {
      languages {
        ...languageFields
      }
    }
    ${fragments.language}
  `,

  getFileFormats: gql`
    query fileFormats {
      fileFormats {
        name
        description
      }
    }
  `,

  getAssets: gql`
    query assets($projectSlug: String!, $filters: AssetFilter, $order: AssetOrder, $pagination: OffsetPaginationInput) {
      project(slug: $projectSlug) {
        assets(filters: $filters, order: $order, pagination: $pagination) {
          count
          results {
            ...assetFields
          }
        }
      }
    }
    ${fragments.asset}
  `,
  
  getAssetById: gql`
    query assetById($projectSlug: String!, $id: ID!) {
      project(slug: $projectSlug) {
        assetById(id: $id) {
          ...assetFields
          history {
            ...assetHistoryFields
          }
          comments {
            ...commentFields
          }
        }
      }
    }
    ${fragments.asset}
    ${fragments.assetHistory}
    ${fragments.comment}
  `,

  getTranslationById: gql`
    query translationById($projectSlug: String!, $id: ID!) {
      project(slug: $projectSlug) {
        translationById(id: $id) {
          id
          created
          edited
          value
          language {
            ...languageFields
          }
          history {
            ...translationHistoryFields
          }
          comments {
            ...commentFields
          }
        }
      }
    }
    ${fragments.language}
    ${fragments.translationHistory}
    ${fragments.comment}
  `,

  getApiKeys: gql`
    query apiKeys($projectId: ProjectID!) {
      apiKeys(projectId: $projectId) {
        id
        created
        lastOnline
        prefix
        name
        revoked
        expiryDate
        roles
      }
    }
  `,

  getMachineTranslationOrders: gql`
    query machineTranslationOrders($projectSlug: String!) {
      project(slug: $projectSlug) {
        machineTranslationOrders {
          ...machineTranslationOrderFields
        }
      }
    }
    ${fragments.machineTranslationOrder}
  `,

  getMachineTranslationOrder: gql`
    query machineTranslationOrder($projectSlug: String!, $id: ID!) {
      project(slug: $projectSlug) {
        machineTranslationOrder(id: $id) {
          ...machineTranslationOrderFields
        }
      }
    }
    ${fragments.machineTranslationOrder}
  `,

  getUsage: gql`
    query usage($projectSlug: String!) {
      project(slug: $projectSlug) {
        usage {
          machineTranslationsLimit
          machineTranslationsLeft
        }
      }
    }
  `,

  getExportedDataHistory: gql`
    query getExportedDataHistory($projectSlug: String!) {
      project(slug: $projectSlug) {
        exportedData {
          createdOn
          type
          name
          url
        }
      }
    }
  `,

  getCreditCards: gql`
    query getCreditCards($projectSlug: String!) {
      project(slug: $projectSlug) {
        creditCards {
          id
          month
          year
          lastDigits
          holderName
          typeName
        }
      }
    }
  `,

  organizationDetail: gql`
    query organizationDetail($id: ID!) {
      organizationDetail(id: $id) {
        ...organizationFields
      }
    }
    ${fragments.organization}
  `,

  organizationUsage: gql`
    query organizationUsage($id: ID!) {
      organizationUsage(id: $id) {
        users
        translations
        assets
      }
    }
  `,
}

export default queries;
