




























































import plans, { defaultPlan, getPlanObj, getPricingText } from "@/utils/plans";
import Pricing from "@/components/billing/Pricing.vue";
import client from "@/graphql/client";
import { handleOptionalErrors } from "@/utils/notifications";
import events from '@/events';

export default {
  name: 'Billing',
  components: { Pricing },

  props: {
    organization: { type: Object, required: true }
  },

  data() {
    return {
      plans,
      defaultPlan,
      newPlan: null,
      stats: null,
      creditCards: [],
      loading: false,
    };
  },

  computed: {
    creditCard() {
      return this.creditCards.length > 0 && this.creditCards[0];
    },

    currentPlanObj() {
      return getPlanObj(this.organization.plan) || plans[0];
    },

    translationsLeft() {
      return this.stats && this.currentPlanObj.translations - this.stats.translations;
    },

    usersLeft() {
      return this.stats && this.currentPlanObj.users - this.stats.users;
    },

    exceededPlan() {
      return this.translationsLeft < 0 || this.usersLeft < 0;
    },
  },

  mounted() {
    this.setStats();
  },

  methods: {
    getPricingText,

    async setStats() {
      const { data } = await client.organizationUsage({id: this.organization.id});
      this.stats = data.organizationUsage
    },

    async setPlan(plan) {
      if (this.organization.plan === plan) {
        return;
      }
      
      events.showLoader.emit(true)
      const { errors } = await client.updatePlan({
        id: this.organization.id,
        plan,
      });
      events.showLoader.emit(false)
      if (errors.length) {
        return handleOptionalErrors(errors);
      }
      this.organization.plan = plan
    },

    cancel() {
      this.newPlan = null;
    },
  },
};
