var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%", width: "100%", cursor: "pointer" },
      on: {
        click: function($event) {
          return _vm.$refs.file.click()
        }
      }
    },
    [
      _vm.imageInBytes
        ? _c(
            "v-img",
            {
              staticClass: "edit_image",
              class: { loading: _vm.uploading },
              staticStyle: { height: "100%", width: "100%" },
              attrs: { src: _vm.imageInBytes }
            },
            [
              _vm.uploading
                ? _c(
                    "p",
                    {
                      staticClass: "title text--black text-center",
                      staticStyle: { "margin-top": "45%" }
                    },
                    [_vm._v(" ..." + _vm._s(_vm.$t("images.uploading")) + " ")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c(
        "v-img",
        {
          staticClass: "edit_image white--text",
          staticStyle: { height: "100%", width: "100%" },
          style: "display: " + (_vm.imageInBytes ? "none" : ""),
          attrs: { src: _vm.value.imageUrl || _vm.placeholderImage },
          on: { load: _vm.onFinishedLoading }
        },
        [_vm._t("default")],
        2
      ),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { id: "file", accept: "image/*", type: "file", name: "file" },
        on: { change: _vm.upload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }