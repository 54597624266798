<template>
  <div>
    Not found
  </div>
</template>

<script>
// TODO implement decent looking 404 page
export default {};
</script>
