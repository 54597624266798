var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    {
      staticStyle: { "z-index": "999!important" },
      attrs: { opacity: 0.5, value: _vm.loading }
    },
    [_c("v-progress-circular", { attrs: { indeterminate: "", size: "64" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }