








































import Vue from 'vue';
import { handleErrors } from '@/utils/notifications';
import store from '@/store';
import client from '@/graphql/client';

export default Vue.extend({
  name: 'SetPasswordModal',

  data() {
    return {
      newPassword: '',
      showPassword: false,
      formIsValid: false,
      showModal: false,
    }
  },

  watch: {
    newPassword() {
      //@ts-ignore
      this.formIsValid = this.$refs.form.validate()
    },

    showModal() {
      store.closeChangePasswordModal();
    }
  },

  methods: {
    close() {
      store.closeChangePasswordModal()
    },

    async submitForm() {
      const { data, errors } = await client.setPassword(this.newPassword);
      if (data) {
        this.close();
      } else {
        handleErrors(errors)
      }
    },
  },
});
