


























import Vue from 'vue';
import store from '@/store';
import Tag from '@/components/tags/Tag.vue';
import {toggle} from "@/utils/array";
import { TagFieldsFragment } from '@/generated/graphql';

export default Vue.extend({
  name: 'ChipTagSelect',

  components: { Tag },

  props: {
    value: { type: Array, required: false, default: () => [] }, // list of tag IDs
  },

  data() {
    return {
      options: [] as TagFieldsFragment[],
      showAddNewTagModal: false,
    }
  },

  created() {
    this.options = store.getTagOptions();
  },

  methods: {
    toggleTag(tag) {
      this.$emit('input', toggle(this.value, tag.id));
    },
  },
});
