











































import Vue from 'vue';
import { state } from '@/store';
import { timeAgoInWords, toTimeStamp } from '@/utils/datetime';
import client from "@/graphql/client";
import events from "@/events";
import { handleErrors } from '@/utils/notifications';
import { ExportedDataType } from '@/generated/graphql';

export default Vue.extend({
  name: 'PreviousExports',

  data() {
    return {
      exportedData: [] as ExportedDataType[],
      headers: [
        { text: 'Name' },
        { text: 'Created' },
        { text: 'Type' },
        { text: '' },
      ],
    };
  },

  created() {
    this.fetchExportedData();
    //@ts-ignore
    this.$handleOff(events.exportCreated.on(this.fetchExportedData));
  },

  methods: {
    timeAgoInWords,
    toTimeStamp,

    async fetchExportedData() {
      const { data, errors } = await client.getExportedDataHistory({
        projectSlug: state.project.slug
      });

      if (errors.length) {
        handleErrors(errors);
      }
      this.exportedData = data.project.exportedData
    },
  },
});
