
















































































import countries from "@/constants/countries";

export default {
  name: "OrganizationInfo",

  props: { 
    address: { type: Object, required: true },
    title: { type: String, required: true }
  },

  data() {
    return {
      countries,
      open: false,
    }
  }
};
