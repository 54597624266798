import Vue from 'vue';

let count = 0;
const nextEventName = () => {
  count += 1;
  return ''+count;
};

const bus = new Vue();

function createEvent() {
  const eventName = nextEventName();
  return {
    on(callback) {
      bus.$on(eventName, callback);
      return () => bus.$off(eventName, callback);
    },
    once(callback) { bus.$once(eventName, callback); },
    emit(...args: any[]) { bus.$emit(eventName, ...arguments) },
  }
}

export default {
  openLoadScreen: createEvent(),
  closeLoadScreen: createEvent(),
  authenticated: createEvent(),
  downloadTable: createEvent(),
  tagsChanged: createEvent(),
  nextRoute: createEvent(),
  createdApiKey: createEvent(),
  unauthenticated: createEvent(),
  notification: createEvent(),
  refetchState: createEvent(),
  savedTranslation: createEvent(),
  exportCreated: createEvent(),
  openConfirmDeleteDialog: createEvent(),
  openAsset: createEvent(),
  fetchAssets: createEvent(),
  openMessageModal: createEvent(),
  searchError: createEvent(),
  openConfirmDialog: createEvent(),
  refetchOrganizationDetail: createEvent(),
  showLoader: createEvent(),
}
