var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.organization
    ? _c(
        "v-form",
        {
          ref: "form",
          staticClass: "pa-4",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("global.name"),
              required: "",
              rules: [_vm.$rules.required],
              "validate-on-blur": ""
            },
            model: {
              value: _vm.organization.name,
              callback: function($$v) {
                _vm.$set(_vm.organization, "name", $$v)
              },
              expression: "organization.name"
            }
          }),
          _c(
            "div",
            { staticClass: "my-6 mb-12 pa-5 org-info__wrapper" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "capitalize_first_letter font-weight-medium org-info__title"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("organizations.field.vat_coc_label")) +
                      " "
                  )
                ]
              ),
              _c(
                "v-layout",
                { staticClass: "mx-0", attrs: { row: "" } },
                [
                  _c("v-text-field", {
                    staticClass: "px-2",
                    attrs: {
                      md12: "",
                      lg6: "",
                      label: _vm.$t("organizations.field.vat_number")
                    },
                    model: {
                      value: _vm.organization.vatNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.organization, "vatNumber", $$v)
                      },
                      expression: "organization.vatNumber"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "px-2",
                    attrs: {
                      xs12: "",
                      lg6: "",
                      label: _vm.$t("organizations.field.coc_number")
                    },
                    model: {
                      value: _vm.organization.cocNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.organization, "cocNumber", $$v)
                      },
                      expression: "organization.cocNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("organization-address", {
            attrs: {
              address: _vm.organization.physicalAddress,
              title: _vm.$t("organizations.field.physical_address")
            }
          }),
          _c("v-checkbox", {
            staticClass: "mb-2",
            attrs: {
              color: "primary",
              label: _vm.$t(
                "organizations.field.billing_address_is_physical_address"
              )
            },
            model: {
              value: _vm.organization.billingAddressIsPhysicalAddress,
              callback: function($$v) {
                _vm.$set(
                  _vm.organization,
                  "billingAddressIsPhysicalAddress",
                  $$v
                )
              },
              expression: "organization.billingAddressIsPhysicalAddress"
            }
          }),
          !_vm.organization.billingAddressIsPhysicalAddress
            ? _c("organization-address", {
                attrs: {
                  address: _vm.organization.billingAddress,
                  title: _vm.$t("organizations.field.billing_address")
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between mt-4" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "red",
                    disabled: _vm.submitting,
                    outlined: ""
                  },
                  on: { click: _vm.confirmRemoveOrganization }
                },
                [_vm._v(" " + _vm._s(_vm.$t("organizations.delete")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: _vm.submitting },
                  on: { click: _vm.updateOrganization }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }