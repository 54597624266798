import { handleErrors } from './notifications';
import client from '@/graphql/client';

export async function createTranslation(asset: string, language: string, value: string) {
  const input = {
    asset,
    language,
    value,
  };

  const response = await client.createTranslation({data: input})
  const { data: { createTranslation }, errors } = response;
  if (errors.length) {
    handleErrors(errors);
  }

  return createTranslation;
}
