var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-wrap" }, [
    _c("form", { staticClass: "login" }, [
      _c(
        "p",
        { staticClass: "login-register" },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("view_meta.do_you_have_an_account")) + " "
          ),
          _c(
            "router-link",
            { staticClass: "router-link", attrs: { to: { name: "login" } } },
            [_vm._v(" " + _vm._s(_vm.$t("authentication.login")) + " ")]
          )
        ],
        1
      ),
      _c("h2", [_vm._v(_vm._s(_vm.$t("view_meta.register_for_free_account")))]),
      _c("div", { staticClass: "inputs" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.email,
                  expression: "email",
                  modifiers: { trim: true }
                }
              ],
              attrs: { type: "text", placeholder: _vm.$t("users.email") },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c("v-img", { staticClass: "icon", attrs: { src: _vm.emailSVG } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("authentication.login_form.password")
              },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            }),
            _c("v-img", {
              staticClass: "icon",
              attrs: { src: _vm.passwordSVG }
            })
          ],
          1
        )
      ]),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.submit()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("authentication.create_account")))]
      ),
      _c("div", { staticClass: "angle" })
    ]),
    _c("div", { staticClass: "background" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }