var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _c(
        "td",
        {
          class:
            "text-cut " +
            (_vm.projectRole.id === "ALL" ? "font-weight-bold caption" : "")
        },
        [_vm._v(" " + _vm._s(_vm.projectRole.project.name) + " ")]
      ),
      _vm._l(_vm.roleOptions, function(role) {
        return _c(
          "td",
          { key: role },
          [
            _c("v-checkbox", {
              staticClass: "role-checkbox",
              attrs: { dense: "", value: role },
              on: {
                change: function($event) {
                  return _vm.changeRole(role)
                }
              },
              model: {
                value: _vm.roles,
                callback: function($$v) {
                  _vm.roles = $$v
                },
                expression: "roles"
              }
            })
          ],
          1
        )
      }),
      _c(
        "td",
        [
          _c("v-checkbox", {
            staticClass: "role-checkbox",
            attrs: { dense: "", disabled: !_vm.roles.length && _vm.noRole },
            on: {
              change: function($event) {
                return _vm.changeNoRole(_vm.noRole)
              }
            },
            model: {
              value: _vm.noRole,
              callback: function($$v) {
                _vm.noRole = $$v
              },
              expression: "noRole"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }