var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { "justify-content": "space-between" },
      attrs: { wrap: "" }
    },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("users.title")))
      ]),
      _c(
        "v-btn",
        {
          attrs: { outlined: "" },
          on: {
            click: function($event) {
              _vm.addUsersModal = true
            }
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("project_settings.users.add")) + " "),
          _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "mt-4 scroll-on-mobile", attrs: { xs12: "" } },
        [
          [
            _c("v-data-table", {
              staticStyle: { "min-width": "400px" },
              attrs: {
                headers: _vm.tableHeaders,
                items: _vm.users,
                expanded: _vm.expandedItems,
                "hide-default-footer": "",
                "mobile-breakpoint": 1,
                "disable-pagination": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "tr",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleExpandAsset(item)
                            }
                          }
                        },
                        [
                          _c(
                            "td",
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "mr-4 hide-below-1000",
                                  attrs: { size: "36" }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src:
                                        item.imageUrl || _vm.avatarPlaceholder
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [
                                _vm._v(" " + _vm._s(item.fullName) + " ")
                              ])
                            ],
                            1
                          ),
                          _c("td", { staticClass: "hide-below-800" }, [
                            _vm._v(" " + _vm._s(item.email) + " ")
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.timeAgoInWords(
                                    _vm.toTimeStamp(item.lastOnline)
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.confirmDeleteUser(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mx-auto" }, [
                                    _vm._v(" delete ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "expanded-item",
                  fn: function(ref) {
                    var item = ref.item
                    var headers = ref.headers
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "table-row",
                          attrs: { colspan: headers.length }
                        },
                        [
                          _c("manage-user-roles", {
                            key: item.id,
                            attrs: { user: item }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ]
        ],
        2
      ),
      _c("add-user-modal", {
        model: {
          value: _vm.addUsersModal,
          callback: function($$v) {
            _vm.addUsersModal = $$v
          },
          expression: "addUsersModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }