var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-4" }, [
    _vm.assetId
      ? _c("span", [_vm._v(_vm._s(_vm.$t("history.asset_id_history")))])
      : _c("span", [_vm._v(_vm._s(_vm.$t("history.translation_history")))]),
    _vm.history && _vm.history.length > 0
      ? _c(
          "div",
          _vm._l(_vm.history, function(entry) {
            return _c(
              "transition",
              { key: entry.id, attrs: { appear: "", name: "slide-fade" } },
              [
                _c(
                  "div",
                  { staticClass: "entry my-2 px-4 py-2" },
                  [
                    entry.byUser
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-avatar",
                                        _vm._g(
                                          {
                                            staticClass: "my-2 mr-4",
                                            attrs: { size: "60" }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src:
                                                entry.byUser.imageUrl ||
                                                _vm.avatarPlaceholder
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(entry.byUser.fullName))])]
                        )
                      : _vm._e(),
                    entry.byApiKey
                      ? _c(
                          "v-avatar",
                          _vm._g(
                            { staticClass: "my-2 mr-4", attrs: { size: "60" } },
                            _vm.on
                          ),
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("history.changed_by_api_key"))
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(entry.byApiKey.name) + " ")
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _c("p", { staticClass: "my-0 gray" }, [
                        _vm._v(
                          _vm._s(
                            _vm.timeAgoInWords(_vm.toTimeStamp(entry.datetime))
                          )
                        )
                      ]),
                      entry.oldValue || entry.oldValue === ""
                        ? _c("i", { staticClass: "my-0 old_value" }, [
                            _vm._v(_vm._s(entry.oldValue))
                          ])
                        : _c("i", { staticClass: "my-0 gray" }, [
                            _vm._v(_vm._s(_vm.$t("global.created")))
                          ]),
                      _c("p", { staticClass: "my-0" }, [
                        _vm._v(_vm._s(entry.value))
                      ])
                    ]),
                    entry.byAutomatedTranslation
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "pa-1",
                                            staticStyle: {
                                              "border-radius": "50px"
                                            },
                                            attrs: { small: "" }
                                          },
                                          on
                                        ),
                                        [_vm._v(" mdi-robot ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "history.changed_by_automated_translation"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    entry.byImport
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "pa-1",
                                            staticStyle: {
                                              "border-radius": "50px"
                                            },
                                            attrs: { small: "" }
                                          },
                                          on
                                        ),
                                        [_vm._v(" publish ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("history.changed_by_import"))
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          1
        )
      : _c("div", [_vm._v(" " + _vm._s(_vm.$t("history.not_found")) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }