

















































































import Vue from 'vue';
import { state } from "@/store";
import getRoutePermissions from '@/utils/getRoutePermissions';
import client from '@/graphql/client';
import {Route} from "vue-router";
import { ProjectStatsType } from '@/generated/graphql';
import {captureException} from "@/utils/debugging";

export default Vue.extend({
  name: 'Project',

  props: { project: { type: Object, required: true } },

  data() {
    return {
      projectDetails: {},
      stats: {} as ProjectStatsType,
      projectStatsAreFetched: false,
      state,
      noImagePlaceholder: '/assets/no-image-placeholder.jpg',
    }
  },

  computed: {
    projectPage(): any {
      return this.availableRoutes && this.availableRoutes[0] && this.availableRoutes[0].name
    },

    availableRoutes(): Route[] {
      if (this.state.user.roles) {
        // @ts-ignore
        return this.$router.options.routes
          .find(route => route.name === 'project-wrapper')
          .children
          .filter(route => getRoutePermissions(route, this.project.slug));
      } else return []
    },
  },

  async created() {
    try {
      const { data } = await client.getProjectStats({projectId: this.project.id});
      this.stats = data.projectStats
      if (this.stats) {
        this.stats.translated = this.$n(this.stats.translated, 'percent');
        this.projectStatsAreFetched = true;
      }
    } catch (e) {
      captureException(e);
    }
  },

  methods: {
    getTranslatedStatsName(key) {
      return {
        users: this.$t("stats.users"),
        translations: this.$t("stats.translations"),
        languages: this.$t("stats.languages"),
        translated: this.$t("stats.translated"),
      }[key]
    },
  },
});
