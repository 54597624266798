









import Vue from 'vue'

export default Vue.extend({
  name: 'AssetSearch',

  props: {
    value: { type: String, required: false, default: '' }
  },
})
