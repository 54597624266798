import Vue from 'vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from '@/App.vue';

import '@/plugins/install';
import { authTokenInterceptor } from '@/utils/jwt';
import axiosApiInstance from '@/utils/api';
import { get } from 'lodash';

axiosApiInstance.interceptors.request.use(authTokenInterceptor);
axiosApiInstance.interceptors.response.use(function (response: any) {
  const errors = get(response, "data.errors")
  if (errors
    && get(errors[0], "code") === "errors.jwt_expired"
  ) {
    window.location.href = '/logout';
    return response
  } 
  return response
})

console.log(`build ${process.env.VUE_APP_GIT_HEAD || ''}`)

new Vue({
  i18n,
  router,
  render: h => h(App),
  vuetify,
}).$mount('#app');
