











































import Vue from 'vue';
import { handleErrors, showSuccessNotification } from '@/utils/notifications';
import client from '@/graphql/client';
import { OrganizationRole } from '@/generated/graphql';
import events from '@/events';

export default Vue.extend({
  name: 'AddOrgUserModal',

  props: {
    value: { type: Boolean, required: true },
    organizationId: { type: String, required: true },
  },

  data() {
    return {
      role: OrganizationRole.Member,
      email: '',
    };
  },

  watch: {
    value() {
      this.email = '';
      this.role = OrganizationRole.Member;
    }
  },

  methods: {
    async submit() {
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }

      const { data, errors } = await client.inviteOrganizationUser({data: {
        newUserEmail: this.email,
        organizationId: this.organizationId,
        makeUserAdmin: this.role == OrganizationRole.Admin,
      }});

      if (data) {
        showSuccessNotification(this.$t('organizations.user_invited_success'), 3000);
        this.$emit('input', false);
        events.refetchOrganizationDetail.emit(this.organizationId)
      } else {
        handleErrors(errors);
      }
    }
  }
});
