<template>
  <g-modal
    :value="true"
    max-width="600"
    :title="$t('view_meta.reset_password.title')"
    icon="person"
    persistent
  >
    <v-form
      ref="form"
      @submit.prevent="submitForm"
    >
      <v-text-field
        v-model="newPassword"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        :rules="$validation.password"
        validate-on-blur
        :type="showPassword ? 'text' : 'password'"
        :label="$t('authentication.create_password')"
        counter
        @click:append="showPassword = !showPassword"
      />

      <button
        style="display: none"
        type="submit"
      />
    </v-form>

    <template #actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="submitForm"
      >
        Send
        <v-icon
          right
          dark
          medium
        >
          how_to_reg
        </v-icon>
      </v-btn>

      <v-btn @click="$router.push('/')">
        Cancel
      </v-btn>
    </template>
  </g-modal>
</template>

<script>
import { handleErrors } from '@/utils/notifications';
import { state } from '@/store';
import { login, logout, authenticate } from '@/utils/auth';
import vueI18n from '@/plugins/i18n';
import client from '@/graphql/client';
import { saveAccessToken, saveRefreshToken } from '@/utils/jwt';

export default {
  name: 'ResetPassword',

  metaInfo: {
    get title() { return vueI18n.t("view_meta.reset_password.title") },
  },

  data() {
    return {
      newPassword: '',
      showPassword: false,
      window: window,
      state,
    };
  },

  methods: {
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const { data, errors } = await client.confirmResetPassword({
        data: {
          password: this.newPassword,
          token: `${this.$router.currentRoute.query.token}`,
          uid: `${this.$router.currentRoute.query.uid}`,
        }
      });
  
      if (errors.length) {
        handleErrors(errors)
        return
      }

      const { accessToken, refreshToken, refreshExpiresIn } = data.confirmResetPassword
      saveAccessToken(accessToken)
      saveRefreshToken(refreshToken, refreshExpiresIn)
      await authenticate()

      await this.$router.push({ path: '/' })
    }
  }
};
</script>
