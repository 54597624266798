






































































import Vue from 'vue';
import store from '@/store';
import Tag from '@/components/tags/Tag.vue';
import AddTagModal from '@/components/tags/AddTagModal.vue';
import events from "@/events";
import { TagFieldsFragment } from '@/generated/graphql';

export default Vue.extend({
  name: 'TagSelect',

  components: { Tag, AddTagModal },

  props: {
    value: { type: Array, required: false, default: null }, // list of tag IDs
    small: { type: Boolean, required: false, default: false }, // list of tag IDs
    xSmall: { type: Boolean, required: false, default: false }, // list of tag IDs
  },

  data() {
    return {
      options: [] as TagFieldsFragment[],
      showAddNewTagModal: false,
    }
  },

  computed: {
    fontSize(): number {
      if (this.xSmall) {
        return 10
      }

      return this.small
        ? 13
        : 16
    },
  },

  created() {
    const getTags = () => {
      this.options = store.getTagOptions();
    };
    events.tagsChanged.on(getTags);
    getTags()
  },
});
