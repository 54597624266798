import { configureScope } from "@sentry/browser";
import store from "../store";
import query from "@/plugins/graphql";
import router from "@/router";
import chatclient from "@/utils/chatclient";
import events from "@/events";
import mutations from "@/graphql/mutations";
import queries from "@/graphql/queries";
import { clearToken, getRefreshToken, saveAccessToken, saveRefreshToken } from "./jwt";
import {
  Mutation,
  MutationTokenAuthArgs,
  Query,
  UserType,
} from "@/generated/graphql";

export function setUserToDebugger(user?: UserType) {
  configureScope((scope) => {
    scope.setUser({
      username: user && (user.fullName || "guest"),
      id: user && user.id,
    });
  });
}

export function handleUnauthenticated() {
  store.logout()
  clearToken(); // Clear access-token & refreshToken in localStorage
  getRefreshToken() &&
    query(
      // Revoke refresh-token
      mutations.revokeRefreshToken,
      { refreshToken: getRefreshToken() }
    );
  setUserToDebugger();
  events.unauthenticated.emit();
}

export function handleAuthenticated(user: UserType) {
  store.login(user);
  chatclient.setEmail(user.email);
  setUserToDebugger(user);
  events.authenticated.emit();
}

export async function logout(redirect = true) {
  handleUnauthenticated();
  if (redirect) {
    await router.push("/");
  }
}

export async function login(email: string, password: string) {
  const {
    data, errors
  } = await query<Pick<Mutation, "tokenAuth">, MutationTokenAuthArgs>(
    mutations.obtainToken,
    { username: email, password },
    { disableAuth: true }
  );

  if (errors.length || !data) {
    handleUnauthenticated();
    return errors;
  }

  saveAccessToken(data.tokenAuth.token);
  saveRefreshToken(data.tokenAuth.refreshToken, data.tokenAuth.refreshExpiresIn);
  await authenticate();
}

export async function authenticate() {
  const {
    data, errors
  } = await query<Pick<Query, "currentUser">, any>(queries.currentUser, {});

  if (errors.length) {
    return handleUnauthenticated();
  }

  handleAuthenticated(data.currentUser);
}
