<template>
  <div
    style="height: 100%; width: 100%; cursor: pointer;"
    @click="$refs.file.click()"
  >
    <v-img
      v-if="imageInBytes"
      class="edit_image"
      style="height: 100%; width: 100%"
      :src="imageInBytes"
      :class="{loading: uploading}"
    >
      <p
        v-if="uploading"
        class="title text--black text-center"
        style="margin-top: 45%"
      >
        ...{{ $t('images.uploading') }}
      </p>
    </v-img>

    <v-img
      :style="`display: ${ imageInBytes ? 'none' : ''}`"
      class="edit_image white--text"
      style="height: 100%; width: 100%"
      :src="value.imageUrl || placeholderImage"
      @load="onFinishedLoading"
    >
      <slot />
    </v-img>

    <input
      id="file"
      ref="file"
      accept="image/*"
      type="file"
      name="file"
      style="display:none"
      @change="upload"
    >
  </div>
</template>

<script>
import { captureException } from '@sentry/browser';
import { print } from 'graphql/language/printer';
import client from '@/graphql/client';
import { handleErrors, showErrorNotification } from '../../utils/notifications';
import mutations from '@/graphql/mutations';
import axiosApiInstance, { createResponse } from '@/utils/api';
import { createFormData } from '@/utils/graphql'
import { createErrorResponse } from '@/plugins/graphql';
import placeholderImage from "@/assets/image_upload_placeholder.jpg"

const imageUploadUrl = `${process.env.VUE_APP_API_URL}/upload-image/`;

export default {
  name: 'GEditImage',

  props: {
    // value a value with any fields, but should expect imageUrl and image: ID
    value: Object,
  },

  data() {
    return {
      imageInBytes: null,
      uploading: false,
      placeholderImage,
    };
  },

  methods: {
    onFinishedLoading() {
      this.imageInBytes = null;
      this.uploading = false;
    },

    updateFields(objectWithFields = {}) {
      this.$emit('input', {
        ...this.value,
        ...objectWithFields,
      });
    },

    async upload(event) {
      const file = event.target.files[0];
      this.$refs.file.value = null; // clear input so next input triggers change.

      // Check for validity
      if (!file) { return showErrorNotification(); }

      // Read file so we dont have to wait for upload
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageInBytes = e.target.result;
      };
      reader.readAsDataURL(file);

      this.uploading = true;

      // Actually upload the file
      const operation = JSON.stringify({ query: print(mutations.uploadImage), variables: {image: null} })
      const formData = new FormData()
      formData.append('map', JSON.stringify({'0': ['variables.image']}))
      formData.append('0', file, file.name)
      formData.append('operations', operation)

      try {
        const response = await axiosApiInstance.post('/', formData)
        const { data, errors } = createResponse(response)
        if (errors.length) {
          showErrorNotification(errors)
        }

        this.updateFields({
          imageUrl: data.uploadImage.url,
          image: data.uploadImage.id,
        });
      } catch (error) {
        captureException(error);
        showErrorNotification();
      }
    },
  },
};
</script>

<style>
.edit_image:hover {
  opacity: 0.7;
}
</style>
