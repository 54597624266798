<template>
  <v-app id="locale">
    <v-skeleton-loader
      v-if="!loadedState"
      type="text"
      class="ma-auto"
      width="100%"
      max-width="300px"
    />
    <div v-else>
      <mobile-navigation v-if="state.isMobile" />
      <sidebar v-else />

      <v-content>
        <router-view />
      </v-content>

      <load-screen />
      <notifications />
      <user-message-dialog />
      <confirm-dialog />
      <confirm-delete-dialog />
      <set-password-modal v-if="state.showChangePassword" />
      <portal-target name="modal" />
      <full-page-loader :loading="loading" />
    </div>
  </v-app>
</template>

<script>
import { state } from '@/store';
import Notifications from '@/components/Notifications.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog.vue';
import SetPasswordModal from './components/auth/SetPasswordModal.vue';
import { authenticate } from './utils/auth';
import UserMessageDialog from '@/components/UserMessageDialog';
import MobileNavigation from '@/components/navigation/MobileNavigation';
import Sidebar from '@/components/navigation/Sidebar';
import vueI18n from '@/plugins/i18n';
import client from '@/graphql/client';
import events from '@/events';
import LoadScreen from "@/components/LoadScreen";
import FullPageLoader from './components/FullPageLoader.vue';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    get title() {
      try {
        return `${vueI18n.t("view_meta.default.title")}`;
      } catch (e) {
        return "Translations"
      }
    },
    // all titles will be injected into this template
    titleTemplate: '%s | TranslationHut'
  },

  components: {
    LoadScreen,
    Sidebar,
    MobileNavigation,
    UserMessageDialog,
    ConfirmDialog,
    ConfirmDeleteDialog,
    Notifications,
    SetPasswordModal,
    FullPageLoader,
  },

  data() {
    return {
      state,
      loadedState: false,
      loading: false,
    };
  },

  async created() {
    events.refetchState.on(authenticate);
    await this.loadState()
    events.showLoader.on(this.setLoading)
  },

  methods: {
    async loadState() {
      this.loadedState = false;
      await Promise.all([
        authenticate(),
        this.fetchFileFormats(),
      ]);
      this.loadedState = true;
    },

    async fetchFileFormats() {
      const {data: {fileFormats = []}} = await client.getFileFormats();
      state.fileFormats = fileFormats;
    },

    async setLoading(value) {
      this.loading = value;
    }
  },
};
</script>

<style lang="scss">
@import 'sass/globals';
</style>
