import {
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  ApiKeysQuery,
  ApiKeysQueryVariables,
  AssetByIdQuery,
  AssetByIdQueryVariables,
  AssetsQuery,
  AssetsQueryVariables,
  GetCreditCardsQuery,
  GetCreditCardsQueryVariables,
  GetExportedDataHistoryQuery,
  GetExportedDataHistoryQueryVariables,
  MachineTranslationOrderQuery,
  MachineTranslationOrderQueryVariables,
  MachineTranslationOrdersQuery,
  MachineTranslationOrdersQueryVariables,
  Mutation,
  MutationConfirmResetPasswordArgs,
  MutationCreateAccountArgs,
  MutationCreateApiKeyArgs,
  MutationCreateAssetArgs,
  MutationCreateCommentArgs,
  MutationCreateCreditCardArgs,
  MutationCreateOrganizationArgs,
  MutationCreateProjectArgs,
  MutationCreateTagArgs,
  MutationCreateTranslationArgs,
  MutationDeleteTagArgs,
  MutationDeleteUserRoleArgs,
  MutationExportDataArgs,
  MutationImportDataArgs,
  MutationInviteOrganizationUserArgs,
  MutationInviteProjectUserArgs,
  MutationLinkProjectToOrganizationArgs,
  MutationOrderMachineTranslationArgs,
  MutationRemoveOrganizationArgs,
  MutationRemoveOrganizationUserArgs,
  MutationResetPasswordArgs,
  MutationRevokeApiKeyArgs,
  MutationSetPasswordArgs,
  MutationUpdateApiKeyArgs,
  MutationUpdateAssetArgs,
  MutationUpdateOrganizationArgs,
  MutationUpdateOrganizationRoleArgs,
  MutationUpdatePlanArgs,
  MutationUpdateProjectArgs,
  MutationUpdateTranslationArgs,
  MutationUpdateUserArgs,
  MutationUpdateUserProjectRolesArgs,
  MutationUpdateUserRoleArgs,
  MutationUploadImageArgs,
  OrganizationDetailQuery,
  OrganizationDetailQueryVariables,
  OrganizationUsageQuery,
  OrganizationUsageQueryVariables,
  Query,
  QueryGetUserRoleArgs,
  QueryProjectArgs,
  QueryProjectStatsArgs,
  TranslationByIdQuery,
  TranslationByIdQueryVariables,
  UsageQuery,
  UsageQueryVariables,
} from "@/generated/graphql";
import mutations from "@/graphql/mutations";
import queries from "@/graphql/queries";
import query from "@/plugins/graphql";
import { QueryResponse } from "@/utils/api";

export type MutationResponse<T extends keyof Mutation> = Pick<Mutation, T>;
export type QueryRespone<T extends keyof Query> = Pick<Query, T>;

export default {
  // Mutations
  createAccount: (data: MutationCreateAccountArgs) =>
    query<MutationResponse<"createAccount">, MutationCreateAccountArgs>(
      mutations.createAccount,
      data
    ),

  updateUser: ({ data }: MutationUpdateUserArgs) =>
    query<MutationResponse<"updateUser">, MutationUpdateUserArgs>(mutations.updateUser, {
      data,
    }),

  setPassword: (password: string) =>
    query<MutationResponse<"setPassword">, MutationSetPasswordArgs>(mutations.setPassword, {
      password,
    }),

  resetPassword: (email: string) =>
    query<MutationResponse<"resetPassword">, MutationResetPasswordArgs>(mutations.resetPassword, {
      email,
    }),

  confirmResetPassword: (data) =>
    query<MutationResponse<"confirmResetPassword">, MutationConfirmResetPasswordArgs>(
      mutations.confirmResetPassword,
      data
    ),

  inviteProjectUser: (data: MutationInviteProjectUserArgs) =>
    query<MutationResponse<"inviteProjectUser">, MutationInviteProjectUserArgs>(
      mutations.inviteProjectUser,
      data
    ),

  updateUserRoles: (data: MutationUpdateUserRoleArgs) =>
    query<MutationResponse<"updateUserRole">, MutationUpdateUserRoleArgs>(
      mutations.updateUserRole,
      data
    ),

  deleteUserRoles: (data: MutationDeleteUserRoleArgs) =>
    query<MutationResponse<"deleteUserRole">, MutationDeleteUserRoleArgs>(
      mutations.deleteUserRole,
      data
    ),

  createProject: (data: MutationCreateProjectArgs) =>
    query<MutationResponse<"createProject">, MutationCreateProjectArgs>(
      mutations.createProject,
      data
    ),

  updateProject: (data: MutationUpdateProjectArgs) =>
    query<MutationResponse<"updateProject">, MutationUpdateProjectArgs>(
      mutations.updateProject,
      data
    ),

  createTag: (data: MutationCreateTagArgs) =>
    query<MutationResponse<"createTag">, MutationCreateTagArgs>(mutations.createTag, data),

  deleteTag: (data: MutationDeleteTagArgs) =>
    query<MutationResponse<"deleteTag">, MutationDeleteTagArgs>(mutations.deleteTag, data),

  createAsset: (data: MutationCreateAssetArgs) =>
    query<MutationResponse<"createAsset">, MutationCreateAssetArgs>(mutations.createAsset, data),

  updateAsset: (data: MutationUpdateAssetArgs) =>
    query<MutationResponse<"updateAsset">, MutationUpdateAssetArgs>(mutations.updateAsset, data),

  createTranslation: (data: MutationCreateTranslationArgs) =>
    query<MutationResponse<"createTranslation">, MutationCreateTranslationArgs>(
      mutations.createTranslation,
      data
    ),

  updateTranslation: (data: MutationUpdateTranslationArgs) =>
    query<MutationResponse<"updateTranslation">, MutationUpdateTranslationArgs>(
      mutations.updateTranslation,
      data
    ),

  createComment: (data: MutationCreateCommentArgs) =>
    query<MutationResponse<"createComment">, MutationCreateCommentArgs>(
      mutations.createComment,
      data
    ),

  createApiKey: (data: MutationCreateApiKeyArgs) =>
    query<MutationResponse<"createApiKey">, MutationCreateApiKeyArgs>(mutations.createApiKey, data),
  
  updateApiKey: (data: MutationUpdateApiKeyArgs) =>
    query<MutationResponse<"updateApiKey">, MutationUpdateApiKeyArgs>(mutations.updateApiKey, data),

  revokeApiKey: (data: MutationRevokeApiKeyArgs) =>
    query<MutationResponse<"revokeApiKey">, MutationRevokeApiKeyArgs>(
      mutations.revokedApiKey,
      data
    ),

  orderMachineTranslation: (data: MutationOrderMachineTranslationArgs) =>
    query<MutationResponse<"orderMachineTranslation">, MutationOrderMachineTranslationArgs>(
      mutations.orderMachineTranslation,
      data
    ),

  importData: (data: MutationImportDataArgs) =>
    query<MutationResponse<"importData">, MutationImportDataArgs>(mutations.importData, data),

  exportData: (data: MutationExportDataArgs) =>
    query<MutationResponse<"exportData">, MutationExportDataArgs>(mutations.exportData, data),

  createCreditCard: (data: MutationCreateCreditCardArgs) =>
    query<MutationResponse<"createCreditCard">, MutationCreateCreditCardArgs>(
      mutations.createCreditCard,
      data
    ),

  uploadImage: (data: MutationUploadImageArgs) =>
    query<MutationResponse<"uploadImage">, MutationUploadImageArgs>(mutations.uploadImage, data),

  acceptInvite: (data: AcceptInviteMutationVariables) =>
    query<AcceptInviteMutation, AcceptInviteMutationVariables>(mutations.acceptInvite, data),

  updateOrganizationRole: (data: MutationUpdateOrganizationRoleArgs) =>
    query<MutationResponse<"updateOrganizationRole">, MutationUpdateOrganizationRoleArgs>(
      mutations.updateOrganizationRole,
      data
    ),

  createOrganization: (data: MutationCreateOrganizationArgs) =>
    query<MutationResponse<"createOrganization">, MutationCreateOrganizationArgs>(
      mutations.createOrganization,
      data
    ),

  updateOrganization: (data: MutationUpdateOrganizationArgs) =>
    query<MutationResponse<"updateOrganization">, MutationUpdateOrganizationArgs>(
      mutations.updateOrganization,
      data
    ),

  inviteOrganizationUser: (data: MutationInviteOrganizationUserArgs) =>
    query<MutationResponse<"inviteOrganizationUser">, MutationInviteOrganizationUserArgs>(
      mutations.inviteOrganizationUser,
      data
    ),

  removeOrganizationUser: (data: MutationRemoveOrganizationUserArgs) =>
    query<MutationResponse<"removeOrganizationUser">, MutationRemoveOrganizationUserArgs>(
      mutations.removeOrganizationUser,
      data
    ),

  updateUserProjectRoles: (data: MutationUpdateUserProjectRolesArgs) =>
    query<MutationResponse<"updateUserProjectRoles">, MutationUpdateUserProjectRolesArgs>(
      mutations.updateUserProjectRoles,
      data
    ),

  linkProjectToOrganization: (data: MutationLinkProjectToOrganizationArgs) =>
    query<MutationResponse<"linkProjectToOrganization">, MutationLinkProjectToOrganizationArgs>(
      mutations.linkProjectToOrganization,
      data
    ),
  
  removeOrganization: (data: MutationRemoveOrganizationArgs) =>
    query<MutationResponse<"removeOrganization">, MutationRemoveOrganizationArgs>(
      mutations.removeOrganization,
      data
    ),

  updatePlan: (data: MutationUpdatePlanArgs) =>
    query<MutationResponse<"updatePlan">, MutationUpdatePlanArgs>(
      mutations.updatePlan,
      data
    ),

  // Queries
  getUserRole: ({ userId, projectId }) =>
    query<QueryRespone<"getUserRole">, QueryGetUserRoleArgs>(queries.getUserRole, {
      userId,
      projectId,
    }),

  getProjectStats: ({ projectId }) =>
    query<QueryRespone<"projectStats">, QueryProjectStatsArgs>(queries.projectStats, { projectId }),

  getProject: ({ slug }) =>
    query<QueryRespone<"project">, QueryProjectArgs>(queries.getProject, { slug }),

  getLanguages: () => query<QueryResponse<"languages">, any>(queries.getLanguages, {}),

  getFileFormats: () => query<QueryResponse<"fileFormats">, any>(queries.getFileFormats, {}),

  getAssets: (params: AssetsQueryVariables) =>
    query<AssetsQuery, AssetsQueryVariables>(queries.getAssets, params),

  getAssetById: (params) =>
    query<AssetByIdQuery, AssetByIdQueryVariables>(queries.getAssetById, params),

  getTranslationById: (params: TranslationByIdQueryVariables) =>
    query<TranslationByIdQuery, TranslationByIdQueryVariables>(queries.getTranslationById, params),

  getApiKeys: (params: ApiKeysQueryVariables) =>
    query<ApiKeysQuery, ApiKeysQueryVariables>(queries.getApiKeys, params),

  getMachineTranslationOrders: (params: MachineTranslationOrdersQueryVariables) =>
    query<MachineTranslationOrdersQuery, MachineTranslationOrdersQueryVariables>(
      queries.getMachineTranslationOrders,
      params
    ),

  getMachineTranslationOrder: (params: MachineTranslationOrderQueryVariables) =>
    query<MachineTranslationOrderQuery, MachineTranslationOrderQueryVariables>(
      queries.getMachineTranslationOrder,
      params
    ),

  getUsageForProject: (params: UsageQueryVariables) =>
    query<UsageQuery, UsageQueryVariables>(queries.getUsage, params),

  getExportedDataHistory: (params: GetExportedDataHistoryQueryVariables) =>
    query<GetExportedDataHistoryQuery, GetExportedDataHistoryQueryVariables>(
      queries.getExportedDataHistory,
      params
    ),

  getCreditCards: (params: GetCreditCardsQueryVariables) =>
    query<GetCreditCardsQuery, GetCreditCardsQueryVariables>(queries.getCreditCards, params),

  organizationDetail: (params: OrganizationDetailQueryVariables) =>
    query<OrganizationDetailQuery, OrganizationDetailQueryVariables>(
      queries.organizationDetail,
      params
    ),

  organizationUsage: (params: OrganizationUsageQueryVariables) =>
    query<OrganizationUsageQuery, OrganizationUsageQueryVariables>(
      queries.organizationUsage,
      params
    )
};
