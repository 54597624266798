<template>
  <g-modal
    v-model="showDialog"
    max-width="600"
    :title="title"
  >
    {{ text || $t('global.delete_confirmation') }}

    <template #actions>
      <v-btn @click="cancel">
        {{ cancelText || $t('actions.cancel') }}
      </v-btn>

      <v-btn
        type="button"
        color="primary"
        @click="confirm"
      >
        {{ confirmText || $t('actions.accept') }}
      </v-btn>
    </template>
  </g-modal>
</template>

<script>
import events from '@/events';

export default {
  name: 'ConfirmDialog',

  data() {
    return {
      showDialog: false,
      callback: null,
      title: null,
      cancelText: null,
      confirmText: null,
      text: null,
    };
  },

  created() {
    events.openConfirmDialog.on(({
      // options:
      callback,
      title,
      cancelText,
      confirmText,
      text,
    }) => {
      if (!callback) {
        throw Error("'confirm-dialog' event was emitted without callback");
      }

      this.callback = callback;
      this.title = title;
      this.cancelText = cancelText;
      this.confirmText = confirmText;
      this.text = text;
      this.showDialog = true;
    });
  },

  methods: {
    confirm() {
      this.showDialog = false; // close modal first because callback might cause errors
      this.callback();
      this.resetState();
    },

    cancel() {
      this.resetState();
    },

    resetState() {
      this.showDialog = false;
      this.callback = null;
      this.title = null;
      this.cancelText = null;
      this.confirmText = null;
      this.text = null;
    },
  },
};
</script>
