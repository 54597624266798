






































































import Vue from 'vue';
import { format } from 'date-fns';
import { debounce, xorBy } from 'lodash';
import store from '@/store';
import CreateApiKeyDialog from '@/components/develop/CreateApiKeyDialog.vue';
import { handleOptionalErrors } from '@/utils/notifications';
import { timeAgoInWords, toTimeStamp } from '@/utils/datetime';
import client from "@/graphql/client";
import events from "@/events";
import { ApiKeyType } from '@/generated/graphql';

export default Vue.extend({
  name: 'ApiKeys',

  components: { CreateApiKeyDialog },

  data() {
    return {
      apiKeys: [] as ApiKeyType[],
      loading: false,
      displayAddSecret: false,
      headers: [
        { value: 'name', text: this.$t('global.name') },
        { value: 'prefix', text: this.$t('api_keys.prefix') },
        { value: 'created', text: this.$t('global.created') },
        { value: 'lastOnline', text: this.$t('api_keys.last_used') },
        { value: 'expiryDate', text: this.$t('api_keys.expiry_date') },
        { value: 'delete', text: this.$t('actions.revoke') },
      ],
      expanded: [],
      roles: [],
      roleChoices: ["admin", "manager", "developer", "translator"]
    }
  },

  created() {
    this.fetchKeys();
    //@ts-ignore
    this.$handleOff(events.createdApiKey.on(this.fetchKeys));
  },

  methods: {
    timeAgoInWords,
    toTimeStamp,
    format,

    async deleteItem({id}) {
      const { errors } = await client.revokeApiKey({ id });
      if (errors.length) {
        handleOptionalErrors(errors);
        return false;
      }
      this.loading = true;
      this.fetchKeys();
    },

    async fetchKeys() {
      this.loading = true;
      const projectId = store.state.project.id;
      const { data, errors} = await client.getApiKeys({ projectId });
      if (errors.length) {
        handleOptionalErrors(errors);
      }
      else {
        const apiKeys = data.apiKeys
        this.apiKeys = apiKeys.filter(v => !!v);
      }
      this.loading = false;
    },

    rowClick(item) {
      this.expanded = xorBy([item], this.expanded, 'id')
    },

    async updateApiKeyRoles(id, roles) {
      await client.updateApiKey({
        id,
        roles
      })
    },

    debounceUpdateApiKeyRoles: debounce(function (id, roles) { this.updateApiKeyRoles(id, roles) }, 500),

    isExpired(expiryDate: string): boolean {
      if (!expiryDate) {
        return false
      }
      const today = new Date().getTime()
      const expiryDateToTime = new Date(expiryDate).getTime()
      return today >= expiryDateToTime
    }
  },
});
