var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      staticClass: "fill-height",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "v-layout",
        { staticClass: "pa-4", attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("category-auto-complete", {
                on: { submit: _vm.submit },
                model: {
                  value: _vm.changedAsset.value,
                  callback: function($$v) {
                    _vm.$set(_vm.changedAsset, "value", $$v)
                  },
                  expression: "changedAsset.value"
                }
              })
            ],
            1
          ),
          _vm._l(_vm.filteredDisplayFields, function(field, i) {
            return _c(
              "v-flex",
              { key: field.value, attrs: { xs12: "" } },
              [
                _c("v-textarea", {
                  attrs: {
                    rows: "4",
                    "auto-grow": "",
                    label: field.label,
                    autofocus: i === 0
                  },
                  on: { keypress: _vm.handleTextAreaKeyPress },
                  scopedSlots: _vm._u(
                    [
                      field.languageCode
                        ? {
                            key: "append-outer",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "32px",
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      staticClass: "flag",
                                      attrs: {
                                        eager: "",
                                        "max-width": "30",
                                        src: _vm.imageForLanguage(
                                          field.languageCode
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.changedAsset[field.value],
                    callback: function($$v) {
                      _vm.$set(_vm.changedAsset, field.value, $$v)
                    },
                    expression: "changedAsset[field.value]"
                  }
                })
              ],
              1
            )
          }),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "4",
                  "auto-grow": "",
                  label: _vm.$t("assets.description")
                },
                on: { keypress: _vm.handleTextAreaKeyPress },
                model: {
                  value: _vm.changedAsset.description,
                  callback: function($$v) {
                    _vm.$set(_vm.changedAsset, "description", $$v)
                  },
                  expression: "changedAsset.description"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("tag-select", {
                attrs: { small: "" },
                model: {
                  value: _vm.changedAsset.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.changedAsset, "tags", $$v)
                  },
                  expression: "changedAsset.tags"
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c(
            "v-expand-transition",
            [
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.displaySettings,
                      expression: "displaySettings"
                    }
                  ],
                  staticClass: "py-2",
                  attrs: { wrap: "" }
                },
                [
                  _c("g-chip-select", {
                    attrs: {
                      items: _vm.fieldOptions,
                      label: _vm.$t("filters.displayed_fields"),
                      "return-object": ""
                    },
                    model: {
                      value: _vm.displayFields,
                      callback: function($$v) {
                        _vm.displayFields = $$v
                      },
                      expression: "displayFields"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "justify-space-between" },
            [
              _c(
                "g-toggle-icon",
                {
                  model: {
                    value: _vm.displaySettings,
                    callback: function($$v) {
                      _vm.displaySettings = $$v
                    },
                    expression: "displaySettings"
                  }
                },
                [_vm._v("settings")]
              ),
              _c(
                "span",
                [
                  _c("v-btn", { on: { click: _vm.close } }, [
                    _vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")
                  ]),
                  _vm.asset.archived
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          on: { click: _vm.unarchiveAsset }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("actions.unarchive")) + " "
                          )
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: { color: "error" },
                          on: { click: _vm.archiveAsset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("actions.archive")) + " ")]
                      ),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.hasValue, color: "primary" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }