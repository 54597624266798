var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    _vm._b(
      {
        attrs: { value: _vm.displayModal },
        on: {
          input: function($event) {
            return _vm.$emit("input", false)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { staticClass: "justify-space-between" },
                  [
                    _c(
                      "g-toggle-icon",
                      {
                        model: {
                          value: _vm.displaySettings,
                          callback: function($$v) {
                            _vm.displaySettings = $$v
                          },
                          expression: "displaySettings"
                        }
                      },
                      [_vm._v("settings")]
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            on: {
                              click: function($event) {
                                return _vm.$emit("input", false)
                              }
                            }
                          },
                          [_vm._v(" Close ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.hasValue,
                              color: "primary"
                            },
                            on: { click: _vm.submit }
                          },
                          [_vm._v(" Submit ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      },
      "g-modal",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("button", {
            staticStyle: { display: "none" },
            attrs: { type: "submit" },
            on: { submit: _vm.submit }
          }),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("category-auto-complete", {
                    on: { submit: _vm.submit },
                    model: {
                      value: _vm.asset.value,
                      callback: function($$v) {
                        _vm.$set(_vm.asset, "value", $$v)
                      },
                      expression: "asset.value"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.displayFields, function(field) {
                return _c(
                  "v-flex",
                  { key: field.value, attrs: { xs12: "" } },
                  [
                    _c("v-textarea", {
                      ref: field.value,
                      refInFor: true,
                      attrs: { rows: "4", "auto-grow": "", label: field.label },
                      on: { keypress: _vm.handleTextAreaKeyPress },
                      scopedSlots: _vm._u(
                        [
                          field.languageCode
                            ? {
                                key: "append-outer",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "32px",
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          staticClass: "flag",
                                          attrs: {
                                            eager: "",
                                            "max-width": "30",
                                            src: _vm.imageForLanguage(
                                              field.languageCode
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.asset[field.value],
                        callback: function($$v) {
                          _vm.$set(_vm.asset, field.value, $$v)
                        },
                        expression: "asset[field.value]"
                      }
                    })
                  ],
                  1
                )
              }),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rows: "4",
                      "auto-grow": "",
                      label: _vm.$t("assets.description")
                    },
                    on: { keypress: _vm.handleTextAreaKeyPress },
                    model: {
                      value: _vm.asset.description,
                      callback: function($$v) {
                        _vm.$set(_vm.asset, "description", $$v)
                      },
                      expression: "asset.description"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.displaySettings,
                      expression: "displaySettings"
                    }
                  ],
                  staticClass: "py-2",
                  attrs: { wrap: "" }
                },
                [
                  _c("g-chip-select", {
                    attrs: {
                      items: _vm.fieldOptions,
                      label: _vm.$t("assets.form.displayed_fields"),
                      "return-object": ""
                    },
                    model: {
                      value: _vm.displayFields,
                      callback: function($$v) {
                        _vm.displayFields = $$v
                      },
                      expression: "displayFields"
                    }
                  }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("assets.form.add_after_submit")
                        },
                        model: {
                          value: _vm.addNewAssetOnSubmit,
                          callback: function($$v) {
                            _vm.addNewAssetOnSubmit = $$v
                          },
                          expression: "addNewAssetOnSubmit"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }