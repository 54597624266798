var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingNewProject || _vm.loading
    ? _c(
        "div",
        {
          staticClass: "pa-4",
          staticStyle: {
            height: "90vh",
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "max-width": "300px",
            margin: "auto"
          }
        },
        [
          _c("v-skeleton-loader", {
            staticClass: "ma-auto",
            attrs: { type: "text", width: "100%" }
          })
        ],
        1
      )
    : _c(
        "v-layout",
        {
          staticClass: "align-content-start px-4",
          attrs: { row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            { staticClass: "pa-4", attrs: { md12: "", lg5: "" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { "min-height": "400px" },
                  attrs: { color: "pa-4" }
                },
                [
                  _vm.project
                    ? _c(
                        "v-form",
                        {
                          ref: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.save($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "pa-2",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "pa-2" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("global.name"),
                                      required: "",
                                      rules: [_vm.$rules.required],
                                      "validate-on-blur": ""
                                    },
                                    model: {
                                      value: _vm.project.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.project, "name", $$v)
                                      },
                                      expression: "project.name"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      label: _vm.$t("assets.description"),
                                      rows: "4"
                                    },
                                    model: {
                                      value: _vm.project.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.project,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "project.description"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("g-edit-image", {
                                staticClass: "ma-2",
                                staticStyle: {
                                  height: "200px",
                                  width: "200px",
                                  "border-radius": "12px",
                                  overflow: "hidden"
                                },
                                model: {
                                  value: _vm.project,
                                  callback: function($$v) {
                                    _vm.project = $$v
                                  },
                                  expression: "project"
                                }
                              }),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "pa-2 capitalize_first_letter",
                                  staticStyle: { "margin-right": "218px" },
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.linkOrganizations,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Organizations"
                                    },
                                    on: {
                                      change: _vm.linkProjectToOrganization
                                    },
                                    model: {
                                      value: _vm.organizationId,
                                      callback: function($$v) {
                                        _vm.organizationId = $$v
                                      },
                                      expression: "organizationId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "pa-2 capitalize_first_letter",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("languages.title")))
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "pa-2", attrs: { xs12: "" } },
                                [
                                  _vm._l(_vm.project.languages, function(
                                    language
                                  ) {
                                    return _c(
                                      "g-language-chip",
                                      {
                                        key: language.code,
                                        staticClass: "ma-2",
                                        attrs: { language: language },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmDeleteLanguage(
                                              language.code
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-1",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v(" close ")]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "tiny-chip",
                                      on: {
                                        click: function($event) {
                                          _vm.addLanguageModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v(" add ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("actions.add")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "pa-2 mt-4",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("tags.title")))
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "pa-2", attrs: { xs12: "" } },
                                [_c("manage-tags")],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "pa-2 mt-4",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("billing.current_plan")
                                          ) +
                                          ": "
                                      ),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPricingText(
                                              _vm.project.organization.plan
                                            )
                                          )
                                        )
                                      ]),
                                      _c("br"),
                                      _vm.isOrganizationAdmin
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "organization",
                                                  params: {
                                                    id:
                                                      _vm.project.organization
                                                        .id
                                                  },
                                                  query: { tab: "billing" }
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "billing.go_to_billing"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.organizationId
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mt-4 w-100",
                                              attrs: {
                                                text: "",
                                                color: "blue",
                                                type: "info"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "billing.should_link_to_org"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-4 mr-2 ml-auto",
                                  attrs: { color: "red", outlined: "" },
                                  on: { click: _vm.askDeleteConfirmation }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("project_settings.delete")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "pa-4", attrs: { md12: "", lg7: "" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { "min-height": "250px" },
                  attrs: { color: "pa-4" }
                },
                [
                  _vm.project.slug
                    ? _c("users", {
                        model: {
                          value: _vm.project,
                          callback: function($$v) {
                            _vm.project = $$v
                          },
                          expression: "project"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "g-modal",
            {
              attrs: { "max-width": "600", title: _vm.$t("languages.add") },
              model: {
                value: _vm.addLanguageModal,
                callback: function($$v) {
                  _vm.addLanguageModal = $$v
                },
                expression: "addLanguageModal"
              }
            },
            [
              _c("select-languages", {
                model: {
                  value: _vm.project.languages,
                  callback: function($$v) {
                    _vm.$set(_vm.project, "languages", $$v)
                  },
                  expression: "project.languages"
                }
              })
            ],
            1
          ),
          _c("new-organization-modal", {
            model: {
              value: _vm.isOpenNewOrgModal,
              callback: function($$v) {
                _vm.isOpenNewOrgModal = $$v
              },
              expression: "isOpenNewOrgModal"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }