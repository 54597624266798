// These models are 'getters' so that we do not create a reference to the original object.
import {ProjectState} from "../../types/customTypes";

const MODELS = Object.freeze({
  get asset() {
    return {
      id: null,
      value: null,
      description: null,
      translations: [],
    } as Record<string, any>;
  },
  get user() {
    return {
      lastOnline: null,
      username: null,
      email: null,
      id: null,
      fullName: null,
      firstName: null,
      lastName: null,
      image: null, // image id
      imageUrl: null,
      projects: [],
      organizations: [],
    } as Record<string, any>;
  },
  get project() {
    return {
      id: null,
      name: null,
      imageUrl: null,
      slug: null,
      description: null,
      plan: 'basic_01',
      languages: [],
      tags: [],
      users: [],
    } as ProjectState;
  },
});

export default MODELS;
