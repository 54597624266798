





































import Vue from 'vue';
import client from "@/graphql/client";
import { timeAgoInWords, toTimeStamp } from '@/utils/datetime';
import { state } from '@/store';
import { MachineTranslationOrderType } from '@/generated/graphql';
import { handleErrors } from '@/utils/notifications';

export default Vue.extend({
  name: 'OrderInfoModal',

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      order: {} as MachineTranslationOrderType,
    };
  },

  async created() {
    const { data, errors } = await client.getMachineTranslationOrder({
      projectSlug: state.project.slug,
      id: this.id
    });
    if (errors.length) {
      return handleErrors(errors)
    }

    this.order = data.project.machineTranslationOrder

  },

  methods: { timeAgoInWords, toTimeStamp },
});
