




















































import Vue from "vue";
import Tag from "@/components/tags/Tag.vue";
import client from "@/graphql/client";
import store from "@/store";
import { handleErrors } from "@/utils/notifications";
import { TagInput } from "@/generated/graphql";
import { getRandomColor } from "@/utils/color";
import events from "@/events";

export default Vue.extend({
  name: 'AddTagModal',

  components: { Tag },

  props: {
    value: { type: Boolean, required: true },
  },

  data() {
    return {
      text: this.$t('tags.text_label') as string,
      color: getRandomColor(),
    }
  },

  computed: {
    tag(): TagInput {
      return {
        text: this.text,
        color: this.color,
        projectId: store.getProjectId(),
      }
    },
  },

  methods: {
    async save() {
      // @ts-ignore
      const isValid: boolean = this.$refs.form.validate()
      if (!isValid) {
        return
      }

      const {
        errors,
        data: { createTag },
      } = await client.createTag({ data: this.tag })
      if (errors.length) {
        handleErrors(errors)
      } else {
        store.addTag(createTag);
        events.tagsChanged.emit();
        this.close();
      }
    },

    close() {
      this.$emit('input', false);
    },
  },
});
