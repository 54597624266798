













































































































import Vue from 'vue';
import { state } from '@/store';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';
import CategoryAutoComplete from '@/components/CategoryAutoComplete.vue';
import MODELS from '../../constants/models';
import {
  handleErrors, showSavedNotification,
} from '@/utils/notifications';
import { createTranslation } from '@/utils/graphqlMethods';
import { getEventIsSubmitForm } from '@/utils/eventShortcuts';
import displayEnterTip from '@/utils/displayEnterTip';
import client from '@/graphql/client';

interface FieldType {
  value: string,
  languageCode: string,
  text: string,
  label: string,
}

export default Vue.extend({
  name: 'AddAssets',

  components: { CategoryAutoComplete },

  mixins: [syncStateWithStorage(['displayFields'])],

  props: {
    value: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      asset: MODELS.asset,
      // Copy from fieldOptions
      displayFields: state.project.languages.filter(e => e.languageCode).map(language => ({
        value: `language.${language.code}`,
        languageCode: language.code,
        text: `${language.code}`,
        label: `${language.code} ${this.$t('translate.translation')}`,
      })),
      addNewAssetOnSubmit: true,
      displaySettings: false,
      displayModal: false,
    };
  },

  computed: {
    filteredDisplayFields(): FieldType[] {
      const fieldValues: string[] = this.displayFields.map(({value}) => value);
      return this.fieldOptions.filter(({value}) => fieldValues.includes(value));
    },

    fieldOptions(): FieldType[] {
      return state.project.languages.map(language => ({
        value: `language.${language.code}`,
        languageCode: language.code,
        text: `${language.code}`,
        label: `${language.code} ${this.$t('translate.translation')}`,
      }));
    },

    hasValue(): boolean {
      return this.displayFields
        .map(({ value }) => this.asset[value])
        .filter(v => !!v)
        .length > 0 || this.asset.value;
    },
  },

  mounted() {
    this.displayModal = true;
  },

  methods: {
    imageForLanguage(languageCode) {
      try {
        return state.project.languages.find(({ code }) => code === languageCode).imageUrl
      } catch (e) {
        return null;
      }
    },

    handleTextAreaKeyPress(event) {
      displayEnterTip();
      if (getEventIsSubmitForm(event)) {
        this.submit()
      }
    },

    async submit() {
      if(!this.hasValue) {
        return;
      }

      const { data, errors } = await client.createAsset({data: {
        projectId: state.project.id,
        value: this.asset.value,
        description: this.asset.description,
      }});

      if (errors.length) {
        handleErrors(errors)
        return
      }

      if (!(data.createAsset && data.createAsset.id)) {
        handleErrors("We couldn't save your new asset. Please make sure the Asset ID is unique within your project!");
        return;
      }

      this.displayFields.forEach(({languageCode, value}) => {
        if (languageCode && this.asset[value]) {
          const formatted = this.asset[value].replace("\n", " ")
          createTranslation(data.createAsset.id, languageCode, formatted);
        }
      });

      showSavedNotification();
      this.setNewAssetToState();

      if (this.addNewAssetOnSubmit) {
      } else {
        this.close();
      }
    },

    close() {
      this.displayModal = false;
      setTimeout(() => {
        this.$emit('input', false);
      }, 250);
    },

    setNewAssetToState() {
      Object.keys(this.asset).forEach((key) => {
        this.asset[key] = MODELS.asset[key];
      });
    }
  },
});
