<template>
  <v-layout row class="px-md-10 px-xs-2 ma-4">
    <v-flex>
      <v-btn class="mt-2" style="display: block; margin-left: auto" color="primary" outlined @click="addUsersModal = true">
        {{ $t("organizations.users.add") }}
        <v-icon class="ml-2">add</v-icon>
      </v-btn>

      <v-flex class="mt-4 scroll-on-mobile" xs12>
        <template>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search user..."
            single-line
            hide-details
            autocomplete="chrome-off"
          />
          <v-data-table
            :headers="tableHeaders"
            :items="users"
            :expanded="expandedItems"
            :items-per-page="10"
            style="min-width: 400px"
            :mobile-breakpoint="1"
            :search="search"
          >
            <template v-slot:item="{item}">
              <tr @click="toggleExpandAsset(item)">
                <td>
                  <v-avatar size="36" class="mr-1 hide-below-1000">
                    <v-img :src="item.imageUrl || avatarPlaceholder" />
                  </v-avatar>

                  <span>
                    {{ item.fullName }}
                  </span>
                </td>

                <td class="hide-below-800">
                  {{ item.email }}
                </td>

                <td>
                  <v-checkbox
                    v-model="item.isOrganizationAdmin"
                    color="primary"
                    @click.stop="confirmUdateOrganizationAdmin(item)"
                  />
                </td>

                <td>
                  <div class="d-flex">
                    <v-btn icon @click.stop="confirmDeleteUser(item)">
                      <v-icon style="font-size: 17px;" color="red" class="mx-auto"> delete </v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon class="mx-auto">
                        {{ isExpanded(item.id) ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{item, headers}">
              <td :colspan="headers.length" class="table-row">
                <manage-organization-user :key="item.id" :user="item" />
              </td>
            </template>
          </v-data-table>
        </template>
      </v-flex>
    </v-flex>
    <add-org-user-modal v-model="addUsersModal" :organization-id="organization.id || ''" />
  </v-layout>
</template>

<script>
import events from "@/events";
import { state } from "@/store";
import { timeAgoInWords, toTimeStamp } from "@/utils/datetime";
import { convertOrganizationUserRoles } from "@/utils/organizations";
import avatarPlaceholder from "@/assets/avatar-placeholder.png";
import { handleErrors, showWarningNotification } from "@/utils/notifications";
import { OrganizationRole } from "@/generated/graphql";
import client from "@/graphql/client";
import AddOrgUserModal from "../AddOrgUserModal";
import ManageOrganizationUser from '../ManageOrganizationUser.vue';

export default {
  components: { AddOrgUserModal, ManageOrganizationUser },

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        {
          text: this.$t("global.name"),
          value: "fullName",
        },
        {
          text: this.$t("users.email"),
          value: "email",
          class: "hide-below-800",
        },
        { text: this.$t("users.organization_admin"), value: "isOrganizationAdmin" },
        {
          text: this.$t("actions.title"),
          value: "delete",
          width: 50,
          align: "end",
          sortable: false,
        },
      ],
      addUsersModal: false,
      expandedItems: [],
      search: '',
      users: [],
      avatarPlaceholder,
    };
  },

  watch: {
    organization: {
      handler: function (val) {
        if (val) {
          this.users = convertOrganizationUserRoles(val)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    timeAgoInWords,
    toTimeStamp,

    toggleExpandAsset(item) {
      if (this.expandedItems.find((v) => v.id === item.id)) {
        this.expandedItems = [];
      } else {
        this.expandedItems = [item];
      }
    },

    confirmUdateOrganizationAdmin(user) {
      const updatingCurrentUser = state.user.id === user.id;
      const isRemovingAsAdmin = user.isOrganizationAdmin;

      if (updatingCurrentUser && isRemovingAsAdmin) {
        showWarningNotification(this.$t("project_settings.remove_admin_self_error"));
        return;
      }
      events.openConfirmDialog.emit({
        callback: () => this.updateOrganizationAdmin(user, isRemovingAsAdmin),
        title: "",
        text: isRemovingAsAdmin
          ? this.$t("organization_settings.confirm_remove_admin")
          : this.$t("organization_settings.confirm_add_admin"),
      });
    },

    async updateOrganizationAdmin(user, isRemovingAsAdmin) {
      const { errors } = await client.updateOrganizationRole({
        data: {
          organizationId: this.organization.id,
          userId: user.id,
          role: isRemovingAsAdmin ? OrganizationRole.Member : OrganizationRole.Admin,
        },
      });
      if (errors.length) {
        return handleErrors(errors);
      }
      // eslint-disable-next-line require-atomic-updates
      user.isOrganizationAdmin = !user.isOrganizationAdmin;
    },

    confirmDeleteUser(user) {
      const userWillDeleteItself = user.id === state.user.id;

      if (userWillDeleteItself) {
        return showWarningNotification(
          this.$t('organizations.users.remove_self_error')
        )
      }

      events.openConfirmDeleteDialog.emit({
        callback: () => this.deleteUser(user),
        title: '',
        text: this.$t('global.delete_confirmation'),
      });
    },

    async deleteUser({ id }) {
      const { errors } = await client.removeOrganizationUser({
        data: {
          userId: id,
          organizationId: this.organization.id
        }
      })
      if (errors.length) {
        return handleErrors(errors)
      }
      this.users = this.users.filter(e => e.id != id)
    },

    isExpanded(id) {
      return this.expandedItems.find(e => e.id == id)
    }
  },
};
</script>
