var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: {
        "max-width": "400",
        value: _vm.value,
        title: _vm.$t("organizations.add_new_organization")
      },
      on: {
        input: function($event) {
          return _vm.close()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 mb-2 ml-auto",
                  attrs: { disabled: !_vm.organizationName, color: "primary" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("global.name") },
            model: {
              value: _vm.organizationName,
              callback: function($$v) {
                _vm.organizationName = $$v
              },
              expression: "organizationName"
            }
          }),
          _c("button", {
            staticStyle: { display: "none" },
            on: { submit: _vm.submitForm }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }