<template>
  <div class="container">
    <v-card class="ma-4">
      <api-keys />
    </v-card>

    <v-card class="ma-4 pa-4">
      <h1 class="title">GraphQL API</h1>
      <p>
        Our API uses <a
          href="https://graphql.org/"
          target="_blank"
        >GraphQL</a>. You can find all docs in our
        <a
          :href="graphiqlLink"
          target="_blank"
        >GraphiQL environment</a>.
      </p>

      <a
        class="not_clear_link"
        @click="openMessageModal"
      >Something not clear? Let us know</a>
    </v-card>

    <v-card class="ma-4 pa-4">
      <h1 class="title">Authentication & Authorization</h1>
      <p class="mt-0 py-4 mb-0">
        There are 2 different methods to authenticate in the API.
        Both are set in the <code>Authorization</code> header.
      </p>

      <v-divider class="divider" />
      <h3>1. API Key</h3>
      <p class="mt-4 mb-1 my-0">
        The API Key always has all permissions for one single project.
      </p>

      <p class="mt-4 mb-1 my-0">
        To Authorize by API Key, simply create an API key, and set the Authorization header in your request to <code>Authorization: Api-Key YOURSECRET</code>
      </p>

      <v-divider class="divider" />
      <h3>2. User Access (JWT)</h3>
      <p class="mt-4 mb-1 my-0">
        When using JWT, you login with a user email + password. Requests with JWT will have same authorization as the user on app.translationhut.com.
      </p>

      <p class="mt-4 mb-1 my-0">
        New to JWT? Learn more <a
          href="https://jwt.io/introduction"
          target="_blank"
        >here</a>.
      </p>

      <p class="mt-4 mb-1 my-0">
        1. To Authorize by JWT, first retrieve the JWT from the API:
      </p>

      <pre class="mb-4"><code class="multiline">mutation {
  tokenAuth(
    username: "your-email@example.com",
    password: "your-password"
  ) {
    token
    refreshToken
  }
}</code></pre>

      <p class="mt-4 mb-1 my-0">
        2. Get the token from the response. A successful response will return:
      </p>

      <pre class="mb-4"><code class="multiline">{
  "data": {
    "tokenAuth": {
      "token": "YOUR-ACCESS-TOKEN",
      "refreshToken": "YOUR-REFRESH-TOKEN"
    }
  }
}</code></pre>

      <p class="mt-4 mb-1 my-0">
        3. Then, set the returned token in the Authorization header in your request:
      </p>
      <pre class="mb-4"><code class="multiline">Authorization: JWT YOUR-ACCESS-TOKEN</code></pre>

      <p class="mt-4 mb-1 my-0">
        4. To refresh the JWT token:
      </p>
      <pre class="mb-4"><code class="multiline">mutation {
  refreshJwt(refreshToken: "YOUR-REFRESH-TOKEN") {
    token
    refreshToken
  }
}
</code></pre>
    </v-card>

    <v-card class="ma-4 pa-4">
      <h1 class="title">Download translation files</h1>

      <p class="mt-0 py-4 mb-0">
        Its simple to integrate translationhut into your CI. Just download the translation files in your build process.
      </p>

      <p class="mt-0 py-4 mb-0">Curl Example:</p>
      <pre class="mb-4"><code class="multiline">curl '{{ $apiUrl }}/export/flat_json/EN-US?&include_empty=true&base_language=EN-US' \
     -H 'Authorization: Api-Key YOURSECRET' --output EN-US.json'</code></pre>

      <p>Export files are directly available with <code>GET</code> requests. Simply...</p>
      <ul>
        <li>Include your authorization header <code>Authorization: Api-Key YOURSECRET</code>, these are always project-bound</li>
        <li>
          Query the url <code>{{ $apiUrl }}/export/po/ES-ES</code>, where <code>ES-ES</code> is the language code and <code>po</code> can be any of the file types:
          <ul>
            <li
              v-for="format in fileFormats"
              :key="format"
            >
              <code>{{ format }}</code>
            </li>
          </ul>
        </li>
        <li>Optionally include the search parameter <code>include_empty=true</code> to include missing translations with an empty value</li>
        <li>Optionally include a base language <code>base_language=NL-NL</code> to use translation values as keys, instead of Asset IDs</li>
        <li>Optionally include a search parameter <code>languages=AF,EN-US</code> to export multiple languages. Leave out the language code in the URL. If you export multiple language files the response will be a zipped file.</li>
      </ul>
    </v-card>
  </div>
</template>

<script>
import ApiKeys from '@/components/develop/ApiKeys';
import store from '@/store';
import events from '@/events';

export default {
  name: "Develop",
  components: { ApiKeys },
  computed: {
    graphiqlLink() {
      return `${this.$graphiqlUrl}#query=mutation%20%7B%0A%20%20login(loginInput%3A%20%7B%20email%3A%20%22YOUREMAIL%22%2C%20password%3A%22YOURPASSWORD%22%20%7D)%20%7B%0A%20%20%20%20ok%0A%20%20%20%20errors%20%7B%0A%20%20%20%20%20%20field%0A%20%20%20%20%20%20messages%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A`
    },

    fileFormats() {
      return store.state.fileFormats.map(({name}) => name.toLowerCase());
    },
  },

  methods: {
    openMessageModal() {
      events.openMessageModal.emit();
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (min-width: 700px) {
  .not_clear_link {
    float: right;
    display: block;
    margin-right: 16px;
  }
}

.not_clear_link {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  margin-top: 24px;
  margin-bottom: 30px;
}

.max-width {
  width: 100%;
}

li {
  margin-top: 3px;
  margin-bottom: 3px;
}

code span {
  display:block;
}
</style>
