




























































import { cloneDeep, omit } from "lodash";
import client from "@/graphql/client";
import { handleErrors, showSuccessNotification } from "@/utils/notifications";
import countries from "@/constants/countries";
import OrganizationAddress from "../OrganizationAddress.vue";
import events from '@/events';

export default {
  name: "OrganizationInfo",
  components: { OrganizationAddress },

  data() {
    return {
      countries,
      organization: null,
      submitting: false,
    };
  },

  computed: {},

  watch: {},

  async created() {
    const { id } = this.$route.params;
    const { data, errors } = await client.organizationDetail({ id });
    if (errors.length) {
      return handleErrors(errors);
    }
    this.organization = cloneDeep(data.organizationDetail);

    const defaultAddress = {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      postalCode: '',
      state: '',
    }
    if (!this.organization.physicalAddress) {
      this.organization.physicalAddress = { ...defaultAddress }
    }
    if (!this.organization.billingAddress) {
      this.organization.billingAddress = { ...defaultAddress }
    }
  },

  methods: {
    async updateOrganization() {
      this.submitting = true;
      const postData = omit(
        this.organization,
        "id",
        "created",
        "hidden",
        "projects",
        "users",
        "plan",
        "trialExpiryDate",
      )

      if (this.organization.billingAddressIsPhysicalAddress) {
        delete postData.billingAddress
      }

      const { data, errors } = await client.updateOrganization({ id: this.organization.id, data: postData })
      this.submitting = false;

      if (errors.length) {
        return handleErrors(errors);
      }

      showSuccessNotification(this.$t('organizations.updated_successfully'), 3000)
      this.organization = cloneDeep(data.updateOrganization);
    },

    confirmRemoveOrganization() {
      events.openConfirmDeleteDialog.emit({
        callback: () => this.removeOrganization(),
        title: 'organizations.delete',
        text: this.$t('global.delete_confirmation'),
      });
    },

    async removeOrganization() {
      this.submitting = true;
      const { errors } = await client.removeOrganization({ id: this.organization.id })
      this.submitting = false;

      if (errors.length) {
        return handleErrors(errors);
      }

      await this.$router.push('/')
    }
  },
};
