var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "mx-0", attrs: { row: "" } },
    _vm._l(_vm.organizations, function(organization) {
      return _c(
        "v-flex",
        { key: organization.id, attrs: { xs12: "", sm6: "", md4: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "ma-2",
              attrs: {
                to: { name: "organization", params: { id: organization.id } }
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("h1", { staticClass: "title mb-2 text-cut" }, [
                    _vm._v(" " + _vm._s(organization.name) + " ")
                  ]),
                  _c(
                    "v-fade-transition",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "mx-0",
                          staticStyle: { "align-content": "start" },
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "flex-shrink": "1" },
                              attrs: { xs12: "", sm6: "", "py-2": "" }
                            },
                            [
                              _c("h3", { staticClass: "subtitle-2" }, [
                                _vm._v(_vm._s(_vm.$t("stats.users")))
                              ]),
                              _c("h3", { staticClass: "title" }, [
                                _vm._v(_vm._s(organization.users.length))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "flex-shrink": "1" },
                              attrs: { xs12: "", sm6: "", "py-2": "" }
                            },
                            [
                              _c("h3", { staticClass: "subtitle-2" }, [
                                _vm._v(_vm._s(_vm.$t("stats.projects")))
                              ]),
                              _c("h3", { staticClass: "title" }, [
                                _vm._v(_vm._s(organization.projects.length))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }