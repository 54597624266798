








































































































import Vue from 'vue'
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { DataOptions } from "vuetify/src/components/VData/VData";

import client from '@/graphql/client';
import CONSTANTS from '@/constants/constants';
import { state } from '@/store';
import displayAddAssetTip from '@/utils/displayAddAssetTip';
import { timeAgoInWords, toTimeStamp } from '@/utils/datetime';
import { handleErrors, showErrorNotification } from '@/utils/notifications';
import { Header } from "../../../types/customTypes";
import TagSelect from "@/components/tags/TagSelect.vue";
import events from "@/events";
import { downloadInCsv } from "@/utils/csv";
import withLoadScreen from "@/utils/withLoadScreen";
import { convertEmptyArrayToUnset } from '@/utils/array';
import { AssetType, LanguageType, MutationUpdateAssetArgs } from '@/generated/graphql';
import { parsePagination } from '@/utils/pagination';

type Asset = AssetType

// extends record because translation values will be put on the map
// like: item.nl = "Dit is een vertaling"
interface Item extends Record<string, any> {
  id: string, // id of asset
  value: string, // value of asset
  translationIds: Record<string, string>, // map language code to translation id
  tags: string[], // tag ids
  created: string, // in words
  edited: string, // in words
}

export default Vue.extend({
  name: 'AssetTable',

  components: {TagSelect},

  props: {
    filters: { type: Object, required: true },
    displayHeaders: { type: Array, required: true },
    editRows: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },

  data() {
    return {
      state,
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      } as DataOptions,
      lastOpenedItemId: false,
      assets: [],
      amountOfItems: 0,
      isLoading: false,
    } as any
  },

  computed: {
    displayCreatedColumn(): boolean {
      return !!this.tableHeaders.find(({value}) => value === CONSTANTS.translateTableHeaders.createdHeader.value);
    },

    tableHeaders(): Array<Header> {
      const tableHeaders = this.headerOptions
        .filter(({value}) => this.displayHeaders.includes(value));

      if (tableHeaders.length === 0) {
        return [CONSTANTS.translateTableHeaders.assetIdHeader]
      }

      return tableHeaders
    },

    headerOptions(): Array<Header> {
      return [
        CONSTANTS.translateTableHeaders.assetIdHeader,
        ...this.state.project.languages.map((language: LanguageType) => ({
          text: language.code,
          value: language.code,
          sortable: false,
          languageCode: language.code,
        } as Header)),
        CONSTANTS.translateTableHeaders.createdHeader,
        CONSTANTS.translateTableHeaders.editedHeader,
        CONSTANTS.translateTableHeaders.archiveHeader,
        CONSTANTS.translateTableHeaders.descriptionHeader,
        CONSTANTS.translateTableHeaders.tagsHeader,
      ];
    },

    tableItems(): Item[] {
      return this.formatTableItems(this.assets)
    },
  },

  watch: {
    filters: {
      handler() {
        this.debouncedSetTranslationAssets();
      },
      deep: true,
    },

    options: {
      handler() {
        this.debouncedSetTranslationAssets();
      },
      deep: true,
    },

    editRows(editRows) {
      if (!editRows) {
        // refetch in case something was edited
        this.setTranslationAssets();
      }
    },
  },

  created() {
    this.setTranslationAssets();
    this.$handleOff(events.fetchAssets.on(this.setTranslationAssets));
    this.$handleOff(events.downloadTable.on(this.downloadTable));
  },

  methods: {
    async unarchive(assetId: string) {
      await this.changeAsset({ id: assetId, data: { archived: false } });
      this.setTranslationAssets();
    },

    async archive(assetId: string) {
      await this.changeAsset({ id: assetId, data: { archived: true } });
      this.setTranslationAssets();
    },

    changeTags(tagIds, item: Item) {
      client.updateAsset({ id: item.id, data: { tags: tagIds } });
      const index = this.assets.findIndex((asset: any) => asset.id === item.id);
      // @ts-ignore
      this.assets[index].tags = tagIds.map(id => ({ id }));
    },

    isTagsHeader(header: Header) {
      return CONSTANTS.translateTableHeaders.tagsHeader.value === header.value;
    },

    isArchiveHeader(header: Header) {
      return CONSTANTS.translateTableHeaders.archiveHeader.value === header.value;
    },

    toggleOpenAsset({ id }: any) {
      const isSelectingText = window.getSelection().toString();
      if (this.editRows || isSelectingText) {
        return;
      }

      this.lastOpenedItemId = id;
      const asset = this.assets.find((a: any) => a.id === id);
      events.openAsset.emit(asset);
    },

    editField(event: any, header: Header, item: any) {
      const { value } = event.target;

      if (value === null || value === undefined) {
        return;
      }

      const isAssetIdField = header.value === CONSTANTS.translateTableHeaders.assetIdHeader.value;
      if (isAssetIdField) {

        // Asset ID may not be empty - don't have in this case
        if (value === "") {
          return;
        }

        const assetId = item.id; // PK value
        this.changeAsset({ id: assetId, data: { value } })
      } else {
        const translationId = item.translationIds[header.value];
        if (translationId) {
          client.updateTranslation({ id: translationId, data: { value } });
        } else {
          (async () => {
            const { data: { createTranslation } } = await client.createTranslation({data: {
              asset: item.id,
              language: header.languageCode || '',
              value,
            }});

            if (createTranslation && createTranslation.id) {
              // eslint-disable-next-line require-atomic-updates
              item.translationIds[header.value] = createTranslation && createTranslation.id;
            }
          })()
        }
      }
    },

    async addNewAsset(event) {
      const { value } = event.target;
      const { errors } = await client.createAsset({ data: { value, projectId: state.project.id } });
      if (errors.length) {
        handleErrors(this.$t("assets.not_unique_error"));
        return;
      }

      this.setTranslationAssets();

      // eslint-disable-next-line require-atomic-updates
      event.target.value = "";
    },

    async changeAsset(input: MutationUpdateAssetArgs) {
      const { errors } = await client.updateAsset(input);
      if (errors.length) {
        handleErrors(errors);
      }
    },

    moveToField(x, y) {
      try {
        const field = this.$refs[`field-${x}-${y}`][0];
        field.focus();
        field.setSelectionRange(0, field.value.length);
        return true;
      } catch (e) {
        return false;
      }
    },

    moveToFirstRef() {
      this.moveToField(0, 0);
    },

    moveToInputBelow(x, y) {
      this.moveToField(x, y + 1);
    },

    moveToNextField(x, y) {
      if(!this.moveToField(x + 1, y)) {
        this.moveToInputBelow(0, y);
      }
    },

    moveToInputAbove(x, y) {
      const nextY = y - 1;

      if (nextY < 0) {
        //@ts-ignore
        this.$refs['new-asset'].focus();
      } else {
        this.moveToField(x, nextY);
      }
    },

    displayAddAssetTip,

    async downloadTable() {
      const MAX_COUNT = 9999
      if (this.amountOfItems > MAX_COUNT) {
        showErrorNotification(this.$t("assets.export_table.too_many_items"));
        return
      }
      const { results } = await this.fetchTranslationAssets({ itemsPerPage: MAX_COUNT })
      const rows = this.formatTableItems(results)
        .map(row => {
          const newRow = omit(row, "archived", "translationIds", "tags", "id", "value")
          return { assetID: row.value, ...newRow }
        })

      await withLoadScreen({
        title: this.$t("assets.download_table.downloading_modal_title"),
        text: this.$t("assets.download_table.downloading_modal_text", { assetCount: this.amountOfItems }),
        persistent: false,
      }, async () => {
        const error = downloadInCsv(rows, "table-export")
        if (error) {
          showErrorNotification(error);
        }
      })
    },

    formatTableItems(assets: Asset[]): Item[] {
      return assets
        // @ts-ignore TODO can we get rid of this?
        .filter(({deleted}) => !deleted)
        .map((asset: AssetType) => {
          const item: Item = {
            id: asset.id,
            description: asset.description,
            archived: asset.archived,
            value: asset.value || '',
            tags: (asset.tags || []).map(tag => (tag && tag.id) || ''),
            translationIds: {},
            created: timeAgoInWords(toTimeStamp(asset.created)),
            edited: timeAgoInWords(toTimeStamp(asset.edited)),
          };

          asset && asset.translations && asset.translations
            .filter(v => !!v)
            .forEach((translation) => {
              // @ts-ignore
              item[translation.language.code] = translation && translation.value;
              // @ts-ignore
              item.translationIds[translation.language.code] = translation.id;
            });

          return item;
        });
    },

    async fetchTranslationAssets (overwriteOptions: Partial<DataOptions> = {}) {
      if (!state.project.id) {
        return;
      }

      this.isLoading = true;
      const {data, errors} = await client.getAssets({
        projectSlug: state.project.slug,
        ...parsePagination({...this.options, ...overwriteOptions}),
        filters: {
          tags: {inList: convertEmptyArrayToUnset(this.filters.includeTags)},
          excludedTags: convertEmptyArrayToUnset(this.filters.excludeTags),
          languages: convertEmptyArrayToUnset(this.filters.translatedInLanguage),
          excludedLanguages: convertEmptyArrayToUnset(this.filters.untranslatedInLanguage),
          search: this.filters.search,
          archived: this.filters.showArchivedOnly,
        }
      })
     
      this.loading = false;

      if (errors.length) {
        handleErrors(errors);
      }

      return data.project.assets
    },

    async setTranslationAssets () {
      try {
        const {results, count} = await this.fetchTranslationAssets();
        this.assets = results || [];
        this.amountOfItems = count || 0;
        this.isLoading = false;
      } catch (e) {
        console.error(e)
      }
    },

    debouncedSetTranslationAssets: debounce(function() {
      //@ts-ignore
      this.setTranslationAssets();
    }, 250),
  },
});
