




































import client from '@/graphql/client';
import { handleErrors } from '@/utils/notifications';
import OrganizationInfo from '@/components/organizations/tabs/OrganizationInfo.vue';
import OrganizationUsers from '@/components/organizations/tabs/OrganizationUsers.vue';
import Billing from '@/components/organizations/tabs/Billing.vue';
import events from '@/events';

export default {
  name: 'OrganizationSettings',

  components: { Billing, OrganizationInfo, OrganizationUsers },

  data() {
    return {
      organization: {},
      tab: 'info',
    }
  },

  async created() {
    const { id } = this.$route.params;
    await this.fetchOrganizationDetail(id)

    const { tab } = this.$route.query;
    if (tab) {
      this.tab = tab
    }
    events.refetchOrganizationDetail.on(this.fetchOrganizationDetail)
  },

  methods: {
    async fetchOrganizationDetail(id) {
      const {data, errors} = await client.organizationDetail({ id })
      if (errors.length) {
        return handleErrors(errors)
      }

      this.organization = data.organizationDetail
    }
  }
};
