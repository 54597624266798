





















import Vue from 'vue';
import Project from '@/components/home/Project.vue';

export default Vue.extend({
  name: 'Projects',

  components: { Project },
  props: {
    projects: { type: Array, required: true },
  },
});
