


























import ProjectRolesRow from './ProjectRolesRow.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { ProjectRolesRow },

  props: {
    user: { type: Object , required: true },
  },

  data() {
    return {
      headers: ["Projects", "Admin", "Manager", "Developer", "Translator", "No role"],
      roles: [],
    };
  },

  computed: {
    isNoProject() {
      // Because we have the first role for all roles selection
      return this.roles.length <= 1;
    }
  },

  watch: {
    user: {
      handler(val) {
        if (val) {
          const _roles = cloneDeep(this.user.roles);
          const accessProjectIds = _roles.map(e => e.project.id)
          this.user.organizationProjects.forEach((project) => {
            if (!accessProjectIds.includes(project.id)) {
              _roles.push({
                id: this.$uuid.v4(),
                project: project,
                roles: [],
              })
            }
          })
          _roles.unshift({
            id: 'ALL',
            project: { name: this.$t('organizations.roles.set_for_all_projects') },
            roles: [],
          })
          this.roles = _roles
        }
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    handleChangeAllUsersRole(payload) {
      if (payload.noRole) {
        this.roles.forEach((e) => {
          e.roles = []
        })
        return
      }

      if (payload.isAdding) {
        this.roles.forEach((e) => {
          e.roles.push(payload.role)
        })
        return
      }

      this.roles.forEach((e) => {
        e.roles = e.roles.filter(e => e !== payload.role)
      })
    },

    handleChangeSingleUserRole(payload) {
      if (payload.noRole) {
        this.roles.forEach((e) => {
          if (e.id == payload.id) {
            e.roles = []
          }
        })
        return
      }

      if (payload.isAdding) {
        this.roles.forEach((e) => {
          if (e.id == payload.id) {
            e.roles.push(payload.role)
          }
        })
        return
      }

      this.roles.forEach((e) => {
        if (e.id == payload.id) {
          e.roles = e.roles.filter(e => e !== payload.role)
        }
      })
    }
  }
};
