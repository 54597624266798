/* eslint-disable */
<template>
  <div class="form-wrap">
    <form class="login">
      <p class="login-register">
        {{ $t("view_meta.do_you_have_an_account") }}
        <router-link class="router-link" :to="{name: 'login'}">
          {{ $t("authentication.login") }}
        </router-link>
      </p>
      <h2>{{ $t("view_meta.register_for_free_account") }}</h2>
      <div class="inputs">
        <div class="input">
          <input v-model.trim="email" type="text" :placeholder="$t('users.email')">
          <v-img :src="emailSVG" class="icon" />
        </div>
        <div class="input">
          <input
            v-model="password"
            type="text"
            :placeholder="$t('authentication.login_form.password')"
          >
          <v-img :src="passwordSVG" class="icon" />
        </div>
      </div>
      <button @click="submit()">{{ $t("authentication.create_account") }}</button>
      <div class="angle" />
    </form>
    <div class="background" />
  </div>
</template>

<script>
import emailSVG from "@/assets/Icons/envelope-regular.svg";
import passwordSVG from "@/assets/Icons/lock-alt-solid.svg";
import { handleErrors } from "@/utils/notifications";
import events from "@/events";
import { login, logout } from "@/utils/auth";
import vueI18n from "@/plugins/i18n";
import client from "@/graphql/client";

export default {
  name: "Register",
  metaInfo: {
    get title() {
      return vueI18n.t("view_meta.create_account.title");
    }
  },

  props: {
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      failedCreateAccount: false,
      email: null,
      password: null,
      emailSVG,
      passwordSVG,
      errors: []
    };
  },

  methods: {
    logout,

    async submit() {
      if (await this.$refs.form.validate()) {
        events.showLoader.emit(true);
        const { data, errors } = await client.createAccount({
          data: {
            email: this.email,
            password: this.password
          }
        });
        events.showLoader.emit(false);
        if (errors.length) {
          handleErrors(errors);
          return;
        }

        await login(this.email, this.password);
        await this.$router.push("/");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/sass/login";
</style>
