
































































































































































































import Vue from 'vue';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import store, { state } from '@/store';
import getIds from '../utils/getIds';
import SelectLanguages from '@/components/SelectLanguages.vue';
import Users from '@/components/projectsettings/Users.vue';
import ManageTags from '@/components/projectsettings/ManageTags.vue';
import client from '@/graphql/client';
import {
  handleErrors,
  showWarningNotification
} from '@/utils/notifications';
import { GQLProjectType } from "../../types";
import events from "@/events";
import { UpdateProjectInput } from "@/generated/graphql";
import { getPricingText } from "@/utils/plans";
import { get, map, uniqBy } from 'lodash';
import NewOrganizationModal from '@/components/organizations/NewOrganizationModal.vue';

export default Vue.extend({
  name: 'ProjectSettings',

  components: { SelectLanguages, Users, ManageTags, NewOrganizationModal },

  data() {
    return {
      state,
      project: cloneDeep(state.project) as GQLProjectType,
      addLanguageModal: false,
      loading: false,
      cancelSaveHandle: () => {},
      organizationId: !get(state.project, "organization.hidden") && get(state.project, "organization.id"),
      isOpenNewOrgModal: false,
    };
  },

  computed: {
    isLoadingNewProject(): boolean {
      //@ts-ignore
      return this.$route.params.projectSlug === "new-project" || !this.project.id
    },

    isOrganizationAdmin() {
      if (!this.organizationId) { return false }

      return store.isOrganizationAdmin(this.organizationId)
    },

    linkOrganizations() {
      const newOrgShortcut = {
        id: -1,
        name: `+ ${this.$t('organizations.add_new_organization')}`
      }
      const isHiddenCurrentOrganization = get(state.project, "organization").hidden
      if (isHiddenCurrentOrganization) {
        return [newOrgShortcut, ...store.getMyOrganizations()]
      }
      return uniqBy([newOrgShortcut, get(state.project, "organization"), ...store.getMyOrganizations()], 'id')
    }
  },

  watch: {
    project: {
      deep: true,
      handler() {
        if (this.cancelSaveHandle) {
          this.cancelSaveHandle();
        }
        //@ts-ignore
        this.cancelSaveHandle = this.debouncedSave();
      },
    },
  },

  methods: {
    //@ts-ignore
    debouncedSave: debounce(function save() { this.save(); }, 1000),

    getPricingText,

    async save() {
      const currentSlug = this.$route.params.projectSlug;
      if (!store.getUserIsAdmin(currentSlug)) { return; }
      if (!this.project.name) { return; }

      const input = omit(
        this.project,
        ['id', 'imageUrl', 'trailExpiryDate', 'tags', 'image', 'organization']
      ) as UpdateProjectInput;
      if (this.project.users) { input.users = getIds(this.project.users as any); }
      if (this.project.languages) { input.languages = map(this.project.languages, 'code'); }

      if (this.project.image) {
        input.imageId = { set: this.project.image }
      }

      const { data: { updateProject }, errors } = await client.updateProject({ id: this.project.id, data: input });
      if (errors.length) {
        handleErrors(errors);
        return;
      } else {
        store.setProject(this.project);
      }

      // Handle updated slug
      const newSlug = updateProject && updateProject.slug;
      if (newSlug && currentSlug !== newSlug) {
        store.updateProjectSlug(currentSlug, newSlug);
        await this.$router.replace({
          params: { projectSlug: newSlug },
        });
      }
    },

    confirmDeleteLanguage(languageCode) {
      events.openConfirmDeleteDialog.emit({ callback: () => this.deleteLanguage(languageCode) });
    },

    deleteLanguage(languageCode) {
      if (!this.project.languages) { return; }
      if (this.project.languages.length < 2) {
        showWarningNotification(this.$t('project_settings.one_language_required'));
      } else {
        const index = this.project.languages.findIndex((v: any) => v.code === languageCode);
        this.project.languages.splice(index, 1);
      }
    },

    askDeleteConfirmation() {
      events.openConfirmDeleteDialog.emit({ callback: () => this.delete() });
    },

    async delete() {
      this.project.isDeleted = true;
      await this.save();
      events.refetchState.emit();
      await this.$router.push({ name: 'home' });
    },

    async linkProjectToOrganization() {
      if (this.organizationId === -1) {
        this.isOpenNewOrgModal = true
        this.organizationId = null
        return
      }

      if (!this.isOrganizationAdmin) {
        return
      }

      const { errors } = await client.linkProjectToOrganization({
        organizationId: this.organizationId,
        projectId: this.project.id,
      })
      if (errors.length) {
        return handleErrors(errors)
      }
      this.project.organization = store.getMyOrganizations().find(e => e.id == this.organizationId)
      store.setProject(this.project)
    },
  },
});
