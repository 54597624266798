























import Vue from 'vue';
import client from '@/graphql/client';
import store from '@/store';
import getCategoriesFromStrings from '@/utils/getCategoriesFromStrings';
import { getEventIsSubmitForm } from "@/utils/eventShortcuts";

export default Vue.extend({
  name: 'CategoryAutoComplete',

  props: {
    value: { type: String, required: false, default: '' }
  },

  data() {
    return {
      categories: [] as string[],
      search: '' as string,
    }
  },

  computed: {
    items(): string[] {
      if (!this.search) {
        return this.categories;
      }
      const s = this.search.toLowerCase();
      return this.categories
        .map(v => v.toLocaleLowerCase())
        .filter(v => v.includes(s))
        .filter(v => v !== s)
    },
  },

  created() {
    // this.setCategories();
  },

  methods: {
    getCategoryLevel(item: string): number {
      return item.split('.').length - 1;
    },

    getLastCategoryText(item: string): string {
      const categoryLevel  = this.getCategoryLevel(item);
      return item.split('.')[categoryLevel];
    },

    getFirstCategoriesText(item: string) {
      return item.replace(this.getLastCategoryText(item), "");
    },

    handleInput(event) {
      this.$emit('input', event.target.value);
    },

    handleKeyPress(event) {
      if (getEventIsSubmitForm(event)) {
        this.$emit('submit', event);
      }
    },

    async setCategories() {
      const { data } = await client.getAssets({ projectSlug: store.getProject().slug });
      if (data) {
        const stringAssets = data.project.assets.results.map((e) => e.value)
        this.categories = getCategoriesFromStrings(stringAssets);
      }
    },
  },
});
