import Vue from 'vue';
import Router from 'vue-router';

import get from 'lodash/get';

import store from '@/store';

import Home from '@/views/Home.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import CreateAccount from '@/views/CreateAccount.vue';
import ProjectWrapper from '@/views/ProjectWrapper.vue';
import MachineTranslations from '@/views/MachineTranslations.vue';
import ProjectSettings from '@/views/ProjectSettings.vue';
import OrganizationSettings from '@/views/OrganizationSettings.vue';
import Translate from '@/views/Translate.vue';
import Export from '@/views/Export.vue';
import Develop from '@/views/Develop.vue';
import Import from '@/views/Import.vue';
import TranslationOverview from '@/views/TranslationOverview.vue';
import getRoutePermissions from './utils/getRoutePermissions';
import Empty from '@/views/Empty';
import AcceptInvite from '@/views/AcceptInvite';
import vueI18n from '@/plugins/i18n';
import events from '@/events';
import Logout from '@/views/Logout';
import Login from '@/views/Login';
import Register from '@/views/Register';
import ForgotPassword from '@/views/ForgotPassword'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'project-wrapper',
      path: '/project/:projectSlug',
      component: ProjectWrapper,
      children: [
        {
          path: 'project-settings',
          name: 'project-settings',
          component: ProjectSettings,
          get title() { return vueI18n.t('navigation.settings') },
          icon: 'settings',
          meta: { roles: ['admin'] },
        },
        {
          path: 'translate',
          name: 'translate',
          component: Translate,
          get title() { return vueI18n.t('navigation.translate') },
          icon: 'translate',
          meta: { roles: ['admin', 'translator', 'manager'] },
        },
        {
          path: 'translation-overview',
          name: 'translation-overview',
          component: TranslationOverview,
          get title() { return vueI18n.t('navigation.overview') },
          icon: 'list',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'auto-translate',
          name: 'auto-translate',
          component: MachineTranslations,
          get title() { return vueI18n.t('navigation.auto_translate') },
          icon: 'mdi-robot',
          meta: { roles: ['admin', 'manager'] },
        },
        {
          path: 'develop',
          name: 'develop',
          component: Develop,
          get title() { return vueI18n.t('navigation.develop') },
          icon: 'code',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'import',
          name: 'import',
          component: Import,
          get title() { return vueI18n.t('navigation.import') },
          icon: 'publish',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
        {
          path: 'export',
          name: 'export',
          component: Export,
          get title() { return vueI18n.t('navigation.export') },
          icon: 'save_alt',
          meta: { roles: ['admin', 'developer', 'manager'] },
        },
      ],
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      get title() { return vueI18n.t('navigation.home') },
      icon: 'home',
      meta: { isPublicRoute: true },
    },
    {
      path: '/reset-password',
      name: "reset-password",
      component: ResetPassword,
      meta: { isPublicRoute: true },
    },
    {
      path: '/accept-invite',
      name: "accept-invite",
      component: AcceptInvite,
      meta: { isPublicRoute: true },
    },
    {
      path: '/organization/:id',
      name: "organization",
      component: OrganizationSettings,
    },
    {
      path: '/create-account',
      name: "create-account",
      component: CreateAccount,
      meta: { isPublicRoute: true },
    },
    {
      path: '/login',
      name: "login",
      component: Login,
      meta: { isPublicRoute: true },
    },
    {
      path: '/register',
      name: "register",
      component: Register,
      meta: { isPublicRoute: true },
    },
    {
      path: '/forgot-password',
      name: "forgot-password",
      component: ForgotPassword,
      meta: { isPublicRoute: true },
    },
    {
      path: '/logout',
      name: "logout",
      component: Logout,
      meta: { isPublicRoute: true },
    },
    {
      path: '*',
      name: "404",
      component: Empty,
      meta: { isPublicRoute: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const notAuthenticated = () => {
    if (to.name === 'login') {
      next()
    } else {
      next({ name: 'login' })
    }
  };

  const notAuthorized = () => {
    if (to.name === 'home') {
      next()
    } else {
      next({ name: 'home' })
    }
  };

  const checkRouteAndGoNext = () => {
    const isLoggedIn = store.state.loggedIn
    const isPublicRoute = !!get(to, 'meta.isPublicRoute');

    if (isPublicRoute) {
      return next()
    }

    if (!isLoggedIn) {
      return notAuthenticated()
    }

    const projectSlug = get(to, 'params.projectSlug');
    if (!projectSlug) {
      return notAuthorized();
    }

    return getRoutePermissions(to, projectSlug)
      ? next()
      : notAuthorized();
  };

  if (store.state.loggedIn !== null) {
    checkRouteAndGoNext();
  } else {
    events.authenticated.once(checkRouteAndGoNext);
    events.unauthenticated.once(checkRouteAndGoNext);
  }
});

export default router;
