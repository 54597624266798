import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import MODELS from '@/constants/models';
import languages from "@/constants/languages"
import { captureException } from './utils/debugging';
import { getDarkMode } from '@/utils/darkMode';
import { GQLLanguageType, GQLProjectType, GQLUserRoleType } from "../types";
import chatclient from "@/utils/chatclient";
import client from "@/graphql/client";
import {getISO639Locale, setLocaleToHtml, setLocaleToStorage} from "@/utils/language";
import vueI18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import analytics from "@/plugins/analytics";
import { ProjectDetailFieldsFragment, ProjectFieldsFragment, TagFieldsFragment, UserType } from "./generated/graphql";
import { map } from "lodash";

const store = {
  state: {
    get isAdmin(): boolean {
      return store.getUserRolesForCurrentRoute().includes("admin")
    },

    loggedIn: null,

    showChangePassword: false,

    isMobile: false,

    darkMode: getDarkMode(),

    // All available languages
    languages: languages as GQLLanguageType[],

    // All available file formats (for export and soon import)
    fileFormats: [],

    // Currently selected project
    project: MODELS.project as ProjectFieldsFragment,

    // Current user
    user: MODELS.user,

    // Roles of current user for current project
    rolesForProject: [],
  } as Record<string, any>,

  initialState: {} as Record<string, any>,

  addTag(tag: TagFieldsFragment) {
    return this.state.project.tags.push(tag);
  },

  setTagOptions(tags: TagFieldsFragment[]) {
    this.state.project.tags = tags;
  },

  setLocale(locale: string) {
    if (!locale) {
      captureException(new Error(`locale is required, found ${locale}`));
      return;
    }

    if (!vueI18n.availableLocales.includes(locale)) {
      captureException(new Error(`locale must be one of ${vueI18n.availableLocales}, found ${locale}`));
      return;
    }

    const ISO639Locale = getISO639Locale(locale);
    vueI18n.locale = locale;
    vuetify.framework.lang.current = ISO639Locale;
    chatclient.setup(locale);

    if (locale !== state.user.locale) {
      client.updateUser({ data: { locale } });
    }
    
    setLocaleToStorage(locale);
    setLocaleToHtml(locale);
  },

  getTagOptions(): TagFieldsFragment[] {
    return this.state.project.tags;
  },

  getProject(): GQLProjectType {
    return <GQLProjectType>this.state.project;
  },

  getUser() {
    return this.state.user;
  },

  getMyProjects() {
    return this.state.user.myProjects;
  },

  getMyOrganizations() {
    return this.state.user.myOrganizations;
  },

  getProjectId() {
    return this.getProject().id;
  },

  getAllLanguages(): GQLLanguageType[] {
    return this.state.languages;
  },

  getLanguageByCode(languageCode: string): GQLLanguageType {
    return store.getAllLanguages()
      .find((language: GQLLanguageType) => language.code === languageCode) || {} as GQLLanguageType
  },

  getLanguage(languageId: string): GQLLanguageType {
    return this.getAllLanguages()
      .find((language: GQLLanguageType) => language.id === languageId) || {} as GQLLanguageType
  },

  openChangePasswordModal() {
    this.state.showChangePassword = true;
  },

  closeChangePasswordModal() {
    this.state.showChangePassword = false;
  },

  resetState(key: string) {
    this.state[key] = this.initialState[key];
  },

  addProject(project: ProjectDetailFieldsFragment) {
    this.state.user.myProjects.push({
      name: project.name,
      imageUrl: project.imageUrl,
      slug: project.slug,
      id: project.id,
    });
    this.state.user.roles.push({ roles: ['admin'], project, });
    this.setProject(project);
  },

  setProject(project: ProjectDetailFieldsFragment | null) {
    if (!project || !project.slug) {
      captureException(new Error(`store.setProject' was called without 'project.slug'. Found ${JSON.stringify(project)}`));
      return;
    }
    analytics.setProject(project);
    this.state.project = project;
  },

  isOrganizationAdmin(organizationId) {
    const organization = this.getMyOrganizations().find(e => e.id === organizationId)
    if (!organization) {
      return false
    }
    return map(organization.users, 'id').includes(this.getUser().id)
  },

  login(user: UserType) {
    this.state.user = user;
    this.state.loggedIn = true;
    this.state.showChangePassword = !this.state.user.isPasswordSet;
    this.setLocale(user.locale || 'en-GB');
    analytics.setUser(user);
  },

  logout() {
    this.resetState('user');
    this.resetState('project');
    this.resetState('rolesForProject');
    this.state.loggedIn = false;
  },

  getUserRolesForCurrentRoute() {
    return this.getUserRoles(this.state.project.slug);
  },

  getUserRoles(projectSlug: string) {
    if (!this.state.user.roles) {
      return []
    }

    return this.state.user.roles
      .filter((rolesPerProject: GQLUserRoleType) => rolesPerProject.project && rolesPerProject.project.slug === projectSlug)
      .flatMap((rolesPerProject: GQLUserRoleType) => rolesPerProject.roles);
  },

  updateProjectSlug(previousSlug: string, newSlug: string) {
    // update project role
    {
      const index = this.state.user.roles.findIndex((roles: GQLUserRoleType) => roles.project && roles.project.slug === previousSlug);
      this.state.user.roles[index].project.slug = newSlug;
    }
    // update project
    const index = this.state.user.myProjects.findIndex((project: any) => project.slug === previousSlug);
    this.state.user.projects[index].slug = newSlug;
  },

  getUserIsAdmin(projectSlug: string) {
    return this.getUserRoles(projectSlug).includes('admin');
  },

  setWindowWidth() {
    this.state.isMobile = window.innerWidth < 900;
  },
};

store.initialState = cloneDeep(store.state);
store.setWindowWidth();
window.addEventListener('resize', debounce(() => store.setWindowWidth()));

export default store;
export const { state } = store;
