var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "100%", "overflow-x": "auto" } },
    [
      _vm.isNoProject
        ? _c("div", { staticClass: "text-center" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("organizations.no_project_available")) + " "
            )
          ])
        : _c(
            "table",
            { staticClass: "my-4 py-4 role-table v-data-table" },
            [
              _c(
                "tr",
                _vm._l(_vm.headers, function(header) {
                  return _c(
                    "th",
                    {
                      key: header + "th",
                      staticClass: "narrow_col",
                      staticStyle: { width: "80px" }
                    },
                    [_vm._v(" " + _vm._s(header) + " ")]
                  )
                }),
                0
              ),
              _vm._l(_vm.roles, function(projectRole) {
                return [
                  _c("project-roles-row", {
                    key: projectRole.project.id,
                    attrs: { user: _vm.user, "project-role": projectRole },
                    on: {
                      changeAllUsersRole: _vm.handleChangeAllUsersRole,
                      changeSingleUserRole: _vm.handleChangeSingleUserRole
                    }
                  })
                ]
              })
            ],
            2
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }