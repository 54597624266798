/* eslint-disable */
<template>
  <div class="form-wrap">
    <form class="login">
      <p class="login-register">
        {{ $t('view_meta.remember_password.title') }}
        <router-link class="router-link" :to="{name: 'login'}">{{ $t('authentication.login') }}</router-link>
      </p>
      <h2>{{ $t('authentication.reset_password') }}</h2>
      <div class="inputs">
        <div class="input">
          <input v-model="email" type="text" placeholder="Email">
          <v-img :src="emailSVG" class="icon" />
        </div>
      </div>
      <button>{{ $t('authentication.reset_password') }}</button>
      <div class="angle" />
    </form>
    <div class="background" />
  </div>
</template>

<script>
import emailSVG from '@/assets/Icons/envelope-regular.svg'

export default {
  name:"ForgotPassword",


  data() {
    return {
      email:"",
      password:"",
      emailSVG ,
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/sass/login'
</style>
