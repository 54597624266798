import cloneDeep from 'lodash/cloneDeep';

export function findIndex<T>(inputArray: T[], val): number {
  return inputArray.findIndex(v => JSON.stringify(v) === JSON.stringify(val));
}

export function exists<T>(inputArray: T[], val): boolean {
  return findIndex(inputArray, val) !== -1;
}

export function toggle<T>(inputArray: T[], val): T[] {
  const arr = cloneDeep(inputArray);
  const index = findIndex(inputArray, val);
  if (index === -1) {
    arr.push(val);
  } else {
    arr.splice(index, 1);
  }
  return arr;
}

// @ts-ignore
export function addIfNotExists<T>(inputArray: T[], val): T[] {
  if (exists(inputArray, val)) {
    return inputArray;
  }

  const arr = cloneDeep(inputArray);
  arr.push(val);
  return arr;
}

export function removeIfExists<T>(inputArray: T[], val): T[] {
  const index = findIndex(inputArray, val);
  if (index === -1) {
    return inputArray;
  }

  const arr = cloneDeep(inputArray);
  arr.splice(index, 1);
  return arr
}

export const convertEmptyArrayToUnset = (data: any[]) => {
  if (!data.length) {
    return undefined
  }
  return data
}
