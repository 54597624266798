<template>
  <v-tooltip
    top
    :disabled="!tooltipText"
  >
    <template v-slot:activator="{on}">
      <v-btn
        icon
        v-bind="$attrs"
        :class="{'pressed': value}"
        class="toggle-icon mx-1"
        v-on="on"
        @click="$emit('input', !value)"
      >
        <v-icon>
          <slot />
        </v-icon>
      </v-btn>
    </template>

    <span v-if="tooltipText">{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'GToggleIcon',

  props: {
    value: Boolean,
    tooltipText: String,
  },
};
</script>

<style scoped lang="scss">
  .toggle-icon:not(.pressed) {
    opacity: .6;
  }

  .pressed {
    background-color: rgba(0, 0, 0, 0.11);
  }
</style>
