import { OrganizationType } from "@/generated/graphql";

/**
 * Convert organization users data for UserRoles table in Organization page
 */
export const convertOrganizationUserRoles = (organization: OrganizationType) => {
	const organizationProjectIds = (organization.projects || []).map((project) => project.id)
	return (organization.users || []).map((user) => ({
		...user,
		organizationId: organization.id,
		organizationProjects: organization.projects,
		// Only get user roles in projects that belong to organization
		// because the projects in user role are containing all project that user has role.
		roles: user.roles.filter((e) => organizationProjectIds.includes(e.project.id))
	}))
};
