var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pb-4",
      staticStyle: { "max-width": "1000px", margin: "auto" }
    },
    [
      _c("div", { ref: "top" }),
      _c("pricing", {
        attrs: { value: _vm.organization.plan },
        on: { input: _vm.setPlan }
      }),
      _c(
        "div",
        { staticClass: "px-8 mb-4" },
        [
          _vm.exceededPlan
            ? _c("v-alert", { attrs: { color: "error" } }, [
                _vm._v(" " + _vm._s(_vm.$t("billing.plan_exceeded")) + " ")
              ])
            : _vm._e(),
          _c("h5", { staticClass: "title pt-4 pb-4" }, [
            _vm._v(_vm._s(_vm.$t("billing.info")))
          ]),
          _c("h5", { staticClass: "subtitle-1 mt-4 text--secondary" }, [
            _vm._v(_vm._s(_vm.$t("billing.current_credit_card")) + ":")
          ]),
          !_vm.creditCard
            ? _c("p", [_vm._v(_vm._s(_vm.$t("billing.no_credit_card")))])
            : _c("div", { staticClass: "px-4 mb-4 py-2 mt-2" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.creditCard.holderName) +
                      ", " +
                      _vm._s(_vm.creditCard.typeName) +
                      " "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("billing.credit_card_expires_at")) +
                      ": "
                  ),
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.creditCard.month < 10 ? "0" : "") +
                        _vm._s(_vm.creditCard.month) +
                        "/" +
                        _vm._s(
                          ("" + _vm.creditCard.year).substr(
                            ("" + _vm.creditCard.year).length - 2
                          )
                        )
                    )
                  ]),
                  _vm._v(
                    ", " +
                      _vm._s(_vm.$t("billing.credit_card_number_ends_with")) +
                      ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.creditCard.lastDigits))])
                ])
              ]),
          _c("v-divider", { staticClass: "my-4" }),
          _c("h5", { staticClass: "title mt-4 py-4" }, [
            _vm._v(_vm._s(_vm.$t("billing.usage")))
          ]),
          _c("p", { staticClass: "mb-1" }, [
            _c("span", { staticClass: "text--secondary" }, [
              _vm._v(_vm._s(_vm.$t("billing.translations_left")) + ":")
            ]),
            _vm._v(" " + _vm._s(Math.max(_vm.translationsLeft, 0)) + " "),
            _c("span", { staticClass: "text--secondary" }, [
              _vm._v("/ " + _vm._s(_vm.currentPlanObj.translations))
            ])
          ]),
          _c("p", { staticClass: "mb-1" }, [
            _c("span", { staticClass: "text--secondary" }, [
              _vm._v(_vm._s(_vm.$t("billing.users_left")) + ":")
            ]),
            _vm._v(" " + _vm._s(Math.max(_vm.usersLeft, 0)) + " "),
            _c("span", { staticClass: "text--secondary" }, [
              _vm._v("/ " + _vm._s(_vm.currentPlanObj.users))
            ])
          ]),
          _vm.organization.plan !== _vm.defaultPlan
            ? _c("p", [
                _c("span", { staticClass: "text--secondary" }, [
                  _vm._v(_vm._s(_vm.$t("billing.trail_expires_at")) + ":")
                ]),
                _vm._v(" " + _vm._s(_vm.organization.trialExpiryDate) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }