var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "px-md-10 px-xs-2 ma-4", attrs: { row: "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-2",
              staticStyle: { display: "block", "margin-left": "auto" },
              attrs: { color: "primary", outlined: "" },
              on: {
                click: function($event) {
                  _vm.addUsersModal = true
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("organizations.users.add")) + " "),
              _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "mt-4 scroll-on-mobile", attrs: { xs12: "" } },
            [
              [
                _c("v-text-field", {
                  attrs: {
                    "append-icon": "mdi-magnify",
                    label: "Search user...",
                    "single-line": "",
                    "hide-details": "",
                    autocomplete: "chrome-off"
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _c("v-data-table", {
                  staticStyle: { "min-width": "400px" },
                  attrs: {
                    headers: _vm.tableHeaders,
                    items: _vm.users,
                    expanded: _vm.expandedItems,
                    "items-per-page": 10,
                    "mobile-breakpoint": 1,
                    search: _vm.search
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "tr",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toggleExpandAsset(item)
                                }
                              }
                            },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "mr-1 hide-below-1000",
                                      attrs: { size: "36" }
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src:
                                            item.imageUrl ||
                                            _vm.avatarPlaceholder
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.fullName) + " ")
                                  ])
                                ],
                                1
                              ),
                              _c("td", { staticClass: "hide-below-800" }, [
                                _vm._v(" " + _vm._s(item.email) + " ")
                              ]),
                              _c(
                                "td",
                                [
                                  _c("v-checkbox", {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmUdateOrganizationAdmin(
                                          item
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.isOrganizationAdmin,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "isOrganizationAdmin",
                                          $$v
                                        )
                                      },
                                      expression: "item.isOrganizationAdmin"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.confirmDeleteUser(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mx-auto",
                                            staticStyle: {
                                              "font-size": "17px"
                                            },
                                            attrs: { color: "red" }
                                          },
                                          [_vm._v(" delete ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { icon: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mx-auto" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.isExpanded(item.id)
                                                    ? "mdi-chevron-up"
                                                    : "mdi-chevron-down"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "expanded-item",
                      fn: function(ref) {
                        var item = ref.item
                        var headers = ref.headers
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "table-row",
                              attrs: { colspan: headers.length }
                            },
                            [
                              _c("manage-organization-user", {
                                key: item.id,
                                attrs: { user: item }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ]
            ],
            2
          )
        ],
        1
      ),
      _c("add-org-user-modal", {
        attrs: { "organization-id": _vm.organization.id || "" },
        model: {
          value: _vm.addUsersModal,
          callback: function($$v) {
            _vm.addUsersModal = $$v
          },
          expression: "addUsersModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }