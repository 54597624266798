var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-modal",
    {
      attrs: { "max-width": "600" },
      on: { input: _vm.hideModal },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _vm.secret
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$emit("input", false)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("actions.close")) + " ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("actions.add")) + " ")]
                      )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            height: "360",
            "hide-delimiter-background": "",
            "hide-delimiters": "",
            touchless: "",
            "show-arrows": false,
            value: _vm.secret ? 2 : 1
          }
        },
        [
          _c(
            "v-carousel-item",
            { attrs: { value: 1 } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("api_keys.create")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        staticClass: "mt-4",
                        staticStyle: { "max-width": "350px" },
                        attrs: {
                          dark: _vm.$vuetify.theme.isDark,
                          light: !_vm.$vuetify.theme.isDark,
                          label: _vm.$t("global.name")
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      }),
                      _c("g-chip-select", {
                        attrs: {
                          label: _vm.$t("project_settings.users.roles"),
                          items: _vm.items.map(function(i) {
                            return { value: i, text: i }
                          })
                        },
                        model: {
                          value: _vm.formData.roles,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "roles", $$v)
                          },
                          expression: "formData.roles"
                        }
                      }),
                      _c(
                        "v-dialog",
                        {
                          ref: "dialog",
                          attrs: {
                            "return-value": _vm.expiryDate,
                            width: "290px"
                          },
                          on: {
                            "update:returnValue": function($event) {
                              _vm.expiryDate = $event
                            },
                            "update:return-value": function($event) {
                              _vm.expiryDate = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: { "max-width": "350px" },
                                          attrs: {
                                            label: "api_keys.expiry_date",
                                            "append-icon": "mdi-calendar",
                                            readonly: "",
                                            dark: _vm.$vuetify.theme.isDark,
                                            light: !_vm.$vuetify.theme.isDark
                                          },
                                          model: {
                                            value: _vm.expiryDate,
                                            callback: function($$v) {
                                              _vm.expiryDate = $$v
                                            },
                                            expression: "expiryDate"
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.dateModal,
                            callback: function($$v) {
                              _vm.dateModal = $$v
                            },
                            expression: "dateModal"
                          }
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: { scrollable: "" },
                              model: {
                                value: _vm.expiryDate,
                                callback: function($$v) {
                                  _vm.expiryDate = $$v
                                },
                                expression: "expiryDate"
                              }
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dateModal = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("actions.cancel")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.dialog.save(
                                        _vm.expiryDate
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("actions.save")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("button", {
                    staticStyle: { display: "none" },
                    attrs: { type: "submit" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-carousel-item",
            { attrs: { value: 2 } },
            [_c("display-secret", { attrs: { secret: _vm.secret } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }