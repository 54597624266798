/* eslint-disable */
<template>
  <div class="form-wrap">
    <form class="login">
      <p class="login-register">
        {{ $t("view_meta.do_not_have_an_account") }}
        <router-link class="router-link" :to="{name: 'register'}">
          {{
            $t("view_meta.register")
          }}
        </router-link>
      </p>
      <h2>{{ $t("view_meta.login_to_translation_hut") }}</h2>
      <div class="inputs">
        <div class="input">
          <input v-model="email" type="text" :placeholder="$t('users.email')">
          <v-img :src="emailSVG" class="icon" />
        </div>
        <div class="input">
          <input
            v-model="password"
            type="password"
            :placeholder="$t('authentication.login_form.password')"
          >
          <v-img :src="passwordSVG" class="icon" />
        </div>
      </div>

      <router-link class="forgot-password" :to="{name: 'forgot-password'}">
        {{ $t("authentication.forgot_your_password") }}
      </router-link>
      <button>{{ $t("authentication.create_account") }}</button>
      <div class="angle" />
    </form>
    <div class="background" />
  </div>
</template>

<script>
import emailSVG from "@/assets/Icons/envelope-regular.svg";
import passwordSVG from "@/assets/Icons/lock-alt-solid.svg";

export default {
  name: "Login",

  components: {},
  props: {
    value: String
  },
  data() {
    return {
      email: null,
      password: null,
      emailSVG,
      passwordSVG,
      showPassword: false,
      styleField: {
        color: "black"
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>

<style lang="scss" scoped>
.blackText {
  color: black;
}
@import "./src/sass/login.scss";
</style>
