import fragments from "@/graphql/fragments";
import gql from "graphql-tag";

const mutations = {
  createAccount: gql`
    mutation createAccount($data: AccountInput!) {
      createAccount(data: $data)
    }
  `,

  login: gql`
    mutation login($input: AccountInput!) {
      login(loginInput: $input) {
        email
      }
    }
  `,

  logout: gql`
    mutation logoutUser {
      logout
    }
  `,

  obtainToken: gql`
    mutation obtainToken($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
        refreshToken
        refreshExpiresIn
      }
    }
  `,

  refreshToken: gql`
    mutation refreshToken($refreshToken: String) {
      refreshJwt(refreshToken: $refreshToken) {
        token
        refreshExpiresIn
      }
    }
  `,

  revokeRefreshToken: gql`
    mutation revokeRefreshToken($refreshToken: String!) {
      revokeRefreshJwt(refreshToken: $refreshToken) {
        revoked
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($data: UserInput!) {
      updateUser(data: $data) {
        id
      }
    }
  `,

  setPassword: gql`
    mutation setPassword($password: String!) {
      setPassword(password: $password)
    }
  `,

  resetPassword: gql`
    mutation resetPassword($email: Email!) {
      resetPassword(email: $email)
    }
  `,

  confirmResetPassword: gql`
    mutation confirmResetPassword($data: ConfirmResetPasswordInput!) {
      confirmResetPassword(data: $data) {
        accessToken
        refreshToken
        refreshExpiresIn
      }
    }
  `,

  inviteProjectUser: gql`
    mutation inviteProjectUser($data: InviteUserInput!) {
      inviteProjectUser(data: $data)
    }
  `,

  updateUserRole: gql`
    mutation updateUserRole($userId: ID!, $projectId: ProjectID!, $roles: [Role!]!) {
      updateUserRole(userId: $userId, projectId: $projectId, roles: $roles) {
        user
        project {
          ...publicProjectFields
        }
        roles
      }
    }
    ${fragments.publicProject}
  `,

  deleteUserRole: gql`
    mutation deleteUserRole($userId: ID!, $projectId: ProjectID!) {
      deleteUserRole(userId: $userId, projectId: $projectId)
    }
  `,

  createProject: gql`
    mutation createProject($languageCode: String, $organizationId: String) {
      createProject(languageCode: $languageCode, organizationId: $organizationId) {
        ...projectDetailFields
      }
    }
    ${fragments.projectDetail}
  `,

  updateProject: gql`
    mutation updateProject($id: ProjectID!, $data: UpdateProjectInput!) {
      updateProject(id: $id, data: $data) {
        ...projectDetailFields
      }
    }
    ${fragments.projectDetail}
  `,

  createTag: gql`
    mutation createTag($data: TagInput!) {
      createTag(data: $data) {
        ...tagFields
      }
    }
    ${fragments.tag}
  `,

  deleteTag: gql`
    mutation deleteTag($id: ID!) {
      deleteTag(id: $id)
    }
  `,

  createAsset: gql`
    mutation createAsset($data: CreateAssetInput!) {
      createAsset(data: $data) {
        ...assetFields
      }
    }
    ${fragments.asset}
  `,

  updateAsset: gql`
    mutation updateAsset($id: ID!, $data: UpdateAssetInput!) {
      updateAsset(id: $id, data: $data) {
        ...assetFields
      }
    }
    ${fragments.asset}
  `,
  
  createTranslation: gql`
    mutation createTranslation($data: CreateTranslationInput!) {
      createTranslation(data: $data) {
        id
        language {
          code
        }
        value
      }
    }
  `,

  updateTranslation: gql`
    mutation updateTranslation($id: ID!, $data: UpdateTranslationInput!) {
      updateTranslation(id: $id, data: $data) {
        value
      }
    }
  `,

  createComment: gql`
    mutation createComment($data: CreateCommentInput!) {
      createComment(data: $data) {
        ...commentFields
      }
    }
    ${fragments.comment}
  `,

  createApiKey: gql`
    mutation createApiKey($projectId: ProjectID!, $data: CreateApiKeyInput!) {
      createApiKey(projectId: $projectId, data: $data)
    }
  `,

  updateApiKey: gql`
    mutation updateApiKey($id: ID!, $roles: [Role!]!) {
      updateApiKey(id: $id, roles: $roles)
    }
  `,


  revokedApiKey: gql`
    mutation revokedApiKey($id: ID!) {
      revokeApiKey(id: $id)
    }
  `,

  orderMachineTranslation: gql`
    mutation orderMachineTranslation($data: OrderMachineTranslationInput!) {
      orderMachineTranslation(data: $data)
    }
  `,

  importData: gql`
    mutation importData($data: ImportDataInput!) {
      importData(data: $data)
    }
  `,

  exportData: gql`
    mutation exportData($data: ExportDataInput!) {
      exportData(data: $data)
    }
  `,

  createCreditCard: gql`
    mutation createCreditCard($data: CreditCardInput!) {
      createCreditCard(data: $data)
    }    
  `,

  uploadImage: gql`
    mutation uploadImage($image: AbsoluteUpload!) {
      uploadImage(image: $image) {
        id
        url
      }
    }
  `,

  acceptInvite: gql`
    mutation acceptInvite($code: String!) {
      acceptInvite(code: $code) {
        accessToken
        refreshToken
        refreshExpiresIn
      }
    }
  `,

  updateOrganizationRole: gql`
    mutation updateOrganizationRole($data: OrganzationRoleInput!) {
      updateOrganizationRole(data: $data)
    }
  `,

  createOrganization: gql`
    mutation createOrganization($data: CreateOrganizationInput!) {
      createOrganization(data: $data) {
        ...organizationFields
      }
    }
    ${fragments.organization}
  `,

  updateOrganization: gql`
    mutation updateOrganization($id: ID!, $data: UpdateOrganizationInput!) {
      updateOrganization(id: $id, data: $data) {
        ...organizationFields
      }
    }
    ${fragments.organization}
  `,

  inviteOrganizationUser: gql`
    mutation inviteOrganizationUser($data: InviteOrganizationUserInput!) {
      inviteOrganizationUser(data: $data)
    }
  `,

  removeOrganizationUser: gql`
    mutation removeOrganizationUser($data: RemoveOrganzationUserInput!) {
      removeOrganizationUser(data: $data)
    }
  `,

  updateUserProjectRoles: gql`
    mutation updateUserProjectRoles($data: UpdateProjectRoleInput!) {
      updateUserProjectRoles(data: $data) {
        id
      }
    }
  `,

  linkProjectToOrganization: gql`
    mutation linkProjectToOrganization($organizationId: ID!, $projectId: ProjectID!) {
      linkProjectToOrganization(organizationId: $organizationId, projectId: $projectId)
    }
  `,

  removeOrganization: gql`
    mutation removeOrganization($id: ID!) {
      removeOrganization(id: $id)
    }
  `,

  updatePlan: gql`
    mutation updatePlan($id: ID!, $plan: OrganizationPlan!) {
      updatePlan(id: $id, plan: $plan)
    }
  `
};

export default mutations;
