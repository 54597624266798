



































import Vue from 'vue';
import { handleErrors } from '@/utils/notifications';
import client from '@/graphql/client';

export default Vue.extend({
  name: 'NewOrganizationModal',

  props: {
    value: { default: true, type: Boolean }
  },

  data() {
    return {
      organizationName: '',
      showModal: false,
    }
  },

  methods: {
    close() {
      this.$emit('input', false)
    },

    async submitForm() {
      const { data, errors } = await client.createOrganization({
        data: {
          name: this.organizationName
        }
      });
      if (data) {
        this.close();
        await this.$router.push({
          name: 'organization',
          params: { id: data.createOrganization.id }
        })
      } else {
        handleErrors(errors)
      }
    },
  },
});
