var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-wrap" }, [
    _c(
      "form",
      { staticClass: "login" },
      [
        _c(
          "p",
          { staticClass: "login-register" },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("view_meta.do_not_have_an_account")) + " "
            ),
            _c(
              "router-link",
              {
                staticClass: "router-link",
                attrs: { to: { name: "register" } }
              },
              [_vm._v(" " + _vm._s(_vm.$t("view_meta.register")) + " ")]
            )
          ],
          1
        ),
        _c("h2", [
          _vm._v(_vm._s(_vm.$t("view_meta.login_to_translation_hut")))
        ]),
        _c("div", { staticClass: "inputs" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: { type: "text", placeholder: _vm.$t("users.email") },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _c("v-img", { staticClass: "icon", attrs: { src: _vm.emailSVG } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("authentication.login_form.password")
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              }),
              _c("v-img", {
                staticClass: "icon",
                attrs: { src: _vm.passwordSVG }
              })
            ],
            1
          )
        ]),
        _c(
          "router-link",
          {
            staticClass: "forgot-password",
            attrs: { to: { name: "forgot-password" } }
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("authentication.forgot_your_password")) + " "
            )
          ]
        ),
        _c("button", [_vm._v(_vm._s(_vm.$t("authentication.create_account")))]),
        _c("div", { staticClass: "angle" })
      ],
      1
    ),
    _c("div", { staticClass: "background" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }