




import { authenticate } from '@/utils/auth';
import { handleErrors } from '@/utils/notifications';
import client from '@/graphql/client';
import { saveAccessToken, saveRefreshToken } from '@/utils/jwt';

export default {
  async created() {
    const code = this.$route.query.code
    if (code) {
      const { data, errors } = await client.acceptInvite({ code: code })
      if (errors.length) {
        handleErrors(errors)
      } else {
        const { accessToken, refreshToken, refreshExpiresIn } = data.acceptInvite
        saveAccessToken(accessToken)
        saveRefreshToken(refreshToken, refreshExpiresIn)

        await authenticate()
      }
      await this.$router.push({ path: '/' })
    }
  }
}
