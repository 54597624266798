<template>
  <g-modal
    max-width="600"
    @input="hideModal"
  >
    <v-carousel
      height="360"
      hide-delimiter-background
      hide-delimiters
      touchless
      :show-arrows="false"
      :value="secret ? 2 : 1"
    >
      <v-carousel-item :value="1">
        <v-form @submit.prevent="submit">
          <v-card-title>{{ $t('api_keys.create') }}</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="formData.name"
              style="max-width: 350px"
              class="mt-4"
              :dark="$vuetify.theme.isDark"
              :light="!$vuetify.theme.isDark"
              :label="$t('global.name')"
            />
            <g-chip-select
              v-model="formData.roles"
              :label="$t('project_settings.users.roles')"
              :items="items.map(i => ({value: i, text: i}))"
            />
            <v-dialog
              ref="dialog"
              v-model="dateModal"
              :return-value.sync="expiryDate"
              width="290px"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="expiryDate"
                  label="api_keys.expiry_date"
                  append-icon="mdi-calendar"
                  readonly
                  style="max-width: 350px"
                  v-bind="attrs"
                  :dark="$vuetify.theme.isDark"
                  :light="!$vuetify.theme.isDark"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="expiryDate"
                scrollable
              >
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="dateModal = false"
                >
                  {{ $t('actions.cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.dialog.save(expiryDate)"
                >
                  {{ $t('actions.save') }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-card-text>

          <button
            type="submit"
            style="display: none"
          />
        </v-form>
      </v-carousel-item>

      <v-carousel-item :value="2">
        <display-secret :secret="secret" />
      </v-carousel-item>
    </v-carousel>

    <template #actions>
      <div class="actions">
        <v-btn
          v-if="secret"
          color="primary"
          @click.prevent="$emit('input', false)"
        >
          {{ $t('actions.close') }}
        </v-btn>

        <v-btn
          v-else
          color="primary"
          type="submit"
          @click.prevent="submit"
        >
          {{ $t('actions.add') }}
        </v-btn>
      </div>
    </template>
  </g-modal>
</template>

<script>
import { handleErrors } from '@/utils/notifications';
import DisplaySecret from '@/components/develop/DisplaySecret';
import client from '@/graphql/client';
import events from '@/events';
import { state } from '@/store';

export default {
  name: 'CreateApiKeyDialog',

  components: { DisplaySecret },

  data() {
    return {
      secret: null,
      formData: {
        name: '',
        roles: ['admin'],
      },
      expiryDate: null,
      dateModal: false,
    }
  },

  computed: {
    routes() {
      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
    },

    items() {
      return this.routes
        .flatMap(({meta}) => meta && meta.roles)
        // Filter empty, duplicates and admin (admin is not a role)
        .filter((v, i, arr) =>  !!v && arr.indexOf(v) === i)
    },
  },

  methods: {
    hideModal() {
      setTimeout(() => {
        this.$emit('input', false)
      }, 200)
    },

    async submit() {
      this.secret = null; // unmount modal
      const { data, errors } = await client.createApiKey({projectId: state.project.id, data: {
        name: this.formData.name,
        roles: this.formData.roles,
        expiryDate: this.expiryDate,
      }});
      if (errors.length) {
        handleErrors(errors);
        return
      }
      
      this.secret = data.createApiKey;
      events.createdApiKey.emit();
    },
  },
};
</script>

<style>
  .v-carousel__controls {
    display: none;
  }
</style>
