<template>
  <v-overlay
    :opacity="0.5"
    :value="loading"
    style="z-index: 999!important"
  >
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, required: true }
  }
}
</script>
