import gql from 'graphql-tag';

const fragments = {
  language: gql`
    fragment languageFields on LanguageType {
      id
      code
      imageUrl
      type
    }
  `,

  tag: gql`
    fragment tagFields on TagType {
      id
      color
      text
    }
  `,

  project: gql`
    fragment projectFields on ProjectType {
      id
      name
      slug
      imageUrl
      organization {
        id
        name
        hidden
      }
    }
  `,

  publicUser: gql`
    fragment publicUserFields on UserType {
      fullName
      email
      id
      lastOnline
      imageUrl
      isOnline
      lastOnline
    }
  `,
  assetHistory: gql`
    fragment assetHistoryFields on AssetHistoryType {
      id
      oldValue
      value
      byImport
      datetime

      byUser {
        imageUrl
        fullName
      }
      byApiKey {
        name
      }
    }
  `,
  translationHistory: gql`
    fragment translationHistoryFields on TranslationHistoryType {
      id
      oldValue
      value
      byImport
      byAutomatedTranslation
      datetime
      byUser {
        imageUrl
        fullName
      }
      byApiKey {
        name
      }
    }
  `,
  comment: gql`
    fragment commentFields on CommentType {
      id
      text
      user {
        id
        fullName
        imageUrl
      }
    }
  `,
  machineTranslationOrder: gql`
    fragment machineTranslationOrderFields on MachineTranslationOrderType {
      id
      created
      count
      status
      byUser {
        fullName
      }
      translations {
        id
        fromValue
        toValue
      }
      source {
        id
        code
      }
      to {
        id
        code
      }
    }
  `,

  address: gql`
    fragment addressFields on AddressType {
      addressLine1
      addressLine2
      postalCode
      city
      state
      country
    }
  `,
  
  projectDetail: null,
  user: null,
  asset: null,
  publicProject: null,
  organization: null
};

fragments.projectDetail = gql`
  fragment projectDetailFields on ProjectType {
    ...projectFields
    description
    plan
    trailExpiryDate
    languages {
      ...languageFields
    }
    tags {
      ...tagFields
    }
    users {
      ...publicUserFields
    }
    organization {
      id
      name
      hidden
      plan
    }
  }
  ${fragments.project}
  ${fragments.language}
  ${fragments.publicUser}
  ${fragments.tag}
`;

fragments.publicProject = gql`
  fragment publicProjectFields on PublicProjectType {
    id
    name
    slug
  }
`;

fragments.user = gql`
    fragment userFields on UserType {
      imageUrl
      lastOnline
      username
      email
      id
      fullName
      firstName
      lastName
      locale
      isPasswordSet
      isOrganizationAdmin

      roles {
        id
        project {
          ...publicProjectFields
        }
        roles
      }

      projects {
        ...publicProjectFields
      }
    }
    ${fragments.publicProject}
`;

fragments.asset = gql`
  fragment assetFields on AssetType {
    id
    value
    translations {
      id
      value
      language {
        id
        code
      }
    }
    edited
    created
    description
    archived
    archivedId
    tags {
      ...tagFields
    }
  }
  ${fragments.tag}
`;

fragments.organization = gql`
  fragment organizationFields on OrganizationType {
    id
    created
    name
    physicalAddress {
      ...addressFields
    }
    billingAddress {
      ...addressFields
    }
    billingAddressIsPhysicalAddress
    vatNumber
    cocNumber
    hidden
    users {
      ...userFields
    }
    projects {
      ...publicProjectFields
    }
    plan
    trialExpiryDate
  }
  ${fragments.address}
  ${fragments.user}
  ${fragments.publicProject}
`;


export default fragments;
