export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  Email: any;
  /** Accepts URL or file uploads according to graphql-multipart-request-spec */
  AbsoluteUpload: any;
};

export type Query = {
  apiKeys: Array<ApiKeyType>;
  currentUser: UserType;
  fileFormats: Array<FileFormatType>;
  getUserRole: UserRoleType;
  languages: Array<LanguageType>;
  myOrganizations: Array<OrganizationType>;
  myProjects: Array<ProjectType>;
  organizationDetail: OrganizationType;
  project: ProjectType;
  projectStats: ProjectStatsType;
};


export type QueryApiKeysArgs = {
  projectId: Scalars['ID'];
};


export type QueryGetUserRoleArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryOrganizationDetailArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  slug: Scalars['String'];
};


export type QueryProjectStatsArgs = {
  projectId: Scalars['ID'];
};

export type ApiKeyType = {
  created: Scalars['DateTime'];
  id: Scalars['String'];
  lastOnline?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  revoked: Scalars['Boolean'];
};

export type UserType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageId?: Maybe<DjangoModelType>;
  imageUrl?: Maybe<Scalars['String']>;
  isOnline: Scalars['Boolean'];
  isOrganizationAdmin: Scalars['Boolean'];
  isPasswordSet: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastOnline: Scalars['DateTime'];
  locale: Scalars['String'];
  projects?: Maybe<Array<PublicProjectType>>;
  roles?: Maybe<Array<UserRoleType>>;
  username: Scalars['String'];
};

export type DjangoModelType = {
  pk: Scalars['ID'];
};

export type PublicProjectType = {
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type UserRoleType = {
  id: Scalars['ID'];
  project: PublicProjectType;
  roles: Array<Role>;
  user: Scalars['ID'];
};

export enum Role {
  Admin = 'admin',
  Developer = 'developer',
  Manager = 'manager',
  Translator = 'translator'
}

export type FileFormatType = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type LanguageType = {
  code: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type OrganizationType = {
  billingAddress?: Maybe<AddressType>;
  billingAddressIsPhysicalAddress: Scalars['Boolean'];
  cocNumber?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  physicalAddress?: Maybe<AddressType>;
  projects?: Maybe<Array<PublicProjectType>>;
  users: Array<UserType>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type AddressType = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type ProjectType = {
  assetById: AssetType;
  assets: AssetListResponse;
  creditCards?: Maybe<Array<CreditCardType>>;
  description?: Maybe<Scalars['String']>;
  exportedData?: Maybe<Array<ExportedDataType>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  languages: Array<LanguageType>;
  machineTranslationOrder: MachineTranslationOrderType;
  machineTranslationOrders?: Maybe<Array<MachineTranslationOrderType>>;
  name: Scalars['String'];
  organization?: Maybe<OrganizationType>;
  plan?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagType>>;
  trailExpiryDate?: Maybe<Scalars['String']>;
  translationById: TranslationType;
  usage?: Maybe<UsageType>;
  users: Array<UserType>;
};


export type ProjectTypeAssetByIdArgs = {
  id: Scalars['ID'];
};


export type ProjectTypeAssetsArgs = {
  filters?: InputMaybe<AssetFilter>;
  order?: InputMaybe<AssetOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type ProjectTypeMachineTranslationOrderArgs = {
  id: Scalars['ID'];
};


export type ProjectTypeTranslationByIdArgs = {
  id: Scalars['ID'];
};

export type AssetType = {
  archived: Scalars['Boolean'];
  archivedId: Scalars['Int'];
  comments?: Maybe<Array<CommentType>>;
  created: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  edited: Scalars['DateTime'];
  history?: Maybe<Array<AssetHistoryType>>;
  id: Scalars['ID'];
  tags: Array<TagType>;
  translations?: Maybe<Array<TranslationType>>;
  value: Scalars['String'];
};

export type CommentType = {
  asset?: Maybe<DjangoModelType>;
  id: Scalars['ID'];
  text: Scalars['String'];
  translation?: Maybe<DjangoModelType>;
  user: UserType;
};

export type AssetHistoryType = {
  byApiKey?: Maybe<ApiKeyType>;
  byImport: Scalars['Boolean'];
  byUser?: Maybe<UserType>;
  datetime: Scalars['DateTime'];
  id: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TagType = {
  color: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type TranslationType = {
  comments?: Maybe<Array<CommentType>>;
  created: Scalars['DateTime'];
  edited: Scalars['DateTime'];
  history?: Maybe<Array<TranslationHistoryType>>;
  id: Scalars['ID'];
  language: LanguageType;
  value: Scalars['String'];
};

export type TranslationHistoryType = {
  byApiKey?: Maybe<ApiKeyType>;
  byAutomatedTranslation: Scalars['Boolean'];
  byImport: Scalars['Boolean'];
  byUser?: Maybe<UserType>;
  datetime: Scalars['DateTime'];
  id: Scalars['ID'];
  oldValue?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type AssetFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  excludedLanguages?: InputMaybe<Array<Scalars['ID']>>;
  excludedTags?: InputMaybe<Array<Scalars['ID']>>;
  languages?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<IdFilterLookup>;
};

export type IdFilterLookup = {
  contains?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  exact?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iContains?: InputMaybe<Scalars['ID']>;
  iEndsWith?: InputMaybe<Scalars['ID']>;
  iExact?: InputMaybe<Scalars['ID']>;
  inList?: InputMaybe<Array<Scalars['ID']>>;
  iRegex?: InputMaybe<Scalars['String']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  iStartsWith?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  range?: InputMaybe<Array<Scalars['ID']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type AssetOrder = {
  created?: InputMaybe<Ordering>;
  edited?: InputMaybe<Ordering>;
  value?: InputMaybe<Ordering>;
};

export enum Ordering {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OffsetPaginationInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type AssetListResponse = {
  count: Scalars['Int'];
  results: Array<AssetType>;
};

export type CreditCardType = {
  holderName: Scalars['String'];
  id: Scalars['ID'];
  lastDigits: Scalars['String'];
  month: Scalars['Int'];
  owner: UserType;
  typeName: Scalars['String'];
  year: Scalars['Int'];
};

export type ExportedDataType = {
  createdOn: Scalars['DateTime'];
  name: Scalars['String'];
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type MachineTranslationOrderType = {
  byUser: UserType;
  count: Scalars['Int'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  source: LanguageType;
  status: Scalars['String'];
  to: LanguageType;
  translations?: Maybe<Array<MachineTranslationType>>;
};

export type MachineTranslationType = {
  fromLanguage: LanguageType;
  fromValue: Scalars['String'];
  id: Scalars['ID'];
  toLanguage: LanguageType;
  toValue: Scalars['String'];
  translation: TranslationType;
};

export type UsageType = {
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  machineTranslationsLeft: Scalars['Int'];
  machineTranslationsLimit: Scalars['Int'];
};

export type ProjectStatsType = {
  assets: Scalars['Int'];
  languages: Scalars['Int'];
  translated: Scalars['Float'];
  translations: Scalars['Int'];
  users: Scalars['Int'];
};

export type Mutation = {
  acceptInvite: Token;
  confirmResetPassword: Scalars['String'];
  createAccount: Scalars['Boolean'];
  createApiKey: Scalars['String'];
  createAsset: AssetType;
  createComment: CommentType;
  createCreditCard: Scalars['Boolean'];
  createOrganization: OrganizationType;
  createProject: ProjectType;
  createTag: TagType;
  createTranslation: TranslationType;
  deleteTag: Scalars['Boolean'];
  deleteUserRole: Scalars['Boolean'];
  exportData: Scalars['String'];
  importData: Scalars['Boolean'];
  inviteOrganizationUser: Scalars['Boolean'];
  inviteProjectUser: Scalars['Boolean'];
  login: UserType;
  logout: Scalars['Boolean'];
  orderMachineTranslation: Scalars['Boolean'];
  refreshJwt: RefreshedTokenType;
  removeOrganizationUser: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  revokeApiKey: Scalars['Boolean'];
  revokeRefreshJwt: RevokeType;
  setPassword: Scalars['Boolean'];
  tokenAuth: TokenDataType;
  updateAsset: AssetType;
  updateOrganization: OrganizationType;
  updateOrganizationRole: Scalars['Boolean'];
  updateProject: ProjectType;
  updateTranslation: TranslationType;
  updateUser: UserType;
  updateUserRole: UserRoleType;
  uploadImage: ImageType;
  verifyJwt: PayloadType;
};


export type MutationAcceptInviteArgs = {
  code: Scalars['String'];
};


export type MutationConfirmResetPasswordArgs = {
  data: ConfirmResetPasswordInput;
};


export type MutationCreateAccountArgs = {
  data: AccountInput;
};


export type MutationCreateApiKeyArgs = {
  data: CreateApiKeyInput;
  projectId: Scalars['ID'];
};


export type MutationCreateAssetArgs = {
  data: CreateAssetInput;
};


export type MutationCreateCommentArgs = {
  data: CreateCommentInput;
};


export type MutationCreateCreditCardArgs = {
  data: CreditCardInput;
};


export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};


export type MutationCreateProjectArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTagArgs = {
  data: TagInput;
};


export type MutationCreateTranslationArgs = {
  data: CreateTranslationInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserRoleArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationExportDataArgs = {
  data: ExportDataInput;
};


export type MutationImportDataArgs = {
  data: ImportDataInput;
};


export type MutationInviteOrganizationUserArgs = {
  data: InviteOrganizationUserInput;
};


export type MutationInviteProjectUserArgs = {
  data: InviteUserInput;
};


export type MutationLoginArgs = {
  loginInput: AccountInput;
};


export type MutationOrderMachineTranslationArgs = {
  data: OrderMachineTranslationInput;
};


export type MutationRefreshJwtArgs = {
  refresh_token?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveOrganizationUserArgs = {
  data: RemoveOrganzationUserInput;
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email'];
};


export type MutationRevokeApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationRevokeRefreshJwtArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  refresh_token?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type MutationUpdateAssetArgs = {
  data: UpdateAssetInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationRoleArgs = {
  data: OrganzationRoleInput;
};


export type MutationUpdateProjectArgs = {
  data: UpdateProjectInput;
};


export type MutationUpdateTranslationArgs = {
  data: UpdateTranslationInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  data: UserInput;
};


export type MutationUpdateUserRoleArgs = {
  projectId: Scalars['ID'];
  roles: Array<Role>;
  userId: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  image: Scalars['AbsoluteUpload'];
};


export type MutationVerifyJwtArgs = {
  token: Scalars['String'];
};

export type Token = {
  accessToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
};

export type ConfirmResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};

export type AccountInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateApiKeyInput = {
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Role>>;
};

export type CreateAssetInput = {
  description?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['ID']>>;
  value: Scalars['String'];
};

export type CreateCommentInput = {
  assetId?: InputMaybe<Scalars['ID']>;
  text: Scalars['String'];
  translationId?: InputMaybe<Scalars['ID']>;
};

export type CreditCardInput = {
  cvv: Scalars['String'];
  holderName: Scalars['String'];
  month: Scalars['Int'];
  number: Scalars['String'];
  projectId: Scalars['ID'];
  typeName: Scalars['String'];
  year: Scalars['Int'];
};

export type CreateOrganizationInput = {
  billingAddress?: InputMaybe<AddressInput>;
  billingAddressIsPhysicalAddress?: InputMaybe<Scalars['Boolean']>;
  cocNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  physicalAddress?: InputMaybe<AddressInput>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
};

export type TagInput = {
  color?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  text: Scalars['String'];
};

export type CreateTranslationInput = {
  asset?: InputMaybe<Scalars['ID']>;
  language: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type ExportDataInput = {
  format: Scalars['String'];
  fromLanguage?: InputMaybe<Scalars['ID']>;
  includeEmpty: Scalars['Boolean'];
  languageIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type ImportDataInput = {
  languageId: Scalars['ID'];
  projectId: Scalars['ID'];
  publicId: Scalars['String'];
  secureUrl: Scalars['String'];
};

export type InviteOrganizationUserInput = {
  makeUserAdmin?: InputMaybe<Scalars['Boolean']>;
  newUserEmail: Scalars['Email'];
  organizationId: Scalars['ID'];
};

export type InviteUserInput = {
  makeUserAdmin?: InputMaybe<Scalars['Boolean']>;
  newUserEmail: Scalars['Email'];
  projectId: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

export type OrderMachineTranslationInput = {
  projectId: Scalars['ID'];
  source: Scalars['ID'];
  to: Scalars['ID'];
};

export type RefreshedTokenType = {
  payload: TokenPayloadType;
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type TokenPayloadType = {
  exp: Scalars['Int'];
  origIat: Scalars['Int'];
  username: Scalars['String'];
};

export type RemoveOrganzationUserInput = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RevokeType = {
  revoked: Scalars['DateTime'];
};

export type TokenDataType = {
  payload: TokenPayloadType;
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateAssetInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  billingAddress?: InputMaybe<AddressInput>;
  billingAddressIsPhysicalAddress?: InputMaybe<Scalars['Boolean']>;
  cocNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<AddressInput>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type OrganzationRoleInput = {
  organizationId: Scalars['ID'];
  role: OrganizationRole;
  userId: Scalars['ID'];
};

export enum OrganizationRole {
  Admin = 'admin',
  Member = 'member'
}

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageId?: InputMaybe<OneToManyInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type OneToManyInput = {
  set?: InputMaybe<Scalars['ID']>;
};

export type UpdateTranslationInput = {
  asset?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<OneToManyInput>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ImageType = {
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type PayloadType = {
  payload: TokenPayloadType;
};

export type LanguageFieldsFragment = { id: string, code: string, imageUrl?: string | null | undefined, type: string };

export type TagFieldsFragment = { id: string, color: string, text: string };

export type ProjectFieldsFragment = { id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, organization?: { id: string, name: string, hidden: boolean } | null | undefined };

export type PublicUserFieldsFragment = { fullName?: string | null | undefined, email: string, id: string, lastOnline: any, imageUrl?: string | null | undefined, isOnline: boolean };

export type AssetHistoryFieldsFragment = { id: string, oldValue?: string | null | undefined, value?: string | null | undefined, byImport: boolean, datetime: any, byUser?: { imageUrl?: string | null | undefined, fullName?: string | null | undefined } | null | undefined, byApiKey?: { name: string } | null | undefined };

export type TranslationHistoryFieldsFragment = { id: string, oldValue?: string | null | undefined, value: string, byImport: boolean, byAutomatedTranslation: boolean, datetime: any, byUser?: { imageUrl?: string | null | undefined, fullName?: string | null | undefined } | null | undefined, byApiKey?: { name: string } | null | undefined };

export type CommentFieldsFragment = { id: string, text: string, user: { id: string, fullName?: string | null | undefined, imageUrl?: string | null | undefined } };

export type MachineTranslationOrderFieldsFragment = { id: string, created: any, count: number, status: string, byUser: { fullName?: string | null | undefined }, translations?: Array<{ id: string, fromValue: string, toValue: string }> | null | undefined, source: { id: string }, to: { id: string } };

export type AddressFieldsFragment = { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string };

export type ProjectDetailFieldsFragment = { description?: string | null | undefined, plan?: string | null | undefined, trailExpiryDate?: string | null | undefined, id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, languages: Array<{ id: string, code: string, imageUrl?: string | null | undefined, type: string }>, tags?: Array<{ id: string, color: string, text: string }> | null | undefined, users: Array<{ fullName?: string | null | undefined, email: string, id: string, lastOnline: any, imageUrl?: string | null | undefined, isOnline: boolean }>, organization?: { id: string, name: string, hidden: boolean } | null | undefined };

export type PublicProjectFieldsFragment = { id: string, name: string, slug?: string | null | undefined };

export type UserFieldsFragment = { imageUrl?: string | null | undefined, lastOnline: any, username: string, email: string, id: string, fullName?: string | null | undefined, firstName: string, lastName: string, locale: string, isPasswordSet: boolean, isOrganizationAdmin: boolean, roles?: Array<{ id: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined };

export type AssetFieldsFragment = { id: string, value: string, edited: any, created: any, description?: string | null | undefined, archived: boolean, archivedId: number, translations?: Array<{ id: string, value: string, language: { id: string, code: string } }> | null | undefined, tags: Array<{ id: string, color: string, text: string }> };

export type OrganizationFieldsFragment = { id: string, created: any, name: string, billingAddressIsPhysicalAddress: boolean, vatNumber?: string | null | undefined, cocNumber?: string | null | undefined, hidden: boolean, physicalAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, billingAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, users: Array<{ imageUrl?: string | null | undefined, lastOnline: any, username: string, email: string, id: string, fullName?: string | null | undefined, firstName: string, lastName: string, locale: string, isPasswordSet: boolean, isOrganizationAdmin: boolean, roles?: Array<{ id: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined }>, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined };

export type CreateAccountMutationVariables = Exact<{
  data: AccountInput;
}>;


export type CreateAccountMutation = { createAccount: boolean };

export type LoginMutationVariables = Exact<{
  input: AccountInput;
}>;


export type LoginMutation = { login: { email: string } };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { logout: boolean };

export type ObtainTokenMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type ObtainTokenMutation = { tokenAuth: { token: string, refreshToken: string, refreshExpiresIn: number } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
}>;


export type RefreshTokenMutation = { refreshJwt: { token: string, refreshExpiresIn: number } };

export type RevokeRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RevokeRefreshTokenMutation = { revokeRefreshJwt: { revoked: any } };

export type UpdateUserMutationVariables = Exact<{
  data: UserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: string } };

export type SetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type SetPasswordMutation = { setPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
}>;


export type ResetPasswordMutation = { resetPassword: boolean };

export type ConfirmResetPasswordMutationVariables = Exact<{
  data: ConfirmResetPasswordInput;
}>;


export type ConfirmResetPasswordMutation = { confirmResetPassword: string };

export type InviteProjectUserMutationVariables = Exact<{
  data: InviteUserInput;
}>;


export type InviteProjectUserMutation = { inviteProjectUser: boolean };

export type UpdateUserRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
  roles: Array<Role> | Role;
}>;


export type UpdateUserRoleMutation = { updateUserRole: { user: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } } };

export type DeleteUserRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteUserRoleMutation = { deleteUserRole: boolean };

export type CreateProjectMutationVariables = Exact<{
  languageCode?: InputMaybe<Scalars['String']>;
}>;


export type CreateProjectMutation = { createProject: { description?: string | null | undefined, plan?: string | null | undefined, trailExpiryDate?: string | null | undefined, id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, languages: Array<{ id: string, code: string, imageUrl?: string | null | undefined, type: string }>, tags?: Array<{ id: string, color: string, text: string }> | null | undefined, users: Array<{ fullName?: string | null | undefined, email: string, id: string, lastOnline: any, imageUrl?: string | null | undefined, isOnline: boolean }>, organization?: { id: string, name: string, hidden: boolean } | null | undefined } };

export type UpdateProjectMutationVariables = Exact<{
  data: UpdateProjectInput;
}>;


export type UpdateProjectMutation = { updateProject: { description?: string | null | undefined, plan?: string | null | undefined, trailExpiryDate?: string | null | undefined, id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, languages: Array<{ id: string, code: string, imageUrl?: string | null | undefined, type: string }>, tags?: Array<{ id: string, color: string, text: string }> | null | undefined, users: Array<{ fullName?: string | null | undefined, email: string, id: string, lastOnline: any, imageUrl?: string | null | undefined, isOnline: boolean }>, organization?: { id: string, name: string, hidden: boolean } | null | undefined } };

export type CreateTagMutationVariables = Exact<{
  data: TagInput;
}>;


export type CreateTagMutation = { createTag: { id: string, color: string, text: string } };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = { deleteTag: boolean };

export type CreateAssetMutationVariables = Exact<{
  data: CreateAssetInput;
}>;


export type CreateAssetMutation = { createAsset: { id: string, value: string, edited: any, created: any, description?: string | null | undefined, archived: boolean, archivedId: number, translations?: Array<{ id: string, value: string, language: { id: string, code: string } }> | null | undefined, tags: Array<{ id: string, color: string, text: string }> } };

export type UpdateAssetMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateAssetInput;
}>;


export type UpdateAssetMutation = { updateAsset: { id: string, value: string, edited: any, created: any, description?: string | null | undefined, archived: boolean, archivedId: number, translations?: Array<{ id: string, value: string, language: { id: string, code: string } }> | null | undefined, tags: Array<{ id: string, color: string, text: string }> } };

export type CreateTranslationMutationVariables = Exact<{
  data: CreateTranslationInput;
}>;


export type CreateTranslationMutation = { createTranslation: { id: string, value: string, language: { id: string, code: string } } };

export type UpdateTranslationMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateTranslationInput;
}>;


export type UpdateTranslationMutation = { updateTranslation: { value: string } };

export type CreateCommentMutationVariables = Exact<{
  data: CreateCommentInput;
}>;


export type CreateCommentMutation = { createComment: { id: string, text: string, user: { id: string, fullName?: string | null | undefined, imageUrl?: string | null | undefined } } };

export type CreateApiKeyMutationVariables = Exact<{
  projectId: Scalars['ID'];
  data: CreateApiKeyInput;
}>;


export type CreateApiKeyMutation = { createApiKey: string };

export type RevokedApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevokedApiKeyMutation = { revokeApiKey: boolean };

export type OrderMachineTranslationMutationVariables = Exact<{
  data: OrderMachineTranslationInput;
}>;


export type OrderMachineTranslationMutation = { orderMachineTranslation: boolean };

export type ImportDataMutationVariables = Exact<{
  data: ImportDataInput;
}>;


export type ImportDataMutation = { importData: boolean };

export type ExportDataMutationVariables = Exact<{
  data: ExportDataInput;
}>;


export type ExportDataMutation = { exportData: string };

export type CreateCreditCardMutationVariables = Exact<{
  data: CreditCardInput;
}>;


export type CreateCreditCardMutation = { createCreditCard: boolean };

export type UploadImageMutationVariables = Exact<{
  image: Scalars['AbsoluteUpload'];
}>;


export type UploadImageMutation = { uploadImage: { id: string, url: string } };

export type AcceptInviteMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type AcceptInviteMutation = { acceptInvite: { accessToken: string, refreshToken: string, refreshExpiresIn: number } };

export type UpdateOrganizationRoleMutationVariables = Exact<{
  data: OrganzationRoleInput;
}>;


export type UpdateOrganizationRoleMutation = { updateOrganizationRole: boolean };

export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { createOrganization: { id: string, created: any, name: string, billingAddressIsPhysicalAddress: boolean, vatNumber?: string | null | undefined, cocNumber?: string | null | undefined, hidden: boolean, physicalAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, billingAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, users: Array<{ imageUrl?: string | null | undefined, lastOnline: any, username: string, email: string, id: string, fullName?: string | null | undefined, firstName: string, lastName: string, locale: string, isPasswordSet: boolean, isOrganizationAdmin: boolean, roles?: Array<{ id: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined }>, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined } };

export type UpdateOrganizationMutationVariables = Exact<{
  data: UpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { updateOrganization: { id: string, created: any, name: string, billingAddressIsPhysicalAddress: boolean, vatNumber?: string | null | undefined, cocNumber?: string | null | undefined, hidden: boolean, physicalAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, billingAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, users: Array<{ imageUrl?: string | null | undefined, lastOnline: any, username: string, email: string, id: string, fullName?: string | null | undefined, firstName: string, lastName: string, locale: string, isPasswordSet: boolean, isOrganizationAdmin: boolean, roles?: Array<{ id: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined }>, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined } };

export type InviteOrganizationUserMutationVariables = Exact<{
  data: InviteOrganizationUserInput;
}>;


export type InviteOrganizationUserMutation = { inviteOrganizationUser: boolean };

export type RemoveOrganizationUserMutationVariables = Exact<{
  data: RemoveOrganzationUserInput;
}>;


export type RemoveOrganizationUserMutation = { removeOrganizationUser: boolean };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, username: string, email: string, firstName: string, lastName: string, fullName?: string | null | undefined, imageUrl?: string | null | undefined, isOnline: boolean, lastOnline: any, locale: string, isPasswordSet: boolean, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined, roles?: Array<{ id: string, user: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined } };

export type GetUserRoleQueryVariables = Exact<{
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type GetUserRoleQuery = { getUserRole: { user: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } } };

export type MyProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProjectsQuery = { myProjects: Array<{ id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, organization?: { id: string, name: string, hidden: boolean } | null | undefined }> };

export type ProjectStatsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectStatsQuery = { projectStats: { users: number, languages: number, translated: number, translations: number } };

export type ProjectQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectQuery = { project: { description?: string | null | undefined, plan?: string | null | undefined, trailExpiryDate?: string | null | undefined, id: string, name: string, slug?: string | null | undefined, imageUrl?: string | null | undefined, languages: Array<{ id: string, code: string, imageUrl?: string | null | undefined, type: string }>, tags?: Array<{ id: string, color: string, text: string }> | null | undefined, users: Array<{ fullName?: string | null | undefined, email: string, id: string, lastOnline: any, imageUrl?: string | null | undefined, isOnline: boolean }>, organization?: { id: string, name: string, hidden: boolean } | null | undefined } };

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = { languages: Array<{ id: string, code: string, imageUrl?: string | null | undefined, type: string }> };

export type FileFormatsQueryVariables = Exact<{ [key: string]: never; }>;


export type FileFormatsQuery = { fileFormats: Array<{ name: string, description: string }> };

export type AssetsQueryVariables = Exact<{
  projectSlug: Scalars['String'];
  filters?: InputMaybe<AssetFilter>;
  order?: InputMaybe<AssetOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
}>;


export type AssetsQuery = { project: { assets: { count: number, results: Array<{ id: string, value: string, edited: any, created: any, description?: string | null | undefined, archived: boolean, archivedId: number, translations?: Array<{ id: string, value: string, language: { id: string, code: string } }> | null | undefined, tags: Array<{ id: string, color: string, text: string }> }> } } };

export type AssetByIdQueryVariables = Exact<{
  projectSlug: Scalars['String'];
  id: Scalars['ID'];
}>;


export type AssetByIdQuery = { project: { assetById: { id: string, value: string, edited: any, created: any, description?: string | null | undefined, archived: boolean, archivedId: number, history?: Array<{ id: string, oldValue?: string | null | undefined, value?: string | null | undefined, byImport: boolean, datetime: any, byUser?: { imageUrl?: string | null | undefined, fullName?: string | null | undefined } | null | undefined, byApiKey?: { name: string } | null | undefined }> | null | undefined, comments?: Array<{ id: string, text: string, user: { id: string, fullName?: string | null | undefined, imageUrl?: string | null | undefined } }> | null | undefined, translations?: Array<{ id: string, value: string, language: { id: string, code: string } }> | null | undefined, tags: Array<{ id: string, color: string, text: string }> } } };

export type TranslationByIdQueryVariables = Exact<{
  projectSlug: Scalars['String'];
  id: Scalars['ID'];
}>;


export type TranslationByIdQuery = { project: { translationById: { id: string, created: any, edited: any, value: string, language: { id: string, code: string, imageUrl?: string | null | undefined, type: string }, history?: Array<{ id: string, oldValue?: string | null | undefined, value: string, byImport: boolean, byAutomatedTranslation: boolean, datetime: any, byUser?: { imageUrl?: string | null | undefined, fullName?: string | null | undefined } | null | undefined, byApiKey?: { name: string } | null | undefined }> | null | undefined, comments?: Array<{ id: string, text: string, user: { id: string, fullName?: string | null | undefined, imageUrl?: string | null | undefined } }> | null | undefined } } };

export type ApiKeysQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ApiKeysQuery = { apiKeys: Array<{ id: string, created: any, lastOnline?: any | null | undefined, prefix: string, name: string, revoked: boolean }> };

export type MachineTranslationOrdersQueryVariables = Exact<{
  projectSlug: Scalars['String'];
}>;


export type MachineTranslationOrdersQuery = { project: { machineTranslationOrders?: Array<{ id: string, created: any, count: number, status: string, byUser: { fullName?: string | null | undefined }, translations?: Array<{ id: string, fromValue: string, toValue: string }> | null | undefined, source: { id: string }, to: { id: string } }> | null | undefined } };

export type MachineTranslationOrderQueryVariables = Exact<{
  projectSlug: Scalars['String'];
  id: Scalars['ID'];
}>;


export type MachineTranslationOrderQuery = { project: { machineTranslationOrder: { id: string, created: any, count: number, status: string, byUser: { fullName?: string | null | undefined }, translations?: Array<{ id: string, fromValue: string, toValue: string }> | null | undefined, source: { id: string }, to: { id: string } } } };

export type UsageQueryVariables = Exact<{
  projectSlug: Scalars['String'];
}>;


export type UsageQuery = { project: { usage?: { machineTranslationsLimit: number, machineTranslationsLeft: number } | null | undefined } };

export type GetExportedDataHistoryQueryVariables = Exact<{
  projectSlug: Scalars['String'];
}>;


export type GetExportedDataHistoryQuery = { project: { exportedData?: Array<{ createdOn: any, type: string, name: string, url?: string | null | undefined }> | null | undefined } };

export type GetCreditCardsQueryVariables = Exact<{
  projectSlug: Scalars['String'];
}>;


export type GetCreditCardsQuery = { project: { creditCards?: Array<{ id: string, month: number, year: number, lastDigits: string, holderName: string, typeName: string }> | null | undefined } };

export type MyOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrganizationsQuery = { myOrganizations: Array<{ id: string, created: any, name: string, hidden: boolean, users: Array<{ id: string, email: string }>, projects?: Array<{ id: string, name: string }> | null | undefined }> };

export type OrganizationDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationDetailQuery = { organizationDetail: { id: string, created: any, name: string, billingAddressIsPhysicalAddress: boolean, vatNumber?: string | null | undefined, cocNumber?: string | null | undefined, hidden: boolean, physicalAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, billingAddress?: { addressLine1: string, addressLine2: string, postalCode: string, city: string, state: string, country: string } | null | undefined, users: Array<{ imageUrl?: string | null | undefined, lastOnline: any, username: string, email: string, id: string, fullName?: string | null | undefined, firstName: string, lastName: string, locale: string, isPasswordSet: boolean, isOrganizationAdmin: boolean, roles?: Array<{ id: string, roles: Array<Role>, project: { id: string, name: string, slug?: string | null | undefined } }> | null | undefined, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined }>, projects?: Array<{ id: string, name: string, slug?: string | null | undefined }> | null | undefined } };
