





















































import Vue from 'vue';
import client from '@/graphql/client';
import { state } from "@/store";
import NewOrderModal from "@/components/machinetranslations/NewOrderModal.vue";
import OrderInfoModal from "@/components/machinetranslations/OrderInfoModal.vue";
import { timeAgoInWords, toTimeStamp } from "@/utils/datetime";
import { handleErrors } from '@/utils/notifications';
import { MachineTranslationOrderType } from '@/generated/graphql';
let intervalHandler;

export default Vue.extend({
  name: 'MachineTranslationsTable',

  components: { NewOrderModal, OrderInfoModal },

  data() {
    return {
      headers: [
        { value: 'source', text: this.$t('translate.from') },
        { value: 'to', text: this.$t('translate.to') },
        { value: 'count', text: this.$t('order.char_count') },
        { value: 'status', text: this.$t('order.status') },
        { value: 'created', text: this.$t('global.created') },
      ],
      items: [] as MachineTranslationOrderType[],
      addModal: false,
      selectedId: null,
    }
  },

  watch: {
    addModal(addModal) {
      if (!addModal) {
        this.fetchItems();
      }
    },
  },

  created() {
    this.fetchItems();
    intervalHandler = setInterval(this.fetchItems, 30000);
  },

  destroyed() {
    clearInterval(intervalHandler);
  },

  methods: {
    timeAgoInWords,
    toTimeStamp,

    selectItem(item) {
      this.selectedId = item.id;
    },

    async fetchItems() {
      const { data, errors } = await client.getMachineTranslationOrders({projectSlug: state.project.slug});
      if (errors.length) {
        handleErrors(errors)
        return
      }
      this.items = data.project.machineTranslationOrders
    },
  },
});
