





















































































































import Vue from 'vue'
import store, { state } from '@/store';
import syncStateWithStorage from '@/mixins/sync-state-with-storage.js';
import MODELS from '../../constants/models';
import { handleErrors, handleOptionalErrors } from '@/utils/notifications';
import { getEventIsSubmitForm } from '@/utils/eventShortcuts';
import displayEnterTip from '@/utils/displayEnterTip';
import client from '@/graphql/client';
import { GQLLanguageType, GQLTranslationType } from "../../../types";
import CategoryAutoComplete from "@/components/CategoryAutoComplete.vue";
import TagSelect from "@/components/tags/TagSelect.vue";

  interface Field {
    readonly value: string,
    readonly text: string,
    readonly label: string,
    readonly languageCode: string,
  }

export default Vue.extend({
  name: 'EditAssetForm',

  components: { CategoryAutoComplete, TagSelect },

  mixins: [syncStateWithStorage(['displayFields'])],

  props: {
    value: { type: Boolean, required: false, default: true },
    asset: { type: Object, required: true },
  },

  data: function data(): any {
    return {
      changedAsset: {} as Object,
      displayFields: [],
      addNewAssetOnSubmit: true,
      displaySettings: false,
    };
  },

  computed: {
    filteredDisplayFields(): Array<Field> {
      const fieldValues = this.displayFields.map((field: Field) => field.value);
      return this.fieldOptions
        .filter((field: Field) => fieldValues.includes(field.value));
    },

    fieldOptions(): any {
      return [
        ...state.project.languages.map((language: GQLLanguageType) => ({
          value: `language.${language.code}`,
          languageCode: language.code,
          text: `${language.code}`,
          label: `${language.code} ${this.$t('assets.translation_label')}`,
        })),
      ]
    },

    hasValue(): any {
      return this.changedAsset.value || this.filteredDisplayFields
        .map((f: Field) => this.changedAsset[f.value])
        .filter((v?: string) => !!v)
        .length > 0;
    },
  },

  created() {
    this.createChangedAsset()
  },

  methods: {
    async editArchived(archived: boolean) {
      const { errors } = await client.updateAsset({ id: this.asset.id, data: { archived } });
      handleOptionalErrors(errors);
      this.close();
    },

    async archiveAsset() {
      this.editArchived(true);
    },

    async unarchiveAsset() {
      this.editArchived(false);
    },

    createChangedAsset: function createChangedAsset() {
      this.changedAsset.id = this.asset.id;
      this.changedAsset.value = this.asset.value;
      this.changedAsset.description = this.asset.description;
      this.changedAsset.tags = (this.asset.tags || []).map(({id}) => id);

      this.fieldOptions
        .filter(({ languageCode }: { languageCode: boolean }) => languageCode)
        .forEach((field: Field) => {
          const translation = this.asset.translations.find((t: GQLTranslationType) => t.language && t.language.code === field.languageCode);
          this.$set(this.changedAsset, field.value, (translation && translation.value) || "");
        });
    },

    imageForLanguage(languageCode: string): string {
      return store.getLanguage(languageCode).imageUrl || "";
    },

    handleTextAreaKeyPress(event: Event) {
      displayEnterTip();
      if (getEventIsSubmitForm(event)) {
        this.submit()
      }
    },

    async submit() {
      if(!this.hasValue) {
        return;
      }

      const { errors } = await client.updateAsset({
        id: this.changedAsset.id,
        data: {
          archived: this.changedAsset.archived,
          description: this.changedAsset.description,
          value: this.changedAsset.value,
          tags: this.changedAsset.tags,
        }
      });

      if (errors.length) {
        handleErrors(errors);
        return;
      }

      await Promise.all(
        this.displayFields
          .filter((field: Field) => field.languageCode && typeof this.changedAsset[field.value] === "string")
          .map((field: Field) => {
            const id = (this.asset.translations.find((t: any) => t.language.code === field.languageCode) || {}).id
            if (id) {
              client.updateTranslation({
                id,
                data: {
                  value: this.changedAsset[field.value],
                  language: field.languageCode,
                  asset: this.asset.id,
                }
              })
            } else {
              client.createTranslation({
                data: {
                  value: this.changedAsset[field.value],
                  language: field.languageCode,
                  asset: this.asset.id,
                }
              })
            }
          })
      );

      this.emptyAsset();
      this.close();
    },

    close() {
      this.$emit('close', false);
    },

    emptyAsset() {
      Object.keys(this.changedAsset).forEach((key) => {
        this.changedAsset[key] = MODELS.asset[key];
      });
    }
  },
});
