var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("v-card", { staticClass: "ma-4" }, [_c("api-keys")], 1),
      _c("v-card", { staticClass: "ma-4 pa-4" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("GraphQL API")]),
        _c("p", [
          _vm._v(" Our API uses "),
          _c(
            "a",
            { attrs: { href: "https://graphql.org/", target: "_blank" } },
            [_vm._v("GraphQL")]
          ),
          _vm._v(". You can find all docs in our "),
          _c("a", { attrs: { href: _vm.graphiqlLink, target: "_blank" } }, [
            _vm._v("GraphiQL environment")
          ]),
          _vm._v(". ")
        ]),
        _c(
          "a",
          {
            staticClass: "not_clear_link",
            on: { click: _vm.openMessageModal }
          },
          [_vm._v("Something not clear? Let us know")]
        )
      ]),
      _c(
        "v-card",
        { staticClass: "ma-4 pa-4" },
        [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Authentication & Authorization")
          ]),
          _c("p", { staticClass: "mt-0 py-4 mb-0" }, [
            _vm._v(
              " There are 2 different methods to authenticate in the API. Both are set in the "
            ),
            _c("code", [_vm._v("Authorization")]),
            _vm._v(" header. ")
          ]),
          _c("v-divider", { staticClass: "divider" }),
          _c("h3", [_vm._v("1. API Key")]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " The API Key always has all permissions for one single project. "
            )
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " To Authorize by API Key, simply create an API key, and set the Authorization header in your request to "
            ),
            _c("code", [_vm._v("Authorization: Api-Key YOURSECRET")])
          ]),
          _c("v-divider", { staticClass: "divider" }),
          _c("h3", [_vm._v("2. User Access (JWT)")]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " When using JWT, you login with a user email + password. Requests with JWT will have same authorization as the user on app.translationhut.com. "
            )
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(" New to JWT? Learn more "),
            _c(
              "a",
              {
                attrs: { href: "https://jwt.io/introduction", target: "_blank" }
              },
              [_vm._v("here")]
            ),
            _vm._v(". ")
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " 1. To Authorize by JWT, first retrieve the JWT from the API: "
            )
          ]),
          _c("pre", { staticClass: "mb-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                'mutation {\n  tokenAuth(\n    username: "your-email@example.com",\n    password: "your-password"\n  ) {\n    token\n    refreshToken\n  }\n}'
              )
            ])
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " 2. Get the token from the response. A successful response will return: "
            )
          ]),
          _c("pre", { staticClass: "mb-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                '{\n  "data": {\n    "tokenAuth": {\n      "token": "YOUR-ACCESS-TOKEN",\n      "refreshToken": "YOUR-REFRESH-TOKEN"\n    }\n  }\n}'
              )
            ])
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(
              " 3. Then, set the returned token in the Authorization header in your request: "
            )
          ]),
          _c("pre", { staticClass: "mb-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v("Authorization: JWT YOUR-ACCESS-TOKEN")
            ])
          ]),
          _c("p", { staticClass: "mt-4 mb-1 my-0" }, [
            _vm._v(" 4. To refresh the JWT token: ")
          ]),
          _c("pre", { staticClass: "mb-4" }, [
            _c("code", { staticClass: "multiline" }, [
              _vm._v(
                'mutation {\n  refreshJwt(refreshToken: "YOUR-REFRESH-TOKEN") {\n    token\n    refreshToken\n  }\n}\n'
              )
            ])
          ])
        ],
        1
      ),
      _c("v-card", { staticClass: "ma-4 pa-4" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Download translation files")
        ]),
        _c("p", { staticClass: "mt-0 py-4 mb-0" }, [
          _vm._v(
            " Its simple to integrate translationhut into your CI. Just download the translation files in your build process. "
          )
        ]),
        _c("p", { staticClass: "mt-0 py-4 mb-0" }, [_vm._v("Curl Example:")]),
        _c("pre", { staticClass: "mb-4" }, [
          _c("code", { staticClass: "multiline" }, [
            _vm._v(
              "curl '" +
                _vm._s(_vm.$apiUrl) +
                "/export/flat_json/EN-US?&include_empty=true&base_language=EN-US' \\\n     -H 'Authorization: Api-Key YOURSECRET' --output EN-US.json'"
            )
          ])
        ]),
        _c("p", [
          _vm._v("Export files are directly available with "),
          _c("code", [_vm._v("GET")]),
          _vm._v(" requests. Simply...")
        ]),
        _c("ul", [
          _c("li", [
            _vm._v("Include your authorization header "),
            _c("code", [_vm._v("Authorization: Api-Key YOURSECRET")]),
            _vm._v(", these are always project-bound")
          ]),
          _c("li", [
            _vm._v(" Query the url "),
            _c("code", [_vm._v(_vm._s(_vm.$apiUrl) + "/export/po/ES-ES")]),
            _vm._v(", where "),
            _c("code", [_vm._v("ES-ES")]),
            _vm._v(" is the language code and "),
            _c("code", [_vm._v("po")]),
            _vm._v(" can be any of the file types: "),
            _c(
              "ul",
              _vm._l(_vm.fileFormats, function(format) {
                return _c("li", { key: format }, [
                  _c("code", [_vm._v(_vm._s(format))])
                ])
              }),
              0
            )
          ]),
          _c("li", [
            _vm._v("Optionally include the search parameter "),
            _c("code", [_vm._v("include_empty=true")]),
            _vm._v(" to include missing translations with an empty value")
          ]),
          _c("li", [
            _vm._v("Optionally include a base language "),
            _c("code", [_vm._v("base_language=NL-NL")]),
            _vm._v(" to use translation values as keys, instead of Asset IDs")
          ]),
          _c("li", [
            _vm._v("Optionally include a search parameter "),
            _c("code", [_vm._v("languages=AF,EN-US")]),
            _vm._v(
              " to export multiple languages. Leave out the language code in the URL. If you export multiple language files the response will be a zipped file."
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }