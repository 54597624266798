









































import Vue from 'vue';
import store, { state } from '@/store';
import Projects from '@/components/home/Projects.vue';
import Organizations from '@/components/organizations/Organizations.vue';
import UserProfile from '@/components/home/UserProfile.vue';
import vueI18n from "@/plugins/i18n";
import { groupBy } from 'lodash';
import { authenticate } from '@/utils/auth';

export default Vue.extend({
  components: { UserProfile, Projects, Organizations },

  metaInfo: {
    get title() { return vueI18n.t("view_meta.home.title") as string },
  },

  data() {
    return {
      state,
      noneOrgProjects: [],
      orgProjects: [],
      projectsByOrganization: {},
      organizations: [],
      loading: false,
    };
  },
  
  computed: {
    loggedIn: function() {
      return state.loggedIn
    }
  },

  async created() {
    this.loading = true;
    store.resetState('project');
    await authenticate();
    this.loading = false;

    this.organizations = store.getMyOrganizations()
    this.noneOrgProjects = groupBy(store.getMyProjects(), "organization.hidden").true || []
    const orgProjects = groupBy(store.getMyProjects(), "organization.hidden").false || []
    this.projectsByOrganization = groupBy(orgProjects, "organization.id") || []
  },
});
