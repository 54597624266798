import { Result } from "../../types/customTypes";
import { captureException } from "@/utils/debugging";
import { print } from 'graphql/language/printer';
import router from "@/router";
import { ASTNode } from "graphql/language/ast";

const graphqlURL = `${process.env.VUE_APP_API_URL}/graphql/`;

const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ProjectSlug: router.currentRoute.params.projectSlug || ''
});

const defaultOptions = {
  method: 'POST',
  credentials: 'include',
};

function createResult(response: any, flatten: boolean = false): Result {
  const errors = response.errors || [];

  if (!response.data) {
    return {
      errors: errors.length ? errors : ['Something went wrong'],
    };
  }

  // Combine all errors into an array
  errors.push(...Object
    .values(response.data)
    // @ts-ignore
    .flatMap(obj => obj && obj.errors)
    .filter(v => !!v));

  // Get all keys of data and spread their values in a object.
  // Useful for mutations because they are nested more
  const result = { errors };

  const objectToSpread = flatten
    ? response.data
    : response;

  Object.values(objectToSpread).forEach(value => {
      try {
        Object.assign(result, value)
      } catch (e) {
        console.error(e);
      }
    }
  );

  // Errors might have been overwritten by the response data, so set it to object now
  result.errors = errors;
  return result;
}

// convert some error string to graphql-structure error response
function createErrorResponse(errorMessage: string): Result {
  return createResult({
    errors: [{
      field: null,
      message: errorMessage,
    }],
  });
}

export function mutate(queryString: ASTNode, variables: Record<any, any>, fetchOptions: Record<any, any> = {}, flatten: boolean = true) {
  return post(queryString, variables, fetchOptions, flatten);
}

export function post(queryString: ASTNode, variables: Record<any, any>, fetchOptions: Record<any, any> = {}, flatten: boolean = false): Promise<any> {
  // returns { errors: [ { field, message } ], ...data }
  const options = {
    body: JSON.stringify({
      // @ts-ignore
      query: `${print(queryString)}`,
      variables,
    }),
  };

  Object.assign(options, defaultOptions, { headers: getHeaders() }, fetchOptions);

  return new Promise((resolve, reject) => {
    fetch(graphqlURL, options)
      .then(response => response.json())
      .then(json => resolve(createResult(json, flatten)))
      .catch((error) => {
        if (error.name === "AbortError") {
          resolve({ errors: [], aborted: true })
        }
        // Failed fetch is always an unexpected error, since expected
        // errors will be in "response.errors". Send it to sentry.
        captureException(error);
        reject(createErrorResponse(error));
      });
  });
}
