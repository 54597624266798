<template>
  <div class="center-page px-2 px-md-4 py-4">
    <div
      style="width: 100%"
      class="px-md-2"
    >
      <div class="translate-toolbar pt-2 pb-4">
        <span>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn
                style="opacity: .6;"
                icon
                class="mx-1"
                v-on="on"
                @click="downloadTable"
              >
                <v-icon>download</v-icon>
              </v-btn>
            </template>

            <span>{{ $t('assets.export_table.button_tooltip') }}</span>
          </v-tooltip>

          <g-toggle-icon
            v-model="displayColumnSelect"
            :tooltip-text="$t('filters.select_columns_tooltip')"
          >
            mdi-table-column-plus-before
          </g-toggle-icon>

          <g-toggle-icon
            v-model="dense"
            :tooltip-text="$t('filters.dense_view_tooltip')"
          >
            view_headline
          </g-toggle-icon>

          <g-toggle-icon
            v-model="displayFilters"
            :tooltip-text="$t('filters.show_filters_tooltip')"
          >
            filter_list
          </g-toggle-icon>

          <g-toggle-icon
            v-model="editRows"
            :tooltip-text="$t('filters.edit_mode_tooltip')"
          >
            edit
          </g-toggle-icon>

          <v-btn
            v-if="state.isAdmin"
            color="primary"
            class="ml-2"
            @click="displayAddAsset = true"
          >
            {{ $t('assets.add') }}
            <v-icon class="ml-2">add</v-icon>
          </v-btn>
        </span>
      </div>

      <edit-asset-dialog max-width="600" />

      <add-assets-dialog
        v-if="displayAddAsset"
        max-width="600"
        :title="$t('assets.new')"
        @input="closeAddAssetDialog()"
      />

      <g-modal
        v-if="displayColumnSelect"
        v-model="displayColumnSelect"
        max-width="600"
        :title="$t('global.columns')"
      >
        <g-chip-select
          v-model="displayHeaders"
          :items="headerOptions"
          mandatory
        />
      </g-modal>

      <v-expand-transition>
        <asset-filters
          v-show="displayFilters"
          v-model="filters"
        />
      </v-expand-transition>

      <v-card-text class="pa-0 table-wrapper">
        <asset-table
          :filters="filters"
          :dense="dense"
          :display-headers="displayHeaders"
          :edit-rows="editRows"
        />
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { state } from '@/store';
import CONSTANTS from '@/constants/constants';
import AddAssetsDialog from '@/components/assets/AddAssetsDialog.vue';
import syncStateWithStorage from '@/mixins/sync-state-with-storage';
import EditAssetDialog from '@/components/assets/EditAssetDialog';
import AssetFilters from '@/components/assets/AssetFilters';
import AssetTable from '@/components/assets/AssetTable';
import vueI18n from '@/plugins/i18n';
import events from '@/events';

export default {
  name: 'TranslationOverview',

  metaInfo: {
    get title() { return vueI18n.t("view_meta.overview.title") },
  },

  components: {
    AssetTable,
    AssetFilters,
    EditAssetDialog,
    AddAssetsDialog,
  },

  mixins: [syncStateWithStorage(['displayFilters', 'displayHeaders', 'amountOfItems', 'dense', 'editRows'])],

  data() {
    return {
      state,

      // Table state (data),
      isLoading: false,
      amountOfItems: null,
      assets: [],

      // Table state (ui)
      dense: false,
      editRows: false,

      // Settings state
      displayFilters: false,
      displayColumnSelect: false,
      displayAddAsset: false,
      displayHeaders: [],
      filters: {
        search: '',
        showArchivedOnly: false,
        translatedInLanguage: [],
        untranslatedInLanguage: [],
        excludeTags: [],
        includeTags: [],
      },
    };
  },

  computed: {
    headerOptions() {
      return [
        CONSTANTS.translateTableHeaders.assetIdHeader,
        ...this.state.project.languages.map(language => ({
          text: language.code,
          value: language.code,
          sortable: false,
          languageCode: language.code,
        })),
        CONSTANTS.translateTableHeaders.createdHeader,
        CONSTANTS.translateTableHeaders.editedHeader,
        CONSTANTS.translateTableHeaders.archiveHeader,
        CONSTANTS.translateTableHeaders.descriptionHeader,
        CONSTANTS.translateTableHeaders.tagsHeader,
      ];
    }
  },

  watch: {
    // Dont edit archived assets
    'filters.showArchivedOnly': {
      handler(showArchivedOnly) {
        if (showArchivedOnly) {
          this.editRows = false;
        }
      },
    },

    editRows(editRows) {
      if (editRows) {
        this.filters.showArchivedOnly = false;
      }
    },

    headerOptions() {
      this.setDisplayHeaders();
    },
  },

  created() {
    this.setDisplayHeaders();
  },

  methods: {
    setDisplayHeaders() {
      // Set first 5 header options to displayed headers
      if (this.displayHeaders.length === 0) {
        this.headerOptions
          .slice(0, 5)
          .forEach(({ value }) => {
            this.displayHeaders.push(value);
          });
      }
    },

    closeAddAssetDialog() {
      this.displayAddAsset = false;
      events.fetchAssets.emit();
    },

    downloadTable() {
      events.downloadTable.emit()
    },
  },
};
</script>

<style lang="scss">
.table-wrapper {
  overflow-x: auto;
}

.translate-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
</style>
