var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "organization-tabs",
      staticStyle: { "padding-top": "32px", height: "calc(100% - 32px)" }
    },
    [
      _c(
        "v-tabs",
        {
          attrs: { "align-with-title": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab",
            {
              key: "info",
              staticStyle: { "padding-top": "12px" },
              attrs: { href: "#info" }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("organizations.tabs.information")))
              ])
            ]
          ),
          _c(
            "v-tab",
            {
              key: "users",
              staticStyle: { "padding-top": "12px" },
              attrs: { href: "#users" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("organizations.tabs.users")))])]
          ),
          _c(
            "v-tab",
            {
              key: "billing",
              staticStyle: { "padding-top": "12px" },
              attrs: { href: "#billing" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("organizations.tabs.billing")))])]
          )
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticStyle: { "max-height": "calc(100vh - 120px)" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: "info", attrs: { value: "info" } },
            [
              _c(
                "v-card",
                { staticClass: "px-6 wrapper", attrs: { flat: "" } },
                [_c("organization-info")],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "users", attrs: { value: "users" } },
            [
              _c(
                "v-card",
                { staticClass: "wrapper", attrs: { flat: "" } },
                [
                  _c("organization-users", {
                    attrs: { organization: _vm.organization }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "billing", attrs: { value: "billing" } },
            [
              _c(
                "v-card",
                { staticClass: "wrapper", attrs: { flat: "" } },
                [_c("billing", { attrs: { organization: _vm.organization } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }