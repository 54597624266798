var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.project.id
    ? _c("router-view")
    : _c("v-skeleton-loader", {
        staticClass: "ma-auto",
        attrs: { type: "text", width: "100%", "max-width": "300px" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }