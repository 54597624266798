





























































































import Vue from 'vue';
import debounce from 'lodash/debounce';
import store, { state } from '@/store';
import { handleOptionalErrors } from '@/utils/notifications';
import DarkModeToggle from '@/components/navigation/DarkModeToggle.vue';
import UserLanguage from '@/components/home/UserLanguage.vue';
import client from '@/graphql/client';
import NewOrganizationModal from '@/components/organizations/NewOrganizationModal.vue';

export default Vue.extend({
  name: 'UserProfile',

  components: { UserLanguage, DarkModeToggle, NewOrganizationModal },

  data() {
    return {
      user: state.user,
      imageUrl: state.user.imageUrl,
      isShowingChangePasswordModal: false,
      isValid: true,
      isOpenNewOrgModal: false,
    };
  },

  watch: {
    user: {
      handler() {
        this.debouncedSave();
      },
      deep: true,
    },
  },

  methods: {
    openChangePasswordModal() {
      store.openChangePasswordModal();
    },

    //@ts-ignore
    debouncedSave: debounce(function save() { this.save(); }, 500),

    async save() {
      const data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      } as any;

      if (this.user.image) { // this is only put if its updated, so don't always include it
        data.imageId = { set: this.user.image };
      }

      const { errors } = await client.updateUser({ data });
      handleOptionalErrors(errors);
    },
  },
});
