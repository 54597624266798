

















import Vue from 'vue';
import client from "@/graphql/client";
import { state } from "@/store";
import { UsageType } from '@/generated/graphql';
export default Vue.extend({
  name: 'UsageInfo',

  data() {
    return {
      usage: {} as UsageType,
    };
  },

  async created() {
    const { data } = await client.getUsageForProject({ projectSlug: state.project.slug });
    this.usage = data.project.usage
  },
});
