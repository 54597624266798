<template>
  <v-layout
    wrap
    column
    class="page py-4"
  >
    <form
      :key="formkey || '123'"
      class="drag-and-drop-zone"
    >
      <input
        id="file"
        ref="file"
        type="file"
        class="file-input"
        name="file"
        accept=".json"
        @change="upload"
      >

      <div class="drag-and-drop-content">
        <p v-if="uploading">Uploading...</p>
        <p v-else>Choose a .json file or drag it here</p>
        <v-btn
          :loading="uploading"
          :disabled="uploading"
          class="mx-auto"
          color="primary"
          @click="$refs.file.click()"
        >
          Upload
          <v-icon class="ml-2">mdi-upload</v-icon>
        </v-btn>
      </div>

      <g-modal
        v-model="isAskingForLanguage"
        width="400"
        :title="$t('import.select_language')"
        persistent
      >
        <p class="mb-4">
          <v-icon medium>warning</v-icon>
          <span class="ml-2">This might overwrite your current translations</span>
        </p>

        <p>What is the language in the translation file?</p>
        <p>file name: <b>{{ fileName }}</b></p>

        <g-select-language
          v-model="language"
          :items="state.project.languages"
          :rules="[$rules.required]"
          class="mt-4"
          no-data-text="No languages added yet"
          required
        />

        <template #actions>
          <v-btn
            class="mr-0 mb-2 ml-auto"
            @click="cancelAskingForLanguage()"
          >
            Cancel
          </v-btn>

          <v-btn
            class="mr-0 mb-2"
            color="primary"
            @click="doneAskingForLanguage()"
          >
            Done
          </v-btn>
        </template>
      </g-modal>
    </form>

    <v-card class="format-help pa-4 mb-4">
      <format-help />
    </v-card>
  </v-layout>
</template>

<script>
import { state } from '@/store';
import upload from '@/utils/upload';
import {
  handleErrors,
  showErrorNotification,
  showSavedNotification,
  showWarningNotification,
} from '../utils/notifications';
import FormatHelp from '../components/import/FormatHelp.vue';
import vueI18n from '@/plugins/i18n';
import client from '@/graphql/client';

export default {
  components: { FormatHelp },

  metaInfo: {
    get title() { return vueI18n.t("view_meta.import.title") },
  },

  data() {
    return {
      state,
      uploading: false,
      language: null,
      fileName: null,
      formkey: null, // Update this to rerender form.
      isAskingForLanguage: false,

      // callbacks:
      cancelAskingForLanguage: null,
      doneAskingForLanguage: null,
    };
  },

  methods: {
    async askForLanguage() {
      this.language = this.state.project.languages[0];
      this.isAskingForLanguage = true;

      return new Promise((resolve, reject) => {
        this.cancelAskingForLanguage = () => {
          this.isAskingForLanguage = false;
          reject();
        };

        this.doneAskingForLanguage = () => {
          this.isAskingForLanguage = false;
          resolve(this.language);
        };
      });
    },

    async upload(event) {
      const MAX_IN_MB = 5;
      const file = event.target.files[0];
      this.fileName = file.name;
      this.$refs.file.value = null; // clear input so next input triggers change.
      this.formkey = this.$uuid.v4();

      // Check for validity
      if (!file) {
        showErrorNotification('Something went wrong');
        return;
      }

      if (file.size > (1024 * 1024 * MAX_IN_MB)) {
        showWarningNotification(`Make sure your file doesnt exceed ${MAX_IN_MB}MB`);
        return;
      }

      if (file.name.substr(file.name.length - 5) !== '.json') {
        showWarningNotification('Please select a ".json" file');
        return;
      }

      const [
        uploadResult,
        language,
      ] = await Promise.all([upload(file), this.askForLanguage()]);
      if (!language) { return; }
      const { public_id, secure_url } = uploadResult;
      this.uploading = true;
      const { errors, ok } = await client.importData({
        data: {
          projectId: state.project.id,
          publicId: public_id,
          secureUrl: secure_url,
          languageCode: language.code,
        }
      });
      if (errors.length) {
        handleErrors(errors);
      } else {
        showSavedNotification();
      }

      this.uploading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
  .drag-and-drop-zone {
    width: 500px;
    max-width: 90vw;
    height: 200px;
    border: 4px dashed #868686;
    margin: 120px auto;
  }

  .drag-and-drop-content {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .file-input {
    position: absolute;
    width: 500px;
    height: 200px;
    outline: none;
    opacity: 0;
    z-index: 15;
  }

  .page {
    align-content: center;
  }

  .format-help {
    // We need 60px space for sidebar
    @media only screen and (min-width: 860px) {
      max-width: 800px;
    }

    width: 100vw;
  }
</style>
