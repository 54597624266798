<template>
  <div style="max-width: 100%; overflow-x: auto;">
    <table class="my-4 py-4">
      <tbody>
        <th style="width: 150px;" />

        <th
          v-for="header in headers"
          :key="header + 'th'"
          class="narrow_col"
          style="width: 80px;"
        >
          {{ header }}
        </th>

        <tr
          v-for="role in roleOptions"
          :key="role"
        >
          <td style="display: flex; align-items: center; max-width: 150px;">
            <v-checkbox
              v-model="roles"
              hide-details
              style="height: 50px;"
              :value="role"
              :disabled="roles.length === 1 && roles.includes(role)"
              @change="sendToServer"
            />
            <span>{{ role }}</span>
          </td>

          <td
            v-for="route in routes"
            :key="route.title"
            class="narrow_col"
          >
            <v-icon
              v-if="route.meta.roles.includes(role)"
              :color="userHasRole(role) ? 'green' : '#cccccc'"
            >
              check
            </v-icon>
          </td>

          <td class="narrow_col">
            <v-icon
              v-if="role === 'admin'"
              :color="isAdmin ? 'green' : '#cccccc'"
            >
              check
            </v-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { state } from "@/store"
import debounce from "lodash/debounce"
import {
  handleErrors,
  handleOptionalErrors,
  showWarningNotification
} from '@/utils/notifications';
import client from '@/graphql/client';

export default {
  name: 'UserRoles',

  props: {
    user: { type: Object, required: true },
  },

  data() {
    return {
      roles: [],
      userRolesId: null,
    }
  },

  computed: {
    isAdmin() {
      return this.roles.includes('admin');
    },

    projectId() {
      return state.project.id
    },

    routes() {
      return this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
    },

    headers() {
      const headers = this.$router.options.routes
        .find(route => route.name === 'project-wrapper').children
        .map(({title}) => title);

      headers.push(this.$t("project_settings.manage_users"));
      return headers
    },

    roleOptions() {
      return this.routes
        .flatMap(({meta}) => meta && meta.roles)
        // Filter empty, duplicates and admin (admin is not a role)
        // .filter((v, i, arr) =>  v !== "admin" && !!v && arr.indexOf(v) === i)
        .filter((v, i, arr) =>  !!v && arr.indexOf(v) === i)
    },
  },

  created() {
    this.requestUserRoles();
  },

  methods: {
    async requestUserRoles() {
      const userId = this.user.id;
      const projectId = this.projectId;
      const { data, errors } = await client.getUserRole({ userId, projectId });

      if (!data || errors.length) {
        handleErrors(errors);
        return
      }

      const roles = data.getUserRole.roles || [];
      roles.forEach(role => this.roles.push(role));
      this.userRolesId = data.getUserRole.id;
    },

    userHasRole(role) {
      return [...this.roles, this.isAdmin ? "admin" : "niks"].includes(role);
    },

    sendToServer: debounce(async function save() {
      const updatingCurrentUser = state.user.id === this.user.id;
      const isRemovingAsAdmin = !this.roles.includes('admin');

      if (updatingCurrentUser && isRemovingAsAdmin) {
        showWarningNotification(this.$t("project_settings.remove_admin_self_error"));
        this.roles.push("admin");
      }
      const { errors, data } = await client.updateUserRoles({
        projectId: this.projectId,
        userId: this.user.id,
        roles: this.roles,
      });
      if (errors.length) {
        handleErrors(errors);
      }
    })
  },
};
</script>

<style scoped lang="scss">
  table {
    table-layout: fixed;
    width: auto !important;
  }

  .narrow_col {
    text-align: center !important;
    padding-left: 0;
    padding-right: 0;
    word-break: break-all;

    .v-icon {
      width: 40px;
    }
  }
</style>
