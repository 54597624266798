var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.loggedIn
    ? _c(
        "v-layout",
        { staticClass: "pa-4", attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-4 pa-4" },
                [_c("user-profile")],
                1
              )
            ],
            1
          ),
          _vm.organizations.length
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("div", { staticClass: "headline mt-2" }, [
                    _vm._v("Organizations")
                  ]),
                  _c("organizations", {
                    attrs: { organizations: _vm.organizations }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.noneOrgProjects.length
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("div", { staticClass: "headline mt-2" }, [
                    _vm._v("Projects")
                  ]),
                  _c("projects", { attrs: { projects: _vm.noneOrgProjects } })
                ],
                1
              )
            : _vm._e(),
          _vm._l(Object.keys(_vm.projectsByOrganization), function(orgId) {
            return _c(
              "v-flex",
              { key: orgId, attrs: { xs12: "" } },
              [
                _c("div", { staticClass: "headline mt-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.projectsByOrganization[orgId][0].organization.name
                      ) +
                      " "
                  )
                ]),
                _c("projects", {
                  attrs: { projects: _vm.projectsByOrganization[orgId] }
                })
              ],
              1
            )
          }),
          _c(
            "v-overlay",
            { attrs: { opacity: 0.5, value: _vm.loading } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }