import { render, staticRenderFns } from "./OrganizationUsers.vue?vue&type=template&id=5db25803&"
import script from "./OrganizationUsers.vue?vue&type=script&lang=js&"
export * from "./OrganizationUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCheckbox,VDataTable,VFlex,VIcon,VImg,VLayout,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5db25803')) {
      api.createRecord('5db25803', component.options)
    } else {
      api.reload('5db25803', component.options)
    }
    module.hot.accept("./OrganizationUsers.vue?vue&type=template&id=5db25803&", function () {
      api.rerender('5db25803', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organizations/tabs/OrganizationUsers.vue"
export default component.exports