




















































import Vue from 'vue';

export default Vue.extend({
  name: 'Organizations',

  props: {
    organizations: { type: Array, required: true },
  },
});
